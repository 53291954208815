import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { initValuesFormParties } from '../partiesModal/components/forms/initValues';
import { PartiesContextProvider } from '../partiesModal/context/PartiesContextProvider';
import { ModalPartiesBody } from '../partiesModal/components/modal/ModalPartiesBody';
import { getMatterByUser } from '../partiesModal/helpers/logicDataParties';
import { AddPartyDto } from './domain/dto/addPartie.dto';
import { NotificationManager } from "react-notifications";
import { PartiesService } from './services/partiesServices';

const PartiesLocalModal = () => {
    const [values, setValues] = useState(initValuesFormParties);
    const [partiesList, setPartiesList] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [isDeleteParty, setIsDeleteParty] = useState(false);
    const [currentPartyItem, setCurrentPatyItem] = useState({});
    const [randomVal, setRandomVal] = useState(0);
    const [mattersByUser, setMattersByUser] = useState([]);
    const [showModal, setShowModal] = useState(false);
    

    const closeModal = () => {
        // toggleShowModal();
        setValues(initValuesFormParties);
        setIsDeleteParty(false);
        setOpenForm(false);
        setCurrentPatyItem({});
        setIsDeleteParty(false);
    }

    const handleChageForm = (val, key) => {
        setValues({
            ...values,
            formValues: {
            ...values.formValues,
            [key]: val
            }
        });
    }

    const toggleShowAddForm = () => {
        setOpenForm(!openForm);
        if (openForm === false) {
            setValues(initValuesFormParties);
        }
    }

    const toggleDeleteParty = (currentPart) => {
        setIsDeleteParty(!isDeleteParty);
        if (!isDeleteParty) {
          setCurrentPatyItem(currentPart);
          return;
        } else {
          setCurrentPatyItem({});
        }
    };

    const handleDelete = (currentPart) => {
        const isOk = PartiesService.handleDelete(currentPart.id);
        if(isOk) {
            NotificationManager.success(
                '',
                'La parte ha sido eliminada correctamente',
                4500
            );
            toggleDeleteParty();
        } else {
            NotificationManager.error('¡Ha ocurrido un error inesperado y la parte no fue eliminada, inténtalo nuevamente!');
        }
    }

    const handleGetListParties = async () => {
        const partiesList = PartiesService.getParties();
        setPartiesList(partiesList || []);
    }

    const handleSubmit = () => {
        if (_.isEmpty(currentPartyItem)) {
            const [errorSubmit] = AddPartyDto.create(values);
            if(errorSubmit) {
                let errorsByForm = AddPartyDto.findFormErrors(Object.keys(errorSubmit));
                setValues({ ...values, errors: errorSubmit, errorsByForm });
                NotificationManager.error('¡Existen errores, revisa los campos marcados en rojo y vuelve a intentarlo!');
                return;
            }
            const isOk = PartiesService.handleSubmit(values);
            if(isOk) {
                toggleShowAddForm();
                NotificationManager.success(
                    '',
                    'La parte ha sido agregada correctamente',
                    4500
                );
            } else {
                NotificationManager.error('¡Ha ocurrido un error inesperado y la parte no fue agregada, inténtalo nuevamente!');
            }
        } else {
            const [errorSubmit] = AddPartyDto.update(values, currentPartyItem);
            if(errorSubmit) {
                let errorsByForm = AddPartyDto.findFormErrors(Object.keys(errorSubmit));
                setValues({ ...values, errors: errorSubmit, errorsByForm });
                NotificationManager.error('¡Existen errores, revisa los campos marcados en rojo y vuelve a intentarlo!');
                return;
            }

            const isOk = PartiesService.handleUptate(values, currentPartyItem);
            if(isOk) {
                toggleShowAddForm();
                NotificationManager.success(
                    '',
                    'La parte ha sido actualizada correctamente',
                    4500
                );
            } else {
                NotificationManager.error('¡Ha ocurrido un error inesperado y la parte no fue actualizada, inténtalo nuevamente!');
            }
        }
    }

    useEffect(() => {
        const getMatters = async () => {
          let matters = await getMatterByUser() || [];
          setMattersByUser(matters);
        }
        getMatters();
    }, []);

    useEffect(() => {
        return () => {
            PartiesService.handleResetCookie();
        };
    }, []);

    const context = { 
        showModal,
        closeModal,
        idExpedient: null,
        setValues,
        values,
        handleChageForm,
        showForm: openForm,
        listParties: partiesList,
        setCurrentPatyItem,
        handleGetListParties,
        isDeleteParty,
        toggleDeleteParty,
        currentPartyItem,
        handleSubmit: () => handleSubmit(),
        toggleShowAddForm,
        handleDelete: () => handleDelete(currentPartyItem),
        mattersByUser,
        // THIS VALUES IS JUST TO WAIT TO LOAD INITIAL DATA
        randomVal,
        setRandomVal,
        isFromLocal: true
    }

    // if(!showModal) return <></>;
    
    return (
        <div className='mt-2 mb-2'>
            <PartiesContextProvider value={context}>
                <ModalPartiesBody />
            </PartiesContextProvider>
        </div>
    );
}

export default PartiesLocalModal;