import React, { useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { Row, Col, Form } from 'react-bootstrap';
import { AutoCompleteCrimes } from '../AutoCompleteCrimes/AutoCompleteCrimes';
import { JudgmentTypesCheckbox } from '../JudgmentTypesCheckbox/judgmentTypesCheckbox';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from "moment";
import { secondCustomHeaderDatePicker } from '../../expedient_details_logic';
import CustomInputDate from '../CustomInputDate/CustomInputDate';
import { BulletinEntity } from '../../classes/Bulletin.entity';
registerLocale('es', es);


export const BulletinFields = ({ onChange, values, renderErrorsByInputName, errors, defaultValues = false, setErrors }) => {
  const firstInputDatePicker = useRef(null);

	useEffect(() => {
		if( values.bulletin && defaultValues ) {
			const aux = BulletinEntity.parseFromRequest(values.bulletin);
			onChange({ ...values, ...aux });
		}
	}, [])
	
	
	return (
		<>
			<div>
				<Row>
					<Col xs={12} md={12}>
						<Form.Group>
							<Form.Label>* Nombre del Juez:</Form.Label>
							<Form.Control
								type="text"
								onChange={(e) => {
									onChange({ ...values, judgeName: e.target.value })
									if(defaultValues) {
										setErrors(({ judgeName, ...restOfProperties }) => ({ ...restOfProperties }))
									}
								}}
								defaultValue={defaultValues ? values.judgeName : ''}
							/>
							{renderErrorsByInputName(errors, "judgeName")}
						</Form.Group>
					</Col>

					<Col xs={12} md={12}>
						<Form.Group>
							<Form.Label>* Nombre del Secretario de Acuerdos:</Form.Label>
							<Form.Control
								type="text"
								onChange={(e) => {
									onChange({ ...values, agreementsSecretary: e.target.value })
									if(defaultValues) {
										setErrors(({ agreementsSecretary, ...restOfProperties }) => ({ ...restOfProperties }))
									}
								}}
								defaultValue={defaultValues ? values.agreementsSecretary : ''}
							/>
							{renderErrorsByInputName(errors, "agreementsSecretary")}
						</Form.Group>
					</Col>
				</Row>
			</div>

			<Form.Group>
				<Form.Label>* Fecha de dictamen de la sentencia:</Form.Label>
				<DatePicker
					renderCustomHeader={({
						date,
						changeYear,
						changeMonth,
						decreaseMonth,
						increaseMonth,
						prevMonthButtonDisabled,
						nextMonthButtonDisabled
					}) => secondCustomHeaderDatePicker(
						date,
						changeYear,
						changeMonth,
						decreaseMonth,
						increaseMonth,
						prevMonthButtonDisabled,
						nextMonthButtonDisabled
					)}
					locale="es"
					customInput={<CustomInputDate ref={firstInputDatePicker} />}
					maxDate={new Date()}
					selected={values['sentenceDate'] ? moment(values['sentenceDate']).toDate() : ''}
					onChange={(date) => { onChange({ ...values, sentenceDate: date }) }}
					
				/>
				{renderErrorsByInputName(errors, "sentenceDate")}
			</Form.Group>

			{/* <>
				<AutoCompleteCrimes
					onChange={onChange}
					values={values}
					isDefaultValues={defaultValues}
				/>
				{renderErrorsByInputName(errors, "crimes_id")}
			</> */}

			<JudgmentTypesCheckbox
				onChange={onChange}
				values={values}
				isDefaultValues={defaultValues}
			/>
		</>
	)
}

BulletinFields.propTypes = {
	onChange: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
	renderErrorsByInputName: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	defaultValues: PropTypes.bool,
	setErrors: PropTypes.func
}