import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { ErrorsByKeyName } from '../shared/ErrorsByKeyName';
import { usePartiesContext } from '../../context/PartiesContext';
import { typeParties } from '../../assets/typeParties';
import { typeRepresentation } from '../../assets/typeRepresentation';
import { mappingData, findData, mappingDataOne } from '../../helpers/parseAndFilterDataToForm';
import { getPartiesByExpedientIdAndType, isError } from '../../helpers/logicDataParties';
import _ from 'lodash';
import { PartiesService } from '../../../partiesLocalModal/services/partiesServices';
export const PartiesFormPartiesRelations = () => {
	const [partieTypeSelected, setPartieTypeSelected] = useState(null);
	const [typeRepre, setTypeRepre] = useState(null);
	const [relationParties, setRelationParties] = useState([]);
	const [relationPartySelected, setRelationPartySelected] = useState([]);
	const { values, handleChageForm, randomVal, currentPartyItem, idExpedient, setValues, isFromLocal } = usePartiesContext();


	useEffect(() => {
		// Reset valuas in each change of typePartie
		setRelationParties([]);
		setRelationPartySelected(null);
		setTypeRepre(null);
		if(!currentPartyItem) {
			setValues({
				...values,
				formValues: {
					...values.formValues,
					relation_party_id: '',
					type_representation: ''
				}
			});
		}

		const getRelationsParties = async (findedPartie) => {
			let partiesRelations = {};
			if(isFromLocal) {
				partiesRelations = PartiesService.getPrelations(findedPartie.key_relation);
			} else {
				partiesRelations = await getPartiesByExpedientIdAndType(idExpedient, findedPartie.key_relation);
			}
			if(!_.isEmpty(partiesRelations)) {
				let res = Object.keys(partiesRelations).map(key => { 
					const name = partiesRelations[key].party_data.names + ' ' + partiesRelations[key].party_data.paternal_surname + ' ' + (partiesRelations[key].party_data.maternal_surname ? partiesRelations[key].party_data.maternal_surname : '');
					return {
						value: partiesRelations[key].id,
						label: name
					}
				});
				setRelationParties(res);
			}
		}
		let findedPartie = typeParties.find(item => item.codigo === values.formValues.typeParts) || null;
		setPartieTypeSelected(findedPartie);
		// GET POSIBLE RELATIONS
		if(findedPartie && findedPartie.type === 'secondary') {
			getRelationsParties(findedPartie);
		}
	}, [values.formValues.typeParts]);

	useEffect(() => {
		if (values.formValues.type_representation) setTypeRepre(mappingDataOne(findData(typeRepresentation, values.formValues.type_representation)));
	}, [randomVal, currentPartyItem]);

	useEffect(() => {
		if(relationParties.length >0) {
			if(values.formValues.relation_party_id) {
				let finded = JSON.parse(values.formValues.relation_party_id) || [];
				finded = relationParties.map(item => finded.includes(item.value) ? item : null).filter(item => item !== null);
				setRelationPartySelected(finded);
			} else {
				setRelationPartySelected(null);
				handleChageForm('', 'relation_party_id');
			}
		}
	}, [relationParties, randomVal, currentPartyItem]);

	const handleChangeTypeRepre = (val) => {
		if (val) {
			setTypeRepre(val);
			handleChageForm(val.value, 'type_representation');
		} else {
			setTypeRepre(null);
			handleChageForm('', 'type_representation');
		}
	}

	const handleChangeRelationParty = (val) => {
		if (val) {
			setRelationPartySelected(val);
			let ids = val.map(item => item.value);
			handleChageForm(JSON.stringify(ids), 'relation_party_id');
		} else {
			handleChageForm('', 'relation_party_id');
			setRelationPartySelected(null);
		}
	}

	if (!partieTypeSelected) {
		return <></>;
	}

	return (
		<>
			<Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
				{partieTypeSelected.type === 'secondary' &&
					<Row>
						<Col xs={12} md={12}>
							<Form.Group className='mb-3'>
								<Form.Label>
									* Parte Representada
								</Form.Label>

								<Select
									options={relationParties}
									placeholder='Selecciona una opción'
									onChange={val => handleChangeRelationParty(val)}
									value={relationPartySelected}
									classNamePrefix='my-select'
									isMulti={true}
									className={isError(values.errors, 'relation_party_id') ? 'select-error' : ''}
								/>
								<ErrorsByKeyName listErrors={values.errors} value='relation_party_id' />
							</Form.Group>

						</Col>

						<Col xs={12} md={12}>
							<Form.Group>
								<Form.Label>
									* Tipo de representación
								</Form.Label>

								<Select
									options={mappingData(typeRepresentation)}
									placeholder='Selecciona una opción'
									value={typeRepre}
									onChange={val => handleChangeTypeRepre(val)}
									classNamePrefix='my-select'
									className={isError(values.errors, 'type_representation') ? 'select-error' : ''}
								/>
								<ErrorsByKeyName listErrors={values.errors} value='type_representation' />
							</Form.Group>
						</Col>
					</Row>
				}
			</Form>
		</>
	);
}
