export class BulletinEntity {

    constructor(
        judgeName,
        agreementsSecretary,
        sentenceDate,
        genderPerspective,
        childPerspective,
        womanPerspective,
        crimes_id
    ) {
        this.judgeName = judgeName;
        this.agreementsSecretary = agreementsSecretary;
        this.sentenceDate = sentenceDate;
        this.genderPerspective = genderPerspective;
        this.childPerspective = childPerspective;
        this.womanPerspective = womanPerspective;
        this.crimes_id = crimes_id;
        
    };

    static parseFromRequest(data) {
        const {agreements_secretary, child_perspective, crimes_id, dibulgation_date, gender_perspective, judge_name, sentencie_date, woman_perspective} = data;
        return new BulletinEntity(judge_name, agreements_secretary, sentencie_date, gender_perspective, child_perspective, woman_perspective, crimes_id);
    }

}