import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import PartsList from './PartsList';
import PartsFormModal from '../../modals/parts/PartsFormModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from '../info_expedient/InfoExistentExpedient.module.css';

const PartsSection = ({ expedient }) => {
  const [show, setShow] = useState(false);

  const addedParts = localStorage.getItem('parts') ? JSON.parse(localStorage.getItem('parts')) : [];
  const [parts, setParts] = useState(addedParts);

  useEffect(() => {
    localStorage.setItem('parts', JSON.stringify(parts));
  }, [parts]);


  const handleAddPartModal = (e) => {
    e.preventDefault();
    setShow(true);
  }

  return (
    <div className={styles.partsContainer}>
      <div className={styles.addPartsButton}>

        <Button
          onClick={(e) => handleAddPartModal(e)}
          className="formulario-tareas__btn"
        >
          <FontAwesomeIcon
            icon={faPlus}
            className={styles.actionIconPart} /> Agregar parte
        </Button>
      </div>

      <PartsFormModal show={show} setShow={setShow} parts={parts} setParts={setParts} isNewPart={true} />

      <div className={styles.partsListingContainer}>
        <h4 className={styles.partsTableTitle}>Partes del expediente</h4>
        <PartsList parts={parts} setParts={setParts} />
      </div>
    </div>
  );
}

export default PartsSection;