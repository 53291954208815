import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const judgmentTypes = [
	{
		key: 'genderPerspective',
		label: 'Sentencia dictada con perspectiva de género'
	},
	{
		key: 'childPerspective',
		label: 'Sentencia dictada con perspectiva de infancia y adolescencia'
	},
	{
		key: 'womanPerspective',
		label: 'Sentencia dictada con perspectiva de violencia contra las mujeres'
	}
];

export const JudgmentTypesCheckbox = ( { onChange, values, isDefaultValues = false } ) => {
	const [ perspective, setPerspective ] = useState([]);
	const handleChange = (e) => {
		let updatePerspective;
		if(e.target.checked) {
			updatePerspective = [...perspective, e.target.value];
		} else {
			updatePerspective = perspective.filter(item => item !== e.target.value);
		}
		setPerspective(updatePerspective);
	}

	useEffect( () => {
		let aux = values;
		judgmentTypes.map(item => {
			if(perspective.includes(item.key)) {
				aux = {...aux, [item.key]: true};
			} else {
				aux = {...aux, [item.key]: false};
			}
		});
		onChange(aux);
	}, [ perspective ] );

	useEffect( () => {
		if( !isDefaultValues ) {
			let aux = {
				...values,
				genderPerspective: false,
				childPerspective: false,
				womanPerspective: false
			};
			onChange(aux);
		} else {
			let aux = values.bulletin ? values.bulletin : {};
			setPerspective([
				aux.gender_perspective ? 'genderPerspective' : '',
				aux.child_perspective ? 'childPerspective' : '',
				aux.woman_perspective ? 'womanPerspective' : ''
			]);
		}
	}, [] );

	return (
		<Form.Group>
			{
				judgmentTypes.map(item => (
					<Form.Check
						label={item.label}
						key={item.label}
						id={item.label}
						value={item.key}
						checked={perspective.includes(item.key)}
						type='checkbox'
						onChange={handleChange}
						style={{
							marginBottom: '10px',
						}}
					/>
				))
			}
		</Form.Group>
	);
}

JudgmentTypesCheckbox.propTypes = {
	onChange: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
	isDefaultValues: PropTypes.bool,
}