import React, { useEffect, useState } from 'react';
import { PartiesFormBread } from './PartiesFormBread';
import { usePartiesContext } from '../../context/PartiesContext';
import { PartiesFormPersonalInfo } from './PartiesFormPersonalInfo';
import { PartiesFormContactInformation } from './PartiesFormContactInformation';
import { PartiesFormTransparency } from './PartiesFormTransparency';
import { PartiesFormType } from './PartiesFormType';
import { Button } from 'react-bootstrap';
import { initValuesFormParties } from './initValues';
import _ from 'lodash';

export const PartiesForm = () => {
    const { values, toggleShowAddForm, setValues, handleSubmit, currentPartyItem = {}, setCurrentPatyItem, setRandomVal } = usePartiesContext();
    const [isInitialized, setIsInitialized] = useState(false);
    const handleCloseForm = () => {
        setValues(initValuesFormParties);
        toggleShowAddForm();
        setCurrentPatyItem({});
        setIsInitialized(false);
    }

    useEffect(() => {
        if (!_.isEmpty(currentPartyItem) && !isInitialized) {
            setValues({
                ...values,
                formValues: {
                    ...currentPartyItem.party_data,
                    typeParts: currentPartyItem.party_type,
                    is_show_cover: currentPartyItem.show_cover
                }
            });
            setRandomVal((prev) => prev + 1);
            setIsInitialized(true);
        }
    }, [values, currentPartyItem]);    

    return (
        <>
            <PartiesFormType />
            <PartiesFormBread />
            <div className='mb-2' />
            {values.formActive === 'transparency_info' &&
                <PartiesFormTransparency />
            }

            {values.formActive === 'personal_info' &&
                <PartiesFormPersonalInfo />
            }
            {values.formActive === 'contact_info' &&
                <PartiesFormContactInformation />
            }
            <div className='mt-2 d-flex justify-content-between'>
                <Button variant='dark' onClick={handleCloseForm}>
                    Cancelar
                </Button>

                <Button variant='primary' onClick={handleSubmit}>
                    Guardar
                </Button>
            </div>
        </>
    );
}
