import React from 'react'
import Option from '../../../../../react_select/components/option/Option'
import Cookies from "js-cookie";

/**
  The reason why the data-test-is-the-current-user attribute was added is so that
  in end-to-end tests it can be identified that the current user is not being rendered.
**/

const ReceiverSelectOption = (props) => {

  const { userId_03: currentUserId } = Cookies.get();
  const receiverUserId = props.value;
  const isTheCurrentUser = currentUserId === receiverUserId;

  return (
    <div
      data-test-is-the-current-user={isTheCurrentUser}
      style={{ ...(isTheCurrentUser && { display: 'none' }) }}
    >
      <Option {...props} />
    </div>
  )
}

export default ReceiverSelectOption