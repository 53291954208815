import { NotificationManager } from "react-notifications";
import _, { set } from 'lodash';
import { RequestNewVersion } from "../../components/classes/RequestNewVersion";
export class CrimesServices {
    static async getCrimesByMatter(matter, setCrimes) {
        const request = new RequestNewVersion(`${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedient/crimes/${matter}`, "get", null, null);
        const response = await request.executeRequest();
        if (response.code === 200) {
            const crimes = response.response;
            if (!_.isEmpty(crimes)) {
                const options = [];
                Object.keys(crimes).forEach((key) => {
                    const crime = crimes[key];
                    options.push({
                        value: crime.value,
                        label: crime.label
                    });
                });
                setCrimes(options);
            } else {
                setCrimes([]);
            }
        } else {
            setCrimes([]);
            NotificationManager.error(response.response.message || 'No se pudieron cargar los delitos');
        }

    }

}