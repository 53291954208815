import React from 'react';
import BadgeWithOptions from '../../../../badge/badgeWithOptions/BadgeWithOptions';
import styles from './TemplateBadgeElementList.module.css';
import clsx from 'clsx';
import { DEFAULT_VALUE_TEMPLATES_TYPE_ITEM } from '../../lib/constants';

const TemplateBadgeElementList = ({
  templateElementList = DEFAULT_VALUE_TEMPLATES_TYPE_ITEM,
  ...restOfProps
}) => {
  return (
    <ul
      {...restOfProps}
      className={clsx(styles.templateBadgeElementList, restOfProps.className)}
    >
      {
        templateElementList.list.map((templateElement) => (
          <li key={templateElement.id}>
            <BadgeWithOptions
              onClick={() => templateElementList.actions.onSelfTemplateClick(templateElement)}
              onDeleteClick={() => templateElementList.actions.onClickDeleteButton(templateElement.id)}
            >
              {templateElement.alias}
            </BadgeWithOptions>
          </li>
        ))
      }
    </ul>
  )
}

export default TemplateBadgeElementList