import React, { useEffect, useState } from 'react';
import PropsTypes from 'prop-types';
import { PartiesContextProvider } from './context/PartiesContextProvider';
import ModalComponent from '../../../../helper_components/ModalComponent';
import { ModalPartiesFooter } from './components/modal/ModalPartiesFooter';
import { ModalPartiesBody } from './components/modal/ModalPartiesBody';
import { initValuesFormParties } from './components/forms/initValues';
import { getPartiesByExpedientId, handleSubmitParties, handleDeleteParty, handleSubmitUpdateParties, getMatterByUser } from './helpers/logicDataParties';
import _ from 'lodash';

export const PartiesModal = ({ showModal, toggleShowModal, idExpedient }) => {
  const [values, setValues] = useState(initValuesFormParties);
  const [partiesList, setPartiesList] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [isDeleteParty, setIsDeleteParty] = useState(false);
  const [currentPartyItem, setCurrentPatyItem] = useState({});
  const [randomVal, setRandomVal] = useState(0);
  const [mattersByUser, setMattersByUser] = useState([]);

  useEffect(() => {
    const getMatters = async () => {
      let matters = await getMatterByUser() || [];
      setMattersByUser(matters);
    }

    getMatters();
  }, []);

  const handleChageForm = (val, key) => {
    setValues({
      ...values,
      formValues: {
        ...values.formValues,
        [key]: val
      }
    });
  }

  const toggleShowAddForm = () => {
    setOpenForm(!openForm);
    if (openForm === false) {
      setValues(initValuesFormParties);
    }
  }

  const toggleDeleteParty = (currentPart) => {
    setIsDeleteParty(!isDeleteParty);
    if (!isDeleteParty) {
      setCurrentPatyItem(currentPart);
      return;
    } else {
      setCurrentPatyItem({});
    }
  };

  const handleGetListParties = async () => {
    let partiesList = await getPartiesByExpedientId(idExpedient);
    setPartiesList(partiesList || []);
  }

  const closeModal = () => {
    toggleShowModal();
    setValues(initValuesFormParties);
    setIsDeleteParty(false);
    setOpenForm(false);
    setCurrentPatyItem({});
    setIsDeleteParty(false);
  }

  const handleSubmit = () => {
    if (_.isEmpty(currentPartyItem)) {
      handleSubmitParties(values, setValues, idExpedient, toggleShowAddForm);
    } else {
      handleSubmitUpdateParties(idExpedient, values, toggleShowAddForm, currentPartyItem, setValues);
    }
  }

  const contextValues = {
    showModal,
    closeModal,
    idExpedient,
    setValues,
    values,
    handleChageForm,
    showForm: openForm,
    listParties: partiesList,
    setCurrentPatyItem,
    handleGetListParties,
    isDeleteParty,
    toggleDeleteParty,
    currentPartyItem,
    handleSubmit: () => handleSubmit(),
    toggleShowAddForm,
    handleDelete: () => handleDeleteParty(idExpedient, currentPartyItem, toggleDeleteParty, values, setValues),
    mattersByUser,
    // THIS VALUES IS JUST TO WAIT TO LOAD INITIAL DATA
    randomVal,
    setRandomVal,
    isFromLocal: false
  };

  if (!showModal) return <></>;

  return (
    <PartiesContextProvider value={contextValues}>
      <ModalComponent
        header="Listado de partes"
        size={'md'}
        show={true}
        footer={<ModalPartiesFooter />}
        body={<ModalPartiesBody />}
      />
    </PartiesContextProvider>
  );
}

PartiesModal.propTypes = {
  showModal: PropsTypes.bool.isRequired,
  toggleShowModal: PropsTypes.func.isRequired,
  idExpedient: PropsTypes.number.isRequired
}
