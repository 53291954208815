import React from 'react';
import PropTypes from 'prop-types';
import { findDataInCatalogsByKey } from '../../helpers/partyHelpers';
import { typeParties } from '../../assets/typeParties';
import { usePartiesContext } from '../../context/PartiesContext';
export const PartiesItem = ( { party } ) => {
    const { toggleDeleteParty, toggleShowAddForm, setCurrentPatyItem, setValues, values } = usePartiesContext();
    const { id, party_data = {}, party_type } = party;
    const partyTypeItem = findDataInCatalogsByKey(typeParties, party_type);

    const handleOpenForm = () => {
	    toggleShowAddForm()
	    setCurrentPatyItem(party);
        setValues({
            ...values,
            formValues: {
                ...party.party_data,
                typeParts: party.party_type,
                is_show_cover: party.show_cover
            }
        });
    }

    return (
        <div className='border border-secondary rounded d-flex align-items-center cursor-pointer user-select-none mb-2'>
            <div className='container' onClick={handleOpenForm}>
                <div className='row'>
                    <div className='col font-weight-bold'>
                        { partyTypeItem ? partyTypeItem.etiqueta : '' }
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        { `${ party_data.names } ${ party_data.paternal_surname || '' } ${ party_data.maternal_surname || '' }` }
                    </div>
                </div>
            </div>

            <div>
                <span 
                    className='ti-trash' 
                    style={{ color: 'red', fontWeight: 'bold', fontSize: '25px', cursor: 'pointer'}}
                    onClick={() => toggleDeleteParty(party)}
                />
            </div>
        </div>
    )
}

PartiesItem.propTypes = {
    party: PropTypes.object.isRequired
}
