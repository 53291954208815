import React from 'react'
import FormTitle from '../../FormTitle'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from "react-select";
import BootstrapTable from 'react-bootstrap-table-next';
import AddMinorModal from './components/modals/addMinorModal/AddMinorModal';
import { useIndicatorsContext } from '../../../context/indicatorsContext/IndicatorsContext';
import { columns } from './components/modals/addMinorModal/services/involvedMinorsTable/columns';
import { personTypeOptions } from '../../../services/selectOptions/personTypeOptions';
import { getElementFromOptionsList } from '../../../../../../../../services/dataFormatting/getElementFromOptionsList';
import _ from 'lodash';
import { initialDemandsDefaultValues, legalEntityDefaultValues } from '../../../constants/defaultValues';
import { renderErrorsByInputName } from '../../../../../../my_expedients/expedients_list_logic';

const InitialDemand = () => {
  const { initialDemand } = useIndicatorsContext();

  
  
  const {
    toggleisAddMinorModalShowing,
    isAddMinorModalShowing,
    initialDemands,
    assertions,
    errors,
    legalEntityList,
  } = initialDemand;
  
  return (
    <div>
      <FormTitle>
        Demanda Inicial
      </FormTitle>
      <Form>
        <Form.Group controlId="measures_reparation">
          <Form.Label>
            Medidas de reparacion del daño solicitadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_reparation'
            onChange={initialDemands.onFormChange}
            value={_.defaultTo(initialDemands.formValues.measures_reparation, initialDemandsDefaultValues.measures_reparation)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName( errors,'measures_reparation')}
        </Form.Group>

        <Form.Group controlId="measures_protection">
          <Form.Label>
            Medidas de proteccion solicitadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_protection'
            onChange={initialDemands.onFormChange}
            value={_.defaultTo(initialDemands.formValues.measures_protection, initialDemandsDefaultValues.measures_protection)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName( errors,'measures_protection')}
        </Form.Group>

        <Form.Group controlId="minors">
          <Form.Text className="text-muted">
            Menores de edad involucrados
          </Form.Text>
          <Form.Check
            inline
            type="radio"
            label="Si"
            name="minors"
            id='minors_radio_si'
            value={true}
            checked={assertions.isThereAMinorInvolved}
            onChange={initialDemands.onFormRadioChange}
          />
          <Form.Check
            inline
            type="radio"
            label="No"
            name="minors"
            id='minors_radio_no'
            value={false}
            checked={assertions.isNotThereAMinorInvolved}
            onChange={initialDemands.onFormRadioChange}
          />
        </Form.Group>

        {
          assertions.isThereAMinorInvolved
          &&
          <>
            <Row style={{ marginBottom: '12px', justifyContent: 'flex-end' }}>
              <Button variant="success" onClick={toggleisAddMinorModalShowing}>
                <i className="fas fa-plus" />
                {'  '} Agregar Menor
              </Button>
            </Row>
            <Row style={{ overflow: 'auto', display: 'block' }}>
              <BootstrapTable
                keyField="involved_minors_table"
                data={initialDemands.formValues.involved_minors}
                columns={columns}
              />
            </Row>
          </>
        }

        <Row style={{ gap: '30px' }}>
          {
            legalEntityList.map((legalEntityItem, index) => (
              <Col lg key={index}>
                <Form.Group controlId={legalEntityItem.value}>
                  <Form.Text className="text-muted">
                    {legalEntityItem.label}
                  </Form.Text>
                  <Form.Check
                    type="checkbox"
                    label="Si"
                    id={`${legalEntityItem.value}_si`}
                    name={legalEntityItem.value}
                    checked={legalEntityItem.valueChecked}
                    onChange={initialDemands.onFormCheckChange}
                  />
                </Form.Group>

                {
                  Boolean(legalEntityItem.valueChecked)
                  &&
                  <>
                    <Form.Group controlId={`${legalEntityItem.value}_gender`}>
                      <Form.Check
                        inline
                        type="radio"
                        label="Masculino"
                        name={`${legalEntityItem.value}_gender`}
                        id={`${legalEntityItem.value}_gender_male`}
                        value={'male'}
                        data-fieldname='gender'
                        onChange={legalEntityItem.formMethods.onFormRadioChange}
                        checked={legalEntityItem.maleCheked}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Femenino"
                        name={`${legalEntityItem.value}_gender`}
                        id={`${legalEntityItem.value}_gender_female`}
                        value={'female'}
                        data-fieldname='gender'
                        onChange={legalEntityItem.formMethods.onFormRadioChange}
                        checked={legalEntityItem.femaleCheked}
                      />
                    </Form.Group>

                    <Form.Group controlId="type_person">
                      <Form.Label>
                        Tipo de persona
                      </Form.Label>
                      <Select
                        name='type_person'
                        options={personTypeOptions}
                        onChange={legalEntityItem.formMethods.onSelectChange}
                        value={getElementFromOptionsList(legalEntityItem.formMethods.formValues.type_person, personTypeOptions)}
                      />
                    </Form.Group>

                    <Form.Group controlId={`${legalEntityItem.value}_community`}>
                      <Form.Text className="text-muted">
                        ¿Comunidad indigena?
                      </Form.Text>
                      <Form.Check
                        inline
                        type="radio"
                        label="Si"
                        name={`${legalEntityItem.value}_community`}
                        id={`${legalEntityItem.value}_community_si`}
                        value={true}
                        data-fieldname='community'
                        onChange={legalEntityItem.formMethods.onFormRadioChange}
                        checked={legalEntityItem.communityTrue}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        name={`${legalEntityItem.value}_community`}
                        id={`${legalEntityItem.value}_community_no`}
                        value={false}
                        data-fieldname='community'
                        onChange={legalEntityItem.formMethods.onFormRadioChange}
                        checked={legalEntityItem.communityFalse}
                      />
                    </Form.Group>

                    {
                      Boolean(legalEntityItem.communityTrue)
                      &&
                      <Form.Group controlId={`${legalEntityItem.value}_which_community`}>
                        <Form.Label>
                          ¿A que comunidad indigena pertenece?
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name='which_community'
                          value={_.defaultTo(legalEntityItem.formMethods.formValues.which_community, legalEntityDefaultValues.which_community)}
                          onChange={legalEntityItem.formMethods.onFormChange}
                        />
                      </Form.Group>
                    }
                  </>
                }
              </Col>
            ))
          }
        </Row>
      </Form>

      {
        isAddMinorModalShowing
        &&
        <AddMinorModal />
      }
    </div >
  )
}

export default InitialDemand