import React from 'react';
import TemplateTabs from '../templateTabs/TemplateTabs';
import ActionWidget from '../../../../actionWidget/ActionWidget';
import TemplateBadgeElementList from '../templateBadgeElementList/TemplateBadgeElementList';
import styles from './TemplateTabsWithWidgets.module.css';

const TemplateTabsWithWidgets = ({
  templateTabsState,
  templateItemsByType,
  templatesTypeName,
  templatesTypeItem,
  doesCurrentTemplatesTypeItemExist,
}) => {
  return (
    <TemplateTabs
      templateItemsByType={templateItemsByType}
      templatesTypeName={templatesTypeName}
      {...templateTabsState}
    >
      <div className={styles.tabPanelContent}>
        <ActionWidget
          className={styles.tabPanelContent_createWidget}
          icon={'fas fa-plus'}
          onClick={templatesTypeItem.actions.onClickCreateTemplateButton}
          disabled={!templatesTypeItem.canCreateTemplate}
          aria-label={`Agregar platilla de tipo ${templatesTypeItem.displayName}`}
        >
          Agregar platilla de tipo {templatesTypeItem.displayName}
        </ActionWidget>
        {
          doesCurrentTemplatesTypeItemExist
          &&
          <TemplateBadgeElementList
            className={styles.tabPanelContent_templateList}
            templateElementList={templatesTypeItem}
          />
        }
      </div>
    </TemplateTabs>
  )
}

export default TemplateTabsWithWidgets