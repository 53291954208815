import React, { useEffect } from 'react'
import { useState } from "react";
import { navItems } from "../services/navItems";
import useInitialDemand from './useInitialDemand';
import useFinalJudgment from './useFinalJudgment';
import useInterlocutoryJudgment from './useInterlocutoryJudgment';
import useRepresentationAdmissibleProceeding from './useRepresentationAdmissibleProceeding';
import useTestSection from './useTestSection';
import _ from 'lodash';
import useHandleIndicatorsInitialValues from './useHandleIndicatorsInitialValues';
import useSubmitFormValues from './useSubmitFormValues';

const useBodyModalState = ({ toggleShowinModal, expedientId }) => {

  const [currentNav, setCurrentNav] = useState(navItems[0]);
  const [errors, setErrors] = useState({});
  const CurrentForm = ((typeof currentNav === 'object') && currentNav.body) ? currentNav.body : () => (<></>);

  const initialDemand = useInitialDemand();
  const finalJudgment = useFinalJudgment();
  const interlocutoryJudgment = useInterlocutoryJudgment();
  const representationAdmissibleProceeding = useRepresentationAdmissibleProceeding();
  const testSection = useTestSection();

  useHandleIndicatorsInitialValues({
    expedientId,
    initialDemand,
    finalJudgment,
    interlocutoryJudgment,
    representationAdmissibleProceeding,
    testSection,
  });

  const indicatorstFormValues = {
    initial_demands: initialDemand.formValues,
    final_judgments: finalJudgment.formValues,
    interlocutory_judgments: interlocutoryJudgment.formValues,
    representation_admissible_proceedings: representationAdmissibleProceeding.formValues,
    test: testSection.formValues,
  }

  const handleSubmitFormValues = useSubmitFormValues({
    expedientId,
    indicatorstFormValues,
    setErrors,
    errors,
    onSuccessfulRequest: toggleShowinModal,
  });

  return {
    currentNav,
    setCurrentNav,
    CurrentForm,
    initialDemand,
    finalJudgment,
    interlocutoryJudgment,
    representationAdmissibleProceeding,
    testSection,
    handleSubmitFormValues,
    errors,
  }
}

export default useBodyModalState