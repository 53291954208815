import React from 'react'
import { Table } from 'react-bootstrap'
import { PartiesEntity } from '../../../../classes/Parties/Parties.entity'
import { typeParties } from '../../modals/partiesModal/assets/typeParties'

const PartsRelatedToTheExpedientTable = ({ expedientParts = [] }) => {
  return (
    <div style={{ overflow: 'auto', maxHeight: '250px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid #f5f5f5', backgroundColor: '#f5f5f5' }}>
        <h6 style={{ fontWeight: 'bold', color: '#000' }}>Datos de las partes</h6>
      </div>
      <Table style={{ width: '100%', overflow: '300px' }} bordered>
        <tbody style={{ width: '100%', fontSize: '12px' }}>
          {
            expedientParts.map((part, index) => (
              <tr key={index} style={{ width: '20px', padding:'2px', height: '20px' }}>
                <td>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <b style={{ fontSize: '12px' }}>{PartiesEntity.getPartieTypeLabel(part.party_type, typeParties)}</b>
                  </div>
                  {part.bussiness_name && <div style={{ fontSize: '12px' }}>{part.bussiness_name}</div>}
                    <div style={{ fontSize: '12px' }}>{part.names}</div>
                </td>
                {part.email && <td>{part.email}</td>}
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  )
}

export default PartsRelatedToTheExpedientTable