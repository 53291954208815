import { RequestNewVersion } from "../components/classes/RequestNewVersion";

export class UserServices {

    static async getMyMatter() {
        const request = new RequestNewVersion(`${process.env.REACT_APP_URL_API_LARAVEL}/get_my_matter`, "get", null, null);
        const response = await request.executeRequest();
        if (response.code === 200) {
            return response.response.data;
        } else {
            return [];
        }
    }
}