import { checkFields } from "../services/selectOptions/checkFieldsOptions"

export const initialDemandsDefaultValues = {
  measures_reparation: '',
  measures_protection: '',
  involved_minors: [],
  actor: false,
  defendant: false,
  third: false,
  minors: '',
}

export const legalEntityDefaultValues = {
  gender: '',
  type_person: '',
  community: '',
  which_community: '',
}

export const finalJudgmentDefaultFormValues = {
  measures_reparation_decreed: '',
  measures_reparation_denied: '',
  measures_protection_decreed: '',
  measures_protection_denied: '',
  principal_action: '',
  sense_resolution: '',
  protocol: '',
  which_protocol: '',
  crimes: '',
}

export const interlocutoryJudgmentDefaultFormValues = {
  precautionary_measures_decreed: '',
  precautionary_measures_requested: '',
  principal_action: '',
  sense_resolution: '',
  protocol: '',
  which_protocol: '',
  precautory_measures_denied: ''
}

export const representationAdmissibleProceedingDefaultFormValues = {
  [checkFields[0].value]: false,
  [checkFields[1].value]: false,
  [checkFields[2].value]: false,
  [checkFields[3].value]: false,
  [checkFields[4].value]: false,
  [checkFields[5].value]: false,
}

export const testSectionDefaultFormValues = {
  offered: '',
  admitted: '',
  unburdened: '',
}