import React, { forwardRef } from 'react';
import { Menu as MenuReactMenu } from '@szhsin/react-menu';
import styles from './Menu.module.css';
import { getMenuClassName } from './lib/utils/getMenuClassName';

const Menu = forwardRef((props, ref) => {
  return (
    <MenuReactMenu
      {...props}
      menuClassName={getMenuClassName(styles, props.menuClassName)}
      ref={ref}
    />
  )
});

export default Menu