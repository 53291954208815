import { NotificationManager } from "react-notifications";
import { typeParties } from "../../../partiesModal/assets/typeParties";

export class AddPartyDto {
    static validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    static validateName = (name) => {
        const re = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s.,]*$/;
        return re.test(name);
    }

    static validateAge = (age) => {
        const re = /^(?:1[01][0-9]|120|[1-9]?[0-9])$/;
        return re.test(age);
    };

    static validatePhone = (phone) => {
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    }

    static validateBirthDate = (birthDate, age) => {
        let date = new Date(birthDate);
        let today = new Date();
        if (date > today) {
            return false;
        }
        let ageDate = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());
        return date <= ageDate;
    }

    static fieldObligatories = [
        'names',
        'paternal_surname',
        'sex',
        'canReadAndWrite',
        'canSpeakSpanish',
        'levelStudies',
        'civilStatus',
        'nationality',
        'typeParts',
        'regime'
    ];

    static errorsByFormRules = {
        personal_info: [
            'names',
            'paternal_surname',
            'alias',
            'age',
            'birthDate',
            'regime',
            'type_representation',
            'relation_party_id'
        ],
        contact: [
            'email',
            'home',
        ],
        transparency: [
            'canReadAndWrite',
            'canSpeakSpanish',
            'levelStudies',
            'civilStatus',
            'nationality',
            'ocupation',
        ]
    }

    static validateErrors(listOfKeysToValidate = [], values) {
        const { formValues } = values;
        let errors = {};
        listOfKeysToValidate.forEach(key => {
            if (!formValues[key]) {
                errors[key] = errors[key] ? errors[key].push('El campo es obligatorio') : ['El campo es obligatorio'];
            } else {

                switch (key) {
                    case 'email':
                        if (!this.validateEmail(formValues.email)) {
                            errors.email = errors.email ? errors.email.push('El correo no es válido') : ['El correo no es válido'];
                        }
                        break;
                    case 'names':
                        if (!this.validateName(formValues.names)) {
                            errors.names = errors.names ? errors.names.push('El nombre no es válido') : ['El nombre no es válido'];
                        }
                        break;
                    case 'paternal_surname':
                        if (!this.validateName(formValues.paternal_surname)) {
                            errors.paternal_surname = errors.paternal_surname ? errors.paternal_surname.push('El apellido paterno no es válido') : ['El apellido paterno no es válido'];
                        }
                        break;
                    case 'maternal_surname':
                        if (!this.validateName(formValues.maternal_surname)) {
                            errors.maternal_surname = errors.maternal_surname ? errors.maternal_surname.push('El apellido materno no es válido') : ['El apellido materno no es válido'];
                        }
                        break;
                    case 'age':
                        if (!this.validateAge(formValues.age)) {
                            errors.age = errors.age ? errors.age.push('La edad no es válida') : ['La edad no es válida'];
                        }
                        break;
                    case 'phone':
                        if (!this.validatePhone(formValues.phone)) {
                            errors.phone = errors.phone ? errors.phone.push('El teléfono no es válido') : ['El teléfono no es válido'];
                        }
                        break;
                    case 'birthDate':
                        if (!this.validateBirthDate(formValues.birthDate, formValues.age)) {
                            errors.birthDate = errors.birthDate ? errors.birthDate.push('La fecha de nacimiento no es válida') : ['La fecha de nacimiento no es válida'];
                        }
                        break;
                    case 'email':
                        if (!this.validateEmail(formValues.email)) {
                            errors.email = errors.email ? errors.email.push('El correo no es válido') : ['El correo no es válido'];
                        }
                        break;
                }
            }

        });

        if (formValues.maternal_surname){
            if (!this.validateName(formValues.maternal_surname)) {
                errors.maternal_surname = errors.maternal_surname ? errors.maternal_surname.push('El apellido materno no es válido') : ['El apellido materno no es válido'];
            }
        }
        
        if (formValues.regime && formValues.regime === 'moral') {
            if (!formValues.business_name) {
                errors.business_name = errors.business_name ? errors.business_name.push('El campo es obligatorio') : ['El campo es obligatorio'];
            }
        }

        let typePartie = typeParties.find(item => item.codigo === formValues.typeParts);

        if (typePartie && typePartie.type === 'secondary') {
            if (!formValues.type_representation) {
                errors.type_representation = errors.type_representation ? errors.type_representation.push('El campo es obligatorio') : ['El campo es obligatorio'];
            }

            if (!formValues.relation_party_id) {
                errors.relation_party_id = errors.relation_party_id ? errors.relation_party_id.push('El campo es obligatorio') : ['El campo es obligatorio'];
            }
        }

        return errors;
    }

    static create(values) {
        const errors = this.validateErrors(this.fieldObligatories, values);

        if (Object.keys(errors).length > 0) {
            return [errors, null];
        }

        return [null, values];
    }

    static update(values, currentPart) {
        const errors = this.validateErrors(this.fieldObligatories, values);

        if (Object.keys(errors).length > 0) {
            return [errors, null];
        }

        return [null, values];
    }

    static findFormErrors(values) {
        return Object.keys(this.errorsByFormRules).filter(key =>
            this.errorsByFormRules[key].some(value => values.includes(value))
        );
    }

    static errorsByForm(errors) {
        return this.findFormErrors(Object.keys(errors));
    }
}