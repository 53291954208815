import Cookies from "js-cookie";
import { UuidAdater } from "../config/uuid.adapter";
import { typeParties } from "../../partiesModal/assets/typeParties";

export class PartiesService {
    static addToCookies(item) {
        let parties = localStorage.getItem('parties') || null;
        localStorage.removeItem('parties');
        if(parties) {
            parties = JSON.parse(parties);
            parties[UuidAdater.v4()] = item;
            localStorage.setItem('parties', JSON.stringify(parties));
        } else {
            const data = {};
            data[UuidAdater.v4()] = item;
            localStorage.setItem('parties', JSON.stringify(data));
        }
    }

    static updateToCookies(item, id) {
        let parties = localStorage.getItem('parties') || null;
        if(parties) {
            parties = JSON.parse(parties);
            parties[id] = item;
            localStorage.setItem('parties', JSON.stringify(parties));
        } else {
            const data = {};
            data[id] = item;
            localStorage.setItem('parties', JSON.stringify(data));
        }
    }
    
    static handleSubmit(values) {
        try {
            const data = {
                party_type: values.formValues.typeParts,
                party_data: values.formValues,
                show_cover: values.formValues.is_show_cover ? true : false
            }
            if(values.formValues.relation_party_id) {
                data['related_party_id'] = values.formValues.relation_party_id;
            }

            this.addToCookies(data);

            return true;
        } catch (error) {
            return false;
        }
    }

    static handleDelete(id) {
        try {
            let parties = localStorage.getItem('parties') || null;
            if(parties) {
                parties = JSON.parse(parties);
                delete parties[id];
                localStorage.setItem('parties', JSON.stringify(parties));
            }
            return true;
        } catch(err) {
            return false;
        }
    }

    static getPrelations(keys = []) {
        try {
            const parties = localStorage.getItem('parties') || null;
            if(parties) {
                const data = JSON.parse(parties);
                const res = {};
                Object.keys(data).map(key => {
                    if(keys.includes(data[key].party_type)) {
                        res[data[key].id] = data[key];
                    }
                });
                return res;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    }

    static handleUptate(values, currentPart) {
        try {
            const data = {
                party_type: values.formValues.typeParts,
                party_data: values.formValues,
                show_cover: values.formValues.is_show_cover ? true : false
            }
            if(values.formValues.relation_party_id) {
                data['related_party_id'] = values.formValues.relation_party_id;
            }

            this.updateToCookies(data, currentPart.id);
            return true;
        } catch (error) {
            return false;
        }
    }

    static getParties() {
        try {
            let parties = localStorage.getItem('parties') || null;
            if(parties) {
                const data = [];
                parties = JSON.parse(parties);
                Object.keys(parties).map(key => {
                    const party = parties[key];
                    party['id'] = key;
                    data.push(party);
                });
                return data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }     
    }

    static getPrimaryTypeParties() {
        let parties = typeParties.filter(party => party.type === 'primary');
        parties = parties.map(item => item.codigo);
        return parties;
    }

    static handleResetCookie() {
        localStorage.removeItem('parties');
    }
}