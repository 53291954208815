import React, { forwardRef } from 'react';
import { MenuButton as MenuButtonReactMenu } from '@szhsin/react-menu';
import styles from './MenuButton.module.css';
import clsx from 'clsx';

const MenuButton = forwardRef(({ children, ...restOfProps }, ref) => {
  return (
    <MenuButtonReactMenu
      {...restOfProps}
      className={clsx(styles.menuButton, restOfProps.className)}
      ref={ref}
    >
      {children}
      <i className={'fas fa-ellipsis-v'} />
    </MenuButtonReactMenu>
  )
})

export default MenuButton