import React, { useEffect } from 'react';
import { Form, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import TextField from '../../../formikFields/TextField';
import SelectField from '../../../formikFields/SelectField';
import DateField from '../../../formikFields/DateField';
import {
  PartsSexCatalog,
  PartsGenderCatalog,
  PartsTypeCatalog,
  PartsClasificationCatalog,
  RegimesCatalog
} from '../../../../utils/BulletinCatalogs';
import { Separator } from '../../../shared/Separator';
import styles from '../ExpedientForm.module.css';

export default function PartFormFields({ partData, partRegime, setPartRegime,errors}) {
  useEffect(() => {
    if (partData) {
      setPartRegime(partData.partRegime);
    }
  }, []);

  const handleChangeRegime = (value) => {
    setPartRegime(value);
  }

  return (
    <Tabs className={styles.tabMenus}>
      <Tab eventKey="personal_data" title="Datos personales">
        <div className={styles.containerRowFields}>
          <div className={styles.largeSize}>
            <Form.Group>
              <div className={styles.fieldLabel}>
                * Regimen fiscal
              </div>
              <SelectField
                id="partRegime"
                name='partRegime'
                placeholder="Elige una opción"
                options={RegimesCatalog}
                onChange={(value) => handleChangeRegime(value)}
              />
              <small>Selecciona el régimen fiscal al que pertenece la parte</small>
              {errors.partRegime && <div className={styles.error}>{errors.partRegime}</div>}
            </Form.Group>
          </div>
        </div>

        {partRegime && partRegime.value === 'moral' &&
          <>
            <div className={styles.containerRowFields}>
              <div className={styles.largeSize}>
                <div className={styles.fieldLabel}>
                  * Razón social
                </div>
                <TextField
                  id="partBusinessName"
                  name="partBusinessName"
                  placeholder="Escribe la razón social"
                  className={styles.input}
                />
                <small>Indica la razón social</small>
                {errors.partBusinessName && <div className={styles.error}>{errors.partBusinessName}</div>}
              </div>
            </div>

            <div className={styles.legalRepresentantTitle}>Información del Representante Legal</div>
            <Separator />
          </>
        }

        <div className={styles.containerRowFields}>
          <div className={styles.mediumSize}>
            <div className={styles.fieldLabel}>
              * Nombre(s)
            </div>
            <TextField
              id="partNames"
              name="partNames"
              placeholder="Escribe el/los nombre(s)"
              className={styles.input}
            />
            <small>Escribe el nombre de la parte</small>
            {errors.partNames && <div className={styles.error}>{errors.partNames}</div>}
          </div>

          <div className={styles.mediumSize}>
            <div className={styles.fieldLabel}>
              * Primer apellido
            </div>
            <TextField
              id="paternalSurname"
              name="paternalSurname"
              placeholder="Primer apellido"
              className={styles.input}
            />
            <small>Primer apellido</small>
            {errors.paternalSurname && <div className={styles.error}>{errors.paternalSurname}</div>}
          </div>
        </div>

        <div className={styles.containerRowFields}>
          <div className={styles.mediumSize}>
            <div className={styles.fieldLabel}>
              Segundo apellido
            </div>
            <TextField
              id="maternalSurname"
              name="maternalSurname"
              placeholder="Segundo apellido"
              className={styles.input}
            />
            <small>Segundo apellido</small>
            {errors.maternalSurname && <div className={styles.error}>{errors.maternalSurname}</div>}
          </div>

          <div className={styles.mediumSize}>
            <div className={styles.fieldLabel}>
              Alias
            </div>
            <TextField
              id="partAlias"
              name="partAlias"
              placeholder="Alias"
              className={styles.input}
            />
            <small>Alias</small>
            {errors.partAlias && <div className={styles.error}>{errors.partAlias}</div>}
          </div>
        </div>

        <div className={styles.containerRowFields}>
          <div className={styles.mediumSize}>
            <div className={styles.fieldLabel}>
              * Edad
            </div>
            <TextField
              id="partAge"
              name="partAge"
              placeholder="Edad"
              className={styles.input}
            />
            <small>Edad actual</small>
            {errors.partAge && <div className={styles.error}>{errors.partAge}</div>}
          </div>

          <div className={styles.mediumSize}>
            <Form.Group>
              <div className={styles.fieldLabel}>
                * Fecha de nacimiento
              </div>
              <div className={styles.calendarFieldApparience}>
                <div className={styles.calendarGroup}>
                  <div className={styles.datePickerElement}>
                    <DateField
                      name="partBirthday"
                      id="partBirthday"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className={styles.inputDate}
                      placeholderText={"Clic para seleccionar fecha"}
                    />
                  </div>
                  <div className={styles.iconPicker}>
                    <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
                  </div>
                </div>
              </div>
              {errors.partBirthday && <div className={styles.error}>{errors.partBirthday}</div>}
            </Form.Group>
          </div>
        </div>

        <div className={styles.containerRowFields}>
          <div className={styles.mediumSize}>
            <Form.Group>
              <div className={styles.fieldLabel}>Sexo</div>
              <SelectField
                id="partSex"
                name='partSex'
                placeholder="Elige una opción"
                options={PartsSexCatalog}
              />
            </Form.Group>
          </div>

          <div className={styles.mediumSize}>
            <Form.Group>
              <Form.Label>Género</Form.Label>
              <SelectField
                id="partGender"
                name='partGender'
                placeholder="Elige una opción"
                options={PartsGenderCatalog}
              />
            </Form.Group>
          </div>
        </div>

        <div className={styles.containerRowFields}>
          <div className={styles.mediumSize}>
            <Form.Group>
              <Form.Label>* Tipo de Parte</Form.Label>
              <SelectField
                id="partType"
                name='partType'
                placeholder="Elige una opción"
                options={PartsTypeCatalog}
              />
            </Form.Group>
          </div>

          <div className={styles.mediumSize}>
            <Form.Group>
              <Form.Label>* Clasificación</Form.Label>
              <SelectField
                id="partClasification"
                name='partClasification'
                placeholder="Elige una opción"
                options={PartsClasificationCatalog}
              />
            </Form.Group>
          </div>
        </div>
      </Tab>

      <Tab eventKey="contact_info" title="Datos de contacto">
        <div className={styles.containerRowFields}>
          <div className={styles.mediumSize}>
            <div className={styles.fieldLabel}>
              Correo electrónico
            </div>
            <TextField
              id="partEmail"
              name="partEmail"
              placeholder="Correo electrónico"
              className={styles.input}
            />
            <small>Email</small>
            {errors.partEmail && <div className={styles.error}>{errors.partEmail}</div>}
          </div>

          <div className={styles.mediumSize}>
            <div className={styles.fieldLabel}>
              Teléfono
            </div>
            <TextField
              id="partPhone"
              name="partPhone"
              placeholder="Número telefónico"
              className={styles.input}
            />
            <small>Teléfono</small>
            {errors.partPhone && <div className={styles.error}>{errors.partPhone}</div>}
          </div>

        </div>
      </Tab>
    </Tabs>
  );
}
