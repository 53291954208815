import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import WrapperFormikField from './WrapperFormikField';
import { useDropzone } from 'react-dropzone';
import styles from './ErrorMessagesShared.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

export default function DropzoneField({ onChange = () => { }, ...restOfProps }) {
  const { setFieldValue } = useFormikContext();

  const onDrop = useCallback(acceptedFiles => {
    setFieldValue(restOfProps.name, acceptedFiles[0]);
  }, [])

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone(
    {
      accept: 'application/pdf',
      multiple: false,
      onDrop
    },
  );

  return (
    <WrapperFormikField {...restOfProps}>
      {({ errorField }) => (
        <>
          <div {...getRootProps()}
            className={errorField ? styles.dropZoneBorderError : styles.dropZoneBorder}
          >
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p>Suelta los archivos aqui...</p> :
                <p>Arrastra aquí tus archivos o selecciónalos haciendo clic aquí</p>
            }
          </div>
          <small>Asegúrese de cargar un documento testado. El documento debe ser pdf</small>

          {acceptedFiles[0] && (
            <div
              style={{ marginTop: "20px" }}
            >
              {acceptedFiles[0].type === 'application/pdf' ?
                <div class="text-center">
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    color="red"
                    style={{
                      width: "250px",
                      height: "300px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  />
                  <p class="mT-15">{acceptedFiles[0].name}</p>
                </div>
                :
                <img src={URL.createObjectURL(acceptedFiles[0])} alt=''
                  style={{
                    width: "350px",
                    height: "350px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              }
            </div>
          )}

          {errorField && <div className={styles.feedbackErrorMessages}><li>{errorField}</li></div>}
        </>
      )
      }
    </WrapperFormikField >
  )
}