import '../../styles/styles.css';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { genders } from '../../assets/genders';
import { ages } from '../../assets/ages';
import { CustomInputDate } from '../shared/CustomInputDate';
import { secondCustomHeaderDatePicker } from '../shared/logicInputDate';
import { usePartiesContext } from '../../context/PartiesContext';
import { sex } from '../../assets/sex';
import { regimeCatalog } from '../../assets/regime';
import { findData, mappingData, mappingDataOne } from '../../helpers/parseAndFilterDataToForm';
import { ErrorsByKeyName } from '../shared/ErrorsByKeyName';
import { isError } from '../../helpers/logicDataParties';
import { PartiesFormPartiesRelations } from './PartiesFormPartiesRelations';
import _ from 'lodash';
import { clasificationParties } from '../../assets/clasification';
registerLocale('es', es);

const isAnyTypePartySelectedToShowCover = (listParties, party_type_selected, currentParty) => {
    let band = false;
	if(party_type_selected && listParties.length > 0) {
		const findedItems = listParties.filter(item => item.party_type === party_type_selected);
		if(findedItems) {
			findedItems.map(item => {
				if(item.show_cover) {
					band = true;
				}
			})
		}
	}

    if(!_.isEmpty(currentParty)) {
        const findedItemChecked = listParties.find(item => item.party_type === party_type_selected && item.show_cover === true);
        if(findedItemChecked) {
            if(findedItemChecked.id === currentParty.id) {
                band = false;
            }
        }
    }
	return band;
}

export const PartiesFormPersonalInfo = () => {
    const { values, handleChageForm, randomVal, currentPartyItem, listParties } = usePartiesContext();
    const [genderSelected, setGenderSelected] = useState(null);
    const [clasificationSelected, setClasificationSelected] = useState(null);
    const [ageSelected, setAgeSelected] = useState(null);
    const [sexSelected, setSexSelected] = useState(null);
    const [regimeSelected, setRegimeSelected] = useState(null);
    const [showCheckCover, setShowCheckCover] = useState(false);
    
    const handleChangeGender = (val) => {
        if (val) {
            setGenderSelected(val);
            handleChageForm(val, 'gender')
        } else {
            setGenderSelected(null);
            handleChageForm('', 'gender');
        }
    }

    const handleChangeClasification = (val) => {
        if(val) {
            setClasificationSelected(val);
            handleChageForm(val, 'partClasification');
        } else {
            setClasificationSelected(val);
            handleChageForm('', 'partClasification');
        }
    }

    const handleChangeAge = (val) => {
        if (val) {
            setAgeSelected(val);
            handleChageForm(val, 'age')
        } else {
            setAgeSelected(null);
            handleChageForm('', 'age');
        }
    }

    const handleChangeSex = (val) => {
        if (val) {
            setSexSelected(val);
            handleChageForm(val, 'sex');
        } else {
            setSexSelected(null);
            handleChageForm('', 'sex');
        }
    }

    const handleChangeRegime = (val) => {
        if(val) {
            setRegimeSelected(val);
            handleChageForm(val.value, 'regime');
        } else {
            setRegimeSelected(null);
            handleChageForm('', 'regime');
        }
    }

    useEffect( () => {
        // LOAD VALUES TO STATES
        if(values.formValues.gender) setGenderSelected(values.formValues.gender);
        if(values.formValues.sex) setSexSelected(values.formValues.sex);
        if(values.formValues.age) setAgeSelected(values.formValues.age);
	    if(values.formValues.regime) setRegimeSelected(mappingDataOne(findData(regimeCatalog, values.formValues.regime)));
        if(values.formValues.partClasification) setClasificationSelected(values.formValues.partClasification);
    }, [randomVal, currentPartyItem] );

    useEffect(() => {
		const showCheck = isAnyTypePartySelectedToShowCover(listParties, values.formValues.typeParts, currentPartyItem);
		setShowCheckCover(showCheck);
        if(showCheck && (_.isEmpty(currentPartyItem))) handleChageForm(false, 'is_show_cover');
	}, [values.formValues.typeParts])

    return (
        <>
            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={12}>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                        * Régimen de la parte
                        </Form.Label>
                        <Select
                        options={mappingData(regimeCatalog)}
                        placeholder='Selecciona una opción'
                        onChange={val => handleChangeRegime(val)}
                        value={regimeSelected}
                        classNamePrefix="my-select"
                        className={isError(values.errors, 'regime') ? 'select-error' : ''}
                        >
                        
                        </Select>
                        <ErrorsByKeyName listErrors={values.errors} value = 'regime' />
                    </Form.Group>
                    </Col>
                    
                    {values.formValues.regime === 'moral' &&		  
                    <Col xs={12} md={12}>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                        * Razón Social
                        </Form.Label>
                        <Form.Control
                        type='text'
                        placeholder=''
                        onChange={(e) => handleChageForm(e.target.value, 'business_name')}
                        value={values.formValues.business_name}
                        isInvalid={isError(values.errors, 'business_name')}				 
                        />
                        <ErrorsByKeyName listErrors={values.errors} value='business_name'/>
                    </Form.Group>
                    </Col>
                    }
                </Row>
            </Form>
            { values.formValues.regime === 'moral' && 
                <div className='border p-5 rounded mb-2' style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#c7ddff' }}>
                    <p>Información del representante legal</p>
                </div>
            }

            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* Nombres</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Ejemplo: John'
                                onChange={(e) => { handleChageForm(e.target.value, 'names') }}
                                value={values.formValues.names}
                                isInvalid={ isError(values.errors, 'names')}
                            />
                            
                            <ErrorsByKeyName listErrors={values.errors} value='names' />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* Apellido Paterno</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Ejemplo: Doe'
                                onChange={(e) => { handleChageForm(e.target.value, 'paternal_surname') }}
                                value={values.formValues.paternal_surname}
                                isInvalid={ isError(values.errors, 'paternal_surname')}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='paternal_surname' />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Apellido Materno</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder=''
                                onChange={(e) => { handleChageForm(e.target.value, 'maternal_surname') }}
                                value={values.formValues.maternal_surname}
                                isInvalid={ isError(values.errors, 'maternal_surname')}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='maternal_surname' />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Alias</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder=''
                                onChange={(e) => { handleChageForm(e.target.value, 'alias') }}
                                isInvalid={ isError(values.errors, 'alias')}
                                value={values.formValues.alias}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='alias' />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Edad</Form.Label>
                            <Select
                                options={mappingData(ages)}
                                placeholder='Selecciona una opción'
                                value={ageSelected}
                                onChange={val => handleChangeAge(val)}
                                classNamePrefix="my-select"
                                className={isError(values.errors, 'age') ? 'select-error' : ''}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='age' />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Fecha de nacimiento</Form.Label>
                            <DatePicker
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => secondCustomHeaderDatePicker(
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                )}
                                locale="es"
                                customInput={<CustomInputDate error={ isError(values.errors, 'birthDate') }/>}
                                maxDate={new Date()}
                                selected={ values.formValues.birthDate ? new Date(values.formValues.birthDate) : ''}
                                onChange={(date) => handleChageForm(date, 'birthDate')}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='birthDate' />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* Sexo</Form.Label>
                            <Select
                                options={mappingData(sex)}
                                placeholder='Selecciona una opción'
                                onChange={val => handleChangeSex(val)}
                                value={sexSelected}
                                classNamePrefix="my-select"
                                className={isError(values.errors, 'sex') ? 'select-error' : ''}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='sex' />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Género</Form.Label>
                            <Select
                                options={mappingData(genders)}
                                placeholder='Selecciona una opción'
                                onChange={val => handleChangeGender(val)}
                                value={genderSelected}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* Clasificación</Form.Label>
                            <Select
                                options={mappingData(clasificationParties)}
                                placeholder='Selecciona una opción'
                                onChange={val => handleChangeClasification(val)}
                                value={clasificationSelected}
                                classNamePrefix="my-select"
                                className={isError(values.errors, 'partClasification') ? 'select-error' : ''}
                            /> 
                            <ErrorsByKeyName listErrors={values.errors} value='partClasification' />

                        </Form.Group>
                    </Col>
                </Row>
            </Form>

	    <PartiesFormPartiesRelations />
        {values.formValues.typeParts &&
            <Form style={{with: '100%'}} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Check
                                type='checkbox'
                                label='¿Desea mostrar en caratula?'
                                checked={values.formValues.is_show_cover}
                                onChange={(e) => handleChageForm(e.target.checked, 'is_show_cover')}
                                disabled={showCheckCover}
                                id='showCover'
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        }
        </>
    );
}
