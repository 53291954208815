const documentTypeConfigEvidenceList = {
    writ: { 
      nameId: 'writ_id',
      documentType: 'writ'
    }, 
    promotion: { 
      nameId: 'promotion_id',
      documentType: 'promotion'
    },
    demand: { 
      nameId: 'demand_id',
      documentType: 'demand'
    }
};

export default documentTypeConfigEvidenceList;