import React, { useState } from 'react'
import { createContext, useContext } from "react";
import { UndefinedContextError } from '../../../../../../../../errors/contextError/contextError';
import _ from 'lodash';
import { onSaveSignatureToTheListOfSignatures } from '../../../../../../../../services/electronicSignature/multiSignature/onSaveSignatureToTheListOfSignatures';

const MultipleElectronicSignatureModalContext = createContext();
MultipleElectronicSignatureModalContext.displayName = 'MultipleElectronicSignatureModalContext';

const MultipleElectronicSignatureModalContextProvider = (props) => {

  const [electronicSignaturesPendingToSave, setElectronicSignaturesPendingToSave] = useState({});
  const [currentDocumentIdToSaveSignatures, setCurrentDocumentIdToSaveSignatures] = useState();

  const handleSaveSignatures = async () => {
    const electronicSignaturesPendingToSaveList = _.get(electronicSignaturesPendingToSave, currentDocumentIdToSaveSignatures, []);
    for (const electronicSignature of electronicSignaturesPendingToSaveList) {
      await onSaveSignatureToTheListOfSignatures(electronicSignature, currentDocumentIdToSaveSignatures);
    }
  }

  const handleRemoveSavedSignatures = () => {
    const documentIdList = Object.keys(electronicSignaturesPendingToSave);
    const documentIdListWithoutCurrentDocumentId = documentIdList.filter(documentId => documentId !== currentDocumentIdToSaveSignatures);
    const newElectronicSignaturesPendingToSave = {};

    for (const documentId of documentIdListWithoutCurrentDocumentId) {
      newElectronicSignaturesPendingToSave[documentId] = electronicSignaturesPendingToSave[documentId];
    }

    setElectronicSignaturesPendingToSave(newElectronicSignaturesPendingToSave);
  }

  const handleResetElectronicSignaturesPendingToSave = () => {
    setElectronicSignaturesPendingToSave({});
  }

  const handleAddElectronicSignaturePendingToSave = (signatureToSave, documentId) => {
    setElectronicSignaturesPendingToSave((previouState) => {
      const previouSignatureList = _.get(previouState, documentId, []);
      return {
        ...previouState,
        [documentId]: [...previouSignatureList, signatureToSave]
      }
    });
  }

  const getElectronicSignaturesPendingToSaveListByDocumentId = (documentId) => {
    return _.get(electronicSignaturesPendingToSave, documentId, []);
  }

  const contextValue = {
    electronicSignaturesPendingToSave,
    setElectronicSignaturesPendingToSave,
    currentDocumentIdToSaveSignatures,
    setCurrentDocumentIdToSaveSignatures,
    handleSaveSignatures,
    handleRemoveSavedSignatures,
    handleAddElectronicSignaturePendingToSave,
    getElectronicSignaturesPendingToSaveListByDocumentId,
    handleResetElectronicSignaturesPendingToSave,
  }

  return (
    <MultipleElectronicSignatureModalContext.Provider {...props} value={contextValue} />
  )
}

export const useMultipleElectronicSignatureModalContext = () => {
  const context = useContext(MultipleElectronicSignatureModalContext);
  if (context === undefined) {
    throw new UndefinedContextError('To use useElectronicSignatureModalContext you must wrap inside a ElectronicSignatureModalContextProvider')
  }
  return context;
}

export default MultipleElectronicSignatureModalContextProvider