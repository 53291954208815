import { getPartiesByExpedientId } from "../../government_book/details_expedient/modals/partiesModal/helpers/logicDataParties";

export class PartiesEntity {
    static getPartieTypeLabel(key = '', typeParties = []) {
        const findedParty = typeParties.find(party => party.codigo === key);
        if(findedParty) {
            return findedParty.etiqueta;
        }
        return '';
    } 

    static async getPartiesList(id_expedient) {
        let partiesList = await getPartiesByExpedientId(id_expedient);
        return partiesList || [];
    }
}