import React, { useContext, useEffect } from 'react';
import { PartiesItem } from '../parties/PartiesItem';
import { PartiesContext } from '../../context/PartiesContext';
import { PartiesForm } from '../forms/PartiesForm';
import { PartiesList } from '../parties/PartiesList';
import { PatyFormDelete } from '../forms/PatyFormDelete';
import LoaderContext from "../../../../../../layout/shared/loader_context.js";
import Loader from "../../../../../../layout/shared/Loader.js";
import ModalComponent from '../../../../../../helper_components/ModalComponent.js';

export const ModalPartiesBody = () => {

    const { toggleShowAddForm, showForm, isDeleteParty, isOpenEditForm, values, isFromLocal = false, isWithoutMainModal = false } = useContext(PartiesContext);

    const loader = useContext(LoaderContext);
    
    useEffect(() => {
	    loader.show(values.loading);
    }, [values.loading]);

    if(!isFromLocal && !isWithoutMainModal) {
        if (showForm || isOpenEditForm) return (<PartiesForm />);
        if ( isDeleteParty ) return <PatyFormDelete />;
    }
    
    if(!isWithoutMainModal) {
        if (showForm || isOpenEditForm) return (<PartiesForm />);
        if ( isDeleteParty ) return <PatyFormDelete />;
    }

    return (
        <div>
            <div className='d-flex justify-content-start'>
                <p className='h4 user-select-none'>Partes</p>

                <button
                    style={{
                        width: '25px',
                        height: '25px',
                        padding: '2px 0',
                        borderRadius: '50%',
			
                        textAlign: 'center',
                        fontSize: '20px',
                        borderColor: 'green',
                        fontWeight: 'bold',
                        marginLeft: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    className='btn'
                    onClick={(e) => {
                        e.preventDefault();
                        toggleShowAddForm();
                    }}
                >
                    <span>+</span>
                </button>
            </div>
            <PartiesList />

            {
                isWithoutMainModal && showForm && 
                <ModalComponent
                    header={'Agregar Parte'}
                    body={<PartiesForm />}
                    footer={<></>}
                    show={showForm}
                />
            }
            {
                isWithoutMainModal && isDeleteParty &&
                <ModalComponent
                    header={'Eliminar Parte'}
                    body={<PatyFormDelete />}
                    footer={<></>}
                    show={isDeleteParty}
                />
            }
	    <Loader />
        </div>
    )
    
}
