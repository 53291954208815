import React, { useMemo } from 'react';
import ReactDOM from "react-dom";
import styles from './ModalComponentPortal.module.css';
import { v4 as uuidv4 } from 'uuid';

const modalRoot = document.getElementById('modal-root');

const ModalComponentPortal = ({ header, body, footer, isModalShowing, onHide = () => { } }) => {

  const modalId = useMemo(() => `modal-portal-${uuidv4()}`, []);

  const onCloseModal = ({ target }) => {
    const hasModalBackdropBeenClicked = target.id === modalId;

    if (hasModalBackdropBeenClicked) {
      onHide();
    }
  }

  return (
    <>
      {
        isModalShowing
        &&
        ReactDOM.createPortal((
          <div
            className={styles.modal}
            onClick={onCloseModal}
            id={modalId}
          >
            <div className={styles.modal_content}    >
              <header>
                {header}
              </header>
              <section aria-label='Modal Body'>
                {body}
              </section>
              <footer  >
                {footer}
              </footer>
            </div>
          </div>
        ), modalRoot)
      }
    </>
  )
}

export default ModalComponentPortal;