import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap";
import { findCrimeById, findCrimeRequest } from "../../expedient_details_logic";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

const mappingData = (data = []) => {
	return data.map((item) => {
		return {
			value: item.id,
			label: `${item.code? item.code: item.id} - ${item.name}`,
		}
	});
}

export const AutoCompleteCrimes = ({ onChange, values, isDefaultValues = false }) => {
	const [ crimeSelected, setCrimeSelected ] = useState(null);
	const [aux, setAux] = useState([]);
	const getCrimeOptions = (pattern) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options = await findCrimeRequest(pattern);
				options = mappingData(options);
				resolve(options);
			}
			catch (err) {
				resolve([]);
			}
		});
	}

	const handleChange = (value) => {
		if (value) {
			setCrimeSelected(value);
			onChange({ ...values, crimes_id: value.value })
		} else {
			setCrimeSelected(null);
			onChange({ ...values, crimes_id: null })
		}
	}

	useEffect(() => {
		let findDefaultCrime = async (id) => {
			let defaultCrime = await findCrimeById(id);
			setCrimeSelected({ value: defaultCrime.id, label: `${defaultCrime.code} - ${defaultCrime.name}` });
		}

		if( isDefaultValues ) {
			let aux = values.bulletin ? values.bulletin : {};
			if( aux.crimes_id ) {
				findDefaultCrime(aux.crimes_id);
			}
		}
	}, []);

	useEffect(() => {
		getCrimeOptions('').then((firstElements) => setAux(firstElements)).catch(() => setAux([]));
	}, []);

	return (
		<Form.Group>
			<Form.Label>* Acción o Delito:</Form.Label>
			<AsyncSelect
				cacheOptions
				loadOptions={getCrimeOptions}
				defaultOptions={aux}
				onChange={val => handleChange(val)}
				value={crimeSelected}
				placeholder="Ingrese una palabra en el buscador"
				isClearable
			/>
		</Form.Group>
	);
}

AutoCompleteCrimes.propTypes = {
	onChange: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
	isDefaultValues: PropTypes.bool
}