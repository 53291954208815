import React, { useEffect, useState } from 'react'
import FormTitle from '../../FormTitle'
import { Form } from 'react-bootstrap'
import { useIndicatorsContext } from '../../../context/indicatorsContext/IndicatorsContext'
import { getElementFromOptionsList } from '../../../../../../../../services/dataFormatting/getElementFromOptionsList'
import Select from 'react-select'
import { finalJudgmentDefaultFormValues } from '../../../constants/defaultValues'
import _ from 'lodash'
import { renderErrorsByInputName } from '../../../../../../my_expedients/expedients_list_logic'
import { CrimesMultiselect } from '../../../../../../../shared/crimes/CrimesMultiselect'
import { UserEntity } from '../../../../../../../../shared/entities/user.entity'

const FinalJudgment = () => {
  const { finalJudgment, interlocutoryJudgment } = useIndicatorsContext();
  const [userMatters, setUserMatters] = useState([]);
  
  const {
    finalJudgmentFormMethods: {
      onFormChange,
      onFormRadioChange,
      onSelectChange,
      formValues,
      onFormChangeWithKeyValue
    },
    assertions,
    errors,
  } = finalJudgment;

  const {interlocutoryJudgmentFormMethods: {formValues: formValuesInterlocutoryJudgment}} = interlocutoryJudgment;

  const {
    isAProtocol,
    isNotAProtocol,
    isASenseResolutionpProceeded,
    isNotASenseResolutionpProceeded,
  } = assertions;

  useEffect(() => {
    const getMatters = async () => {
      const matters = await UserEntity.getMyMatter();
      setUserMatters(matters);
    }
    getMatters();
  }, []);

  return (
    <div>
      <FormTitle>
        Sentencia definitiva
      </FormTitle>
      <Form>
        <Form.Group controlId="measures_reparation_decreed">
          <Form.Label>
            Medidas de reparacion del daño decretadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_reparation_decreed'
            onChange={onFormChange}
            value={_.defaultTo(formValues.measures_reparation_decreed, finalJudgmentDefaultFormValues.measures_reparation_decreed)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName(errors, 'measures_reparation_decreed')}
        </Form.Group>

        <Form.Group controlId="measures_reparation_denied">
          <Form.Label>
            Medidas de reparacion del daño negadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_reparation_denied'
            onChange={onFormChange}
            value={_.defaultTo(formValues.measures_reparation_denied, finalJudgmentDefaultFormValues.measures_reparation_denied)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName(errors, 'measures_reparation_denied')}
        </Form.Group>

        <Form.Group controlId="measures_protection_decreed">
          <Form.Label>
            Medidas de proteccion decretadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_protection_decreed'
            onChange={onFormChange}
            value={_.defaultTo(formValues.measures_protection_decreed, finalJudgmentDefaultFormValues.measures_protection_decreed)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName(errors, 'measures_protection_decreed')}
        </Form.Group>

        <Form.Group controlId="measures_protection_denied">
          <Form.Label>
            Medidas de proteccion negadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_protection_denied'
            onChange={onFormChange}
            value={_.defaultTo(formValues.measures_protection_denied, finalJudgmentDefaultFormValues.measures_protection_denied)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName(errors, 'measures_protection_denied')}
        </Form.Group>
        {userMatters.includes('penal') &&
          <Form.Group controlId="principal_action">
            <CrimesMultiselect
              isDisabled={false}
              matter={'penal'}
              changeMainForm={onFormChangeWithKeyValue}
              defaultCrimes={_.defaultTo(formValues.crimes, finalJudgmentDefaultFormValues.crimes)}
              isError={false}
              isUpdate={true}
              handleChangeUpdate={onFormChangeWithKeyValue}
            />
          </Form.Group>
        }
        <Form.Group controlId="sense_resolution">
          <Form.Text className="text-muted">
            Sentido de la resolución
          </Form.Text>
          <Form.Check
            inline
            type="radio"
            label="Procedente"
            name="sense_resolution"
            id='sense_resolution_proceed'
            value={'proceed'}
            checked={isASenseResolutionpProceeded}
            onChange={onFormRadioChange}
          />
          <Form.Check
            inline
            type="radio"
            label="Improcedente"
            name="sense_resolution"
            id='sense_resolution_no_proceed'
            value={'no_proceed'}
            checked={isNotASenseResolutionpProceeded}
            onChange={onFormRadioChange}
          />
        </Form.Group>

        <Form.Group controlId="protocol">
          <Form.Text className="text-muted">
            Protocolo
          </Form.Text>
          <Form.Check
            inline
            type="radio"
            label="Si"
            name="protocol"
            id='protocol_si'
            value={true}
            checked={isAProtocol}
            onChange={onFormRadioChange}
          />
          <Form.Check
            inline
            type="radio"
            label="No"
            name="protocol"
            id='protocol_no'
            value={false}
            checked={isNotAProtocol}
            onChange={onFormRadioChange}
          />
        </Form.Group>

        {
          isAProtocol
          &&
          <Form.Group controlId="which_protocol">
            <Form.Label>
              ¿Cuál?
            </Form.Label>
            <Select
              name='which_protocol'
              placeholder="Selecciona un protocolo"
              options={[
                {value: 1,label:"Sentencia dictada con perspectiva de género"}, 
                {value: 2,label:"Sentencia dictada con perspectiva de infancia y adolescencia"},
                {value: 3,label:"Sentencia dictada con perspectiva de violencia contra las mujeres"}
              ]}
              onChange={onSelectChange}
              value={getElementFromOptionsList(formValues.which_protocol, [
                {value: 1,label:"Sentencia dictada con perspectiva de género"}, 
                {value: 2,label:"Sentencia dictada con perspectiva de infancia y adolescencia"},
                {value: 3,label:"Sentencia dictada con perspectiva de violencia contra las mujeres"}
              ])}
            />
          </Form.Group>
        }

      </Form>
    </div>
  )
}

export default FinalJudgment