import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, FormLabel } from "react-bootstrap";
import NavBar from "../layout/shared/NavBar";
import {
  fieldsValidation,
  renderErrorsByInputName,
  generateReport,
} from "./reports_logic";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getDecodedJwt } from "../../services/dataFormatting/getDecodedJwt";
import Cookies from "js-cookie";
import SectionTitle from "../juditial_bulletin/section_title/SectionTitle";
import { UserEntity } from "../../shared/entities/user.entity";

const Reports = (props) => {
  const [reports, setReports] = useState([]);
  const [reportType, setReportType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showEndDate, setShowEndDate] = useState(true);

  const history = useHistory();

  const handleReport = async () => {
    
    const requiredFields = ["reportType", "startDate", "endDate"];
    // If the report type is agreements_bulletin, the field agreements_bulletin is not required
    if(reportType && reportType.value == 'agreements_bulletin') requiredFields.pop();

    const responseOfValidation = fieldsValidation(requiredFields, {
      reportType,
      startDate,
      endDate,
    });

    if (typeof responseOfValidation === "object") {
      setErrors(responseOfValidation);
      NotificationManager.error(
        "Existen errores que te impiden continuar," +
        " por favor revisa los campos marcados en rojo"
      );
    } else {
      setIsLoading(true);
      const data = {
        type_report: reportType.value,
        initial_date: startDate,
        final_date: reportType.value == 'agreements_bulletin' ? startDate : endDate
      }
      const response = await generateReport(data);
      if (response) {
        NotificationManager.success("Reporte generado con éxito");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const getReportsOptions = () => {
    setReports(null);
    const reportsArr = [];

    if(UserEntity.validatePermissions('report_permission_expiration')) {
      reportsArr.push({ label: "Reporte de vencimiento", value: "expirations" });
    }

    if(UserEntity.validatePermissions('report_permission_expedients')) {
      reportsArr.push({ label: "Reporte de expedientes", value: "expedient" });
    }
    
    if(UserEntity.validatePermissions('report_aggrements_bulletin')) {
      reportsArr.push({ label: "Reporte de acuerdo en boletín", value: "agreements_bulletin" });
    }
    setReports(reportsArr);
  };

  const handleChangeReportType = (e) => {
    setReportType(e);
    delete errors["reportType"];
    setErrors(errors);
  };

  const handleCancel = () => {
    setReportType(null);
    setStartDate(null);
    setEndDate(null);
    setErrors({});
  };

  useEffect(() => {
    getReportsOptions();
  }, []);

  useEffect(() => {
    if(reportType) {
      if(reportType.value == 'agreements_bulletin') {
        setShowEndDate(false);
      } else {
        setShowEndDate(true);
      }
    }
  }, [reportType]);

  return (
    <>
      <NavBar {...props} />
      <SectionTitle text="Generar Reporte" />
      <Container className="mt-8">
        <Row style={{ minHeight: "100vh" }}>
          <Col
            sm={12}
            md={{ span: 10, offset: 1 }}
            lg={{ span: 8, offset: 2 }}
            xl={{ span: 10, offset: 1 }}
            className="mY-30"
          >
            <Card className="mt-4">
              <Card.Header
                style={{ background: "#A3BFEA", textAlign: "center" }}
              >
                <h4 className="font-weight-bold">Seleccione le tipo de reporte</h4>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm className="my-10">
                    <FormLabel>
                      <b>*Tipo de Reporte</b>
                    </FormLabel>
                    <Select
                      name="reportType"
                      options={reports ? reports : []}
                      onChange={(e) => handleChangeReportType(e)}
                      value={reportType ? reportType : ""}
                      isLoading={isLoading}
                      placeholder="Seleccione un tipo de reporte"
                      
                      noOptionsMessage={
                        reportType
                          ? () => ""
                          : () => "Por favor solicite los permisos para generar reportes"
                      }
                    />
                    {renderErrorsByInputName(errors, "reportType")}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm className="my-10">
                    <FormLabel>
                      <b>*Fecha Inicial</b>
                    </FormLabel>
                    <Form.Control
                      type="date"
                      value={startDate ? startDate : ""}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    {renderErrorsByInputName(errors, "startDate")}
                  </Col>
                  {showEndDate &&
                    <Col sm className="my-10">
                      <FormLabel>
                        <b>*Fecha Final</b>
                      </FormLabel>
                      <Form.Control
                        type="date"
                        value={endDate ? endDate : ""}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                      {renderErrorsByInputName(errors, "endDate")}
                    </Col>
                  }
                </Row>
                <Row className="mt-4">
                  <Col sm className="d-flex justify-content-start">
                    <button className="btn btn-danger" onClick={handleCancel}>
                      {isLoading ? "Cancelando..." : "Cancelar"}
                    </button>
                  </Col>
                  <Col sm className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={handleReport}
                    >
                      {isLoading ? "Generando..." : "Generar"}
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Reports;
