import React from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import WrapperFormikField from './WrapperFormikField';
import styles from './ErrorMessagesShared.module.css';

export default function TextareaField({ onChange = () => { }, ...restOfProps }) {
  const { setFieldValue } = useFormikContext();

  const handleChange = (value) => {
    onChange(value);
    setFieldValue(restOfProps.name, value.target.value);
  }

  return (
    <WrapperFormikField {...restOfProps}>
      {({ currentValue, errorField }) => (
        <>
          <Form.Control
            as={"textarea"}
            {...restOfProps}
            onChange={handleChange}
            value={currentValue}
            isInvalid={errorField}
          />
          {errorField && <div className={styles.feedbackErrorMessages}><li>{errorField}</li></div>}
        </>
      )}
    </WrapperFormikField>
  )
}