import '../../styles/styles.css';
import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { ErrorsByKeyName } from '../shared/ErrorsByKeyName';
import { usePartiesContext } from '../../context/PartiesContext';
import { isError } from '../../helpers/logicDataParties';
import { findData, mappingData, mappingDataOne } from '../../helpers/parseAndFilterDataToForm';
import { typeParties } from '../../assets/typeParties';
import _, { set } from 'lodash';
import ModalComponent from '../../../../../../helper_components/ModalComponent';
const getOptionsByMattersOfUser = ( mattersByUser ) => {
    let namesOfMatter = mattersByUser.map(item => `${item.name}`.toLowerCase());
    let res = typeParties.filter(party =>
	party.permittedSubjects.some(permitted => namesOfMatter.includes(`${permitted}`.toLowerCase()))
    );
    return res || [];
}

export const PartiesFormType = () => {
    const [ partySelected, setPartySelected ] = useState(null);
    const { values, handleChageForm, randomVal, currentPartyItem, mattersByUser, setValues } = usePartiesContext();
    const [showModalConfirmChangeRelation, setShowModalConfirmChangeRelation] = useState(false);
    const [typePartSelected, setTypePartySelected] = useState(null);

    const handleChangeTypePartie = (val) => {
        const {relation_party_id = ''} = currentPartyItem.party_data || {};
        
        if (val) {
            if(!_.isEmpty(currentPartyItem) && relation_party_id !== '') {
                setTypePartySelected(val);
                setShowModalConfirmChangeRelation(true);
                return;
            }                
            setPartySelected(val);
            setValues({
                ...values,
                formValues: {
                    ...values.formValues,
                    'relation_party_id': '',
                    'typeParts': val.value
                }
              });
        } else {
            setPartySelected(null);
            handleChageForm('', 'typeParts');
        }
    }

    const handleConfirmChangeRelation = () => {
        setValues({
            ...values,
            formValues: {
                ...values.formValues,
                'relation_party_id': '',
                'typeParts': typePartSelected.value
            }
        });
        setPartySelected(typePartSelected);
        setShowModalConfirmChangeRelation(false);
    }

    const handleCancelChangeRelation = () => {
        setShowModalConfirmChangeRelation(false);
        setTypePartySelected(null);
    }
    
    
    useEffect( () => {
        if(values.formValues.typeParts) setPartySelected(mappingDataOne(findData(typeParties, values.formValues.typeParts)));
    }, [randomVal, currentPartyItem] );
    
    return (
        <Form style={{ width: '100%', zIndex: 100 }} className='border p-5 rounded mb-2'>
            <Row>
                <Col xs={12} md={12}>
                    <Form.Group className='mb-3'>
                        <Form.Label>* Tipo de parte</Form.Label>
                        <Select
                            options={mappingData(getOptionsByMattersOfUser(mattersByUser))}
                            placeholder='Selecciona una opción'
                            classNamePrefix="my-select"
                            className={isError(values.errors, 'typeParts') ? 'select-error class' : 'class'}
                            onChange={val => handleChangeTypePartie(val)}
                            value={partySelected}
                            style={{
                                zIndex: 100,
                            }}
                        />
                        <ErrorsByKeyName listErrors={values.errors} value='typeParts' />
                    </Form.Group>
                </Col>
            </Row>

            <ModalComponent
                show={showModalConfirmChangeRelation}
                header='¿Estás seguro de cambiar el tipo de parte?'
                footer={
                    <div className='d-flex justify-content-between'>
                        <button className='btn btn-danger mr-2' onClick={() => handleCancelChangeRelation()}>Cancelar</button>
                        <button className='btn btn-primary' onClick={() => handleConfirmChangeRelation()}>Continuar</button>
                    </div>
                }
                body={
                    <div className='d-flex flex-column'>
                        <p>Al cambiar el tipo de parte, se perderan todas sus relaciones asociadas</p>
                    </div>
                }
            />
        </Form>
    );
}
