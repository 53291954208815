import React from "react";
import { RequestNewVersion } from "../../classes/RequestNewVersion";
import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import { getLabelFromDictionary } from "../../../config/dictionary";
import { PartiesService } from "../details_expedient/modals/partiesLocalModal/services/partiesServices";
import { typeParties } from "../details_expedient/modals/partiesModal/assets/typeParties";
import { getPartiesByExpedientId } from "../details_expedient/modals/partiesModal/helpers/logicDataParties";

export const initColumnsMyExpedients = (showComment, canTurnForeign) => {

  const specialPermissions = Cookies.getJSON('special_permissions_03');
  const isGeneralSecretaryPermissionEnabled = _.get(specialPermissions, '[0].general_secretariat') === '1';

  const generalSecretaryColmns = [
    {
      dataField: "party",
      text: "Partido",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "court",
      text: "Juzgado",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
  ]

  const defaultColumns = [
    {
      dataField: "expedient_number_link",
      text: getLabelFromDictionary( 'tableContent', 'label' ),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "reception_date",
      text: "Fecha de recepción",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "comments",
      text: "Comentarios",
      sort: true,
      style: showComment ? { textAlign: "justify" } : { display: "none" },
      headerStyle: showComment ? { textAlign: "center" } : { display: "none" }
    },
    {
      dataField: "time",
      text: "Tiempo",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "responsible",
      text: "Responsable",
      sort: true,
      style: canTurnForeign === "1" ? { textAlign: "center" } : { display: "none" },
      headerStyle: canTurnForeign === "1" ? { textAlign: "center" } : { display: "none" }
    },
    ...(isGeneralSecretaryPermissionEnabled ? generalSecretaryColmns : []),
    {
      dataField: "time_indicator",
      text: "Indicador",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    }
  ];

  return defaultColumns;
};

export const getMyExpedientsList = async (
    setExpedients,
    setTotalExpedients,
    page,
    sizePerPage,
    setShowModal,
    setOptions,
    setTransfer,
    transfer,
    setTextNotData,
    setNonSelectableArray,
    canTurnForeign,
    setShowExpiration,
    setDocumentsData,
    loader = { show: () => { }, status: false },
) => {

  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/find/user/${userId_03}/${canTurnForeign}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseMyExpedients = await request.executeRequest();

  if (responseMyExpedients.code !== 200) {

    NotificationManager.error(`Algo sucedió y no fue posible traer los ${getLabelFromDictionary('global', 'lowerMainName')}, inténtalo nuevamente`);
  } else {
    if (
      responseMyExpedients.response.error &&
      responseMyExpedients.response.message &&
      typeof responseMyExpedients.response.message === "string"
    ) {
      NotificationManager.error(responseMyExpedients.response.message);
    }
    else if (
      responseMyExpedients.response.electronicExpedients.length === 0 &&
      responseMyExpedients.response.total === 0
    ) {

      setTextNotData(`No se han encontrado ${getLabelFromDictionary('global', 'lowerMainName')}`);
    }
    else {
      const expedients = await formattedExpedients(
        responseMyExpedients.response.electronicExpedients,
        setShowModal,
        setOptions,
        setTransfer,
        transfer,
        setShowExpiration,
        setDocumentsData,
        loader,
      );
      const expedientsReduce = expedients.reduce((array, element) => {
        if (element['released'] === false) {
          array.push(element['id']);
        }
        return array;
      }, []);

      setTotalExpedients(responseMyExpedients.response.total);
      setExpedients(expedients);
      setNonSelectableArray(expedientsReduce);
    }
  }
};

const formattedExpedients = (
    expedients,
    setShowModal,
    setOptions,
    setTransfer,
    transfer,
    setShowExpiration,
    setDocumentsData,
    loader = { show: () => { }, status: false },
) => {
  expedients.map( ( expedient, index ) => {

    expedient["actions"] = (
        <Row key={index} center="xs" around="xs" className={ expedient["released"] ? "" : "d-n" }>
            <Col xs={12} className="procedures-actions">
                <div
                    className="continue"
                    onClick={() => getUsersToTransfer(
                        setShowModal,
                        setOptions,
                        expedient,
                        setTransfer,
                        transfer,
                        true,
                        loader,
                    )}
                    id={ expedient["id"] + "button-transfer" }
                >
                    <i className="ti-arrow-circle-right" title={`Transferir ${getLabelFromDictionary('global', 'sigleMainName')}`} />
                </div>
            </Col>
        </Row>
    );
    expedient["expedient_number_link"] = (
      <Link
        to={{
          pathname: `/expedient_details/${expedient.id}`, 
          state: { 
            labelPath: 'Mis expedientes',
            prevPath: window.location.pathname
          }
        }}
      >
        {expedient["expedient_number"]}
      </Link>
    );
    expedient["reception_date"] = getTextOfDate(new Date(expedient['updated_at'].replace(/-/g, "/")));
    expedient["time"] = getTimeTranscurred(new Date(expedient['updated_at'].replace(/-/g, "/")));
    expedient['party'] = expedient['party'] ? expedient['party'] : 'N/A';
    expedient['court'] = expedient['court'] ? expedient['court'] : 'N/A';
    expedient["comments"] = (<div id={expedient['id'] + 'div-comments'} />);
    expedient["time_indicator"] = (<a onClick={() => {
      setDocumentsData(expedient['expirations']);
      setShowExpiration(true)
    }}><span className={"dot-" + expedient.warning_flag} /></a>);


    return expedient;
  });

  return expedients;
};

export const getTimeTranscurred = (date) => {

  const dateExpedientReceived = new Date(date.getTime());
  const today = new Date();
  return "Hace " + Math.round((today - dateExpedientReceived) / 86400000) + (Math.round((today - dateExpedientReceived) / 86400000) > 1 || Math.round((today - dateExpedientReceived) / 86400000) === 0 ? " días" : " día");
  
};

export const getTextOfDate = (initialDate) => {

  const date = new Date(initialDate).toLocaleDateString('es-MX', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
  const time = new Date(initialDate).toLocaleTimeString('es-MX', {
    hour: '2-digit',
    minute: '2-digit'
  });

  return date + " a las " + time;
};

export const getUsersToTransfer = async (
    setShowModal,
    setOptions,
    expedient,
    setTransfer,
    transfer,
    notShowUserLogged,
    loader = { show: () => { }, status: false },
) => {
  const { userId_03, special_permissions_03 = '[]' } = Cookies.get();
  let specialPermissions = JSON.parse(special_permissions_03);
  const generalSacretariaPermission = _.get(specialPermissions, '[0].general_secretariat');
  const isGeneralSecretariatPermissionEnabled = generalSacretariaPermission === '1';
  let expedientId = expedient ? expedient.id : 0;
  let users = [];
    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/courts/${userId_03}${isGeneralSecretariatPermissionEnabled ? `/${expedientId}` : ''}`,
        "get",
        null,
        {}
      );

    loader.show(true);
    const usersOfSameCourt = await request.executeRequest();
    loader.show(false);

  if (usersOfSameCourt.code !== 200) {
    NotificationManager.error("No es posible cargar la lista de usuarios para la transferencia");
  }
  else {
    if (usersOfSameCourt.response.users && typeof usersOfSameCourt.response.users) {
      for (const key in usersOfSameCourt.response.users) {
        if (!notShowUserLogged) users.push({
          label: usersOfSameCourt.response.users[key],
          value: key
        });
        if (notShowUserLogged && key !== userId_03) users.push({
          label: usersOfSameCourt.response.users[key],
          value: key
        });
      }

      if (expedient) {
        setTransfer({
          ...expedient,
          ...transfer,
          expedient_number: expedient["expedient_number"],
          actor_name: expedient["actor_name"],
          actor_firstname: expedient["actor_firstname"],
          actor_secondname: expedient["actor_secondname"],
          defendant_name: expedient["defendant_name"],
          defendant_firstname: expedient["defendant_firstname"],
          defendant_secondname: expedient["defendant_secondname"],
          via: {
            value: null,
            label: expedient["via"]
          },
          kind_judgment: {
            value: null,
            label: expedient["kind_judgment"]
          },
          lawyer_defendant_name: expedient["lawyer_defendant_name"],
          lawyer_defendant_firstname: expedient["lawyer_defendant_firstname"],
          lawyer_defendant_secondname: expedient["lawyer_defendant_secondname"],
          lawyer_actor_name: expedient["lawyer_actor_name"],
          lawyer_actor_firstname: expedient["lawyer_actor_firstname"],
          lawyer_actor_secondname: expedient["lawyer_actor_secondname"],
          actor_email: expedient["actor_email"],
          defendant_email: expedient["defendant_email"],
          lawyer_actor_email: expedient["lawyer_actor_email"],
          lawyer_defendant_email: expedient["lawyer_defendant_email"],
        });
      }
      setOptions(users);
      setShowModal();

    }
  }
};

export const transferExpedient = async (
  transfer,
  props,
  setErrors,
  toCreate,
  clearExpedientListToBeTurn = () => { },
) => {
  setErrors({});

  if (transfer.id) {
    let partiesList = await getPartiesByExpedientId(transfer.id);
    transfer.parties_array = partiesList;
  }

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/release`,
    "post",
    null,
    {
      ...transfer,
      force: toCreate
    }
  );
  const responseTransfer = await request.executeRequest();

  if (responseTransfer.code !== 200) {

    if (
      responseTransfer.response.error &&
      typeof responseTransfer.response.message === "object"
    ) {
      // eslint-disable-next-line
      const errors = Object.keys(responseTransfer.response.message).map((key, index) => {
        return responseTransfer.response.message[key]
      });
      NotificationManager.error(
        errors.length > 0 ?
          errors[0] :
          'Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo'
      );
      setErrors(responseTransfer.response.message);
    }
    else if (
      responseTransfer.response.error &&
      typeof responseTransfer.response.message === "object"
    ) {
      responseTransfer.response.error &&
      NotificationManager.error('Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo');
      setErrors(responseTransfer.response.message);
    }
    else {
        if ( responseTransfer.response.governmentBook && responseTransfer.response.message ) {
            const { userId_03 } = Cookies.get();

            clearExpedientListToBeTurn();
            window.localStorage.removeItem("user");
            NotificationManager.success(responseTransfer.response.message);

            if ( parseInt(userId_03) == parseInt(responseTransfer.response.governmentBook.receiver_id) ) {
                window.location.reload(false);
            } else {
                props.history.push("/pending_transfers");
            }
        }
    }
  }
  else {
    if (responseTransfer.response.governmentBook && responseTransfer.response.message) {
      const { userId_03 } = Cookies.get();

      localStorage.removeItem("elementsAdded");
      window.localStorage.removeItem("user");
      NotificationManager.success(responseTransfer.response.message);

      if (parseInt(userId_03) == parseInt(responseTransfer.response.governmentBook.receiver_id)) {
        window.location.reload(false);
      } else {
        props.history.push("/pending_transfers");
      }
    }
  }
};

export const renderErrorsList = (errorsKeys, errors) => {
  if (errorsKeys && errorsKeys.length > 0) {
    const errorsList = errorsKeys.map((errorKey, index) => {
      return (
        <li key={index} style={{ padding: "0px 25px 0px", color: "red", fontSize: ".8rem" }}>
          <small>
            {errors[errorKey]}
          </small>
        </li>
      );
    })
    return errorsList;
  }
  else {
    return (
      <li style={{ padding: "5px 5px", color: "red", fontSize: ".8rem" }}>
        <small>
          {errors}
        </small>
      </li>
    );
  }
};

const ErrorsByInputName = ({ children, ...restOfProps }) => {
  return (
    <li {...restOfProps} style={{ padding: "0px 0px 0px", color: "red", fontSize: ".8rem", marginLeft: "1.0rem" }}>
      <small>
        {children}
      </small>
    </li>
  );
}

export const renderErrorsByInputName = (errors, inputName) => {

  const errorByInputName = _.get(errors, inputName);
  const doesErrorByInputNameExist = Boolean(errorByInputName);
  const isErrorByInputNameAList = Array.isArray(errorByInputName);

  if (!doesErrorByInputNameExist) {
    return
  }

  if (isErrorByInputNameAList) {
    const errorsList = errorByInputName.map((error, index) => {

      return (
        <ErrorsByInputName key={index} >
          {error}
        </ErrorsByInputName>
      );
    })
    return errorsList;
  }

  return (
    <ErrorsByInputName>
      {errorByInputName}
    </ErrorsByInputName>
  );

};

const validateParties = () => {
  try {
    let isOk = false;
    const parties = PartiesService.getParties();
    const typeParties = PartiesService.getPrimaryTypeParties();
    for(let i = 0; i < parties.length; i++) {
      if(typeParties.includes(parties[i].party_type)) {
        isOk = true;
        break;
      }
    }
    return isOk;
  } catch (error) {
    return false;
  } 
}

export const validateParties2 = (partiesList = []) => {
  try {
    let isOk = false;
    let partiesPrimary = typeParties.filter(party => party.type === 'primary');
    partiesPrimary = partiesPrimary.map(item => item.codigo);
    for(let i = 0; i < partiesList.length; i++) {
      if(partiesPrimary.includes(partiesList[i].party_type)) {
        isOk = true;
        break;
      }
    }
    return isOk;
  } catch(err) {
    return false;
  }

}

export const fieldsValidation = (requiredFields, objectToSend, selectedAgreementTypes, partiesList = [], fromLocal = false) => {
  const fieldsObjectToSend = Object.keys(objectToSend);
  let missingFields = {};
  requiredFields.forEach((field, index) => {
    if (!fieldsObjectToSend.includes(field) || !objectToSend[field]) {
      if(field != 'parties_array') missingFields[field] = ["Este campo es requerido"];
      if(field == 'parties_array') {
        const isOk = fromLocal ? validateParties2(partiesList) : validateParties();
        if(!isOk) {
          missingFields['parties_array'] = ["Es necesario agregar una parte de tipo principal"];
        }
      }
    };

    if (Array.isArray(selectedAgreementTypes) && objectToSend['type'] === 'agreement') {
      if (selectedAgreementTypes.length == 0) {
        missingFields['alias'] = ["Este campo es requerido"];
      } else {
        if (selectedAgreementTypes == null) {
          missingFields['alias'] = ["Este campo es requerido"];
        }
      }
    }

    
  });

  if (Object.keys(missingFields).length > 0) {
    return missingFields;
  }
  else return true;
};

export const getSearchOfExpedients = async (
  search,
  sizePerPage,
  page,
  setData,
  setTotalExpedients,
  setFunctionsToOpenModal,
  setOptions,
  setTransfer,
  transfer,
  setTextNotData,
  canTurnForeign,
  setShowExpiration,
  setDocumentsData
) => {
  const { userId_03 } = Cookies.get();
  search = String(search).replace(/\//g, '-');
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/search/user/${userId_03}/${search}/${canTurnForeign ? 1 : 0}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseSearch = await request.executeRequest();

  if (responseSearch.code === 200) {

    if (responseSearch.response.electronicExpedients && responseSearch.response.electronicExpedients.length > 0) {

      const formattedExpedientsBySearch = await formattedExpedients(
        responseSearch.response.electronicExpedients,
        setFunctionsToOpenModal,
        setOptions,
        setTransfer,
        transfer,
        setShowExpiration,
        setDocumentsData
      );
      setTotalExpedients(responseSearch.response.total);
      setData(formattedExpedientsBySearch);
    }
    else {
      setTextNotData("No se han encontrado resultados");
      setData([]);
    }
  }
  else if (
    responseSearch.code === 404 &&
    responseSearch.response.error &&
    typeof responseSearch.response.message === "string" &&
    responseSearch.response.message !== ""
  ) {
    setTextNotData(responseSearch.response.message);
    setData([]);
  }
  else {
    setTextNotData("No se han encontrado resultados");
    setData([]);
  }
};

export const cleanOfInputsDeleted = (
  currentElements,
  elementId
) => {
  if (currentElements.length === 0) {
    const elementsTextareaToDelete = document.getElementsByTagName('textarea');
    for (let i = 0; i < elementsTextareaToDelete.length; i++) {

      if (elementsTextareaToDelete[i].id !== elementId) elementsTextareaToDelete[i].style.display = 'none';
    };
  }
};

export const multipleReleaseExpedients = async (
  arrayExpedients,
  setCloseModalMultipleTransfer,
  setData,
  setTotalProcedures,
  page,
  sizePerPage,
  setFunctionsToOpenModal,
  setOptions,
  setTransfer,
  transfer,
  setTextNotData,
  setNonSelectableArray,
  canTurnForeign,
  setShowExpiration,
  setDocumentsData,
  clearExpedientListToBeTurn = () => { },
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/multi_release`,
    "post",
    null,
    {
      governmentBooks: arrayExpedients
    }
  );

  const responseMultipleRelease = await request.executeRequest();

  if (responseMultipleRelease.code === 200) {

    if (responseMultipleRelease.response.governmentBooks &&
      responseMultipleRelease.response.governmentBooks.length > 0 &&
      responseMultipleRelease.response.governmentBooks.length === arrayExpedients.length) {

      clearExpedientListToBeTurn();
      await getMyExpedientsList(
        setData,
        setTotalProcedures,
        page,
        sizePerPage,
        setFunctionsToOpenModal,
        setOptions,
        setTransfer,
        transfer,
        setTextNotData,
        setNonSelectableArray,
        canTurnForeign,
        setShowExpiration,
        setDocumentsData
      );
      NotificationManager.success(`Los ${getLabelFromDictionary('global', 'lowerMainName')} seleccionados han sido turnados con éxito`);
      setCloseModalMultipleTransfer();
    }
    else {

      NotificationManager.error("Hubo un problema durante el turnado masivo, inténtalo nuevamente");
    }
  }
  else {

    NotificationManager.error("Hubo un problema durante el turnado masivo, inténtalo nuevamente");
  }
};

export const disabledButtonsToSingleTransfer = (
  elementsToDisabledButton,
  disabled
) => {
  if (disabled) {
    for (let i = 0; i < elementsToDisabledButton.length; i++) {
      let buttonToTransfer = document.getElementById(elementsToDisabledButton[i]['id'] + 'button-transfer');
      if (buttonToTransfer) {
        buttonToTransfer.style.pointerEvents = 'none';
        buttonToTransfer.style.backgroundColor = '#ebe7e7';
      }
    }
  }
  else {
    for (let i = 0; i < elementsToDisabledButton.length; i++) {
      let buttonToTransfer = document.getElementById(elementsToDisabledButton[i]['id'] + 'button-transfer');
      if (buttonToTransfer) {
        buttonToTransfer.style.pointerEvents = '';
        buttonToTransfer.style.backgroundColor = '#c1c1c1'
      }
    }
  }
};

export const getCatalogOptionsKindJudgment = async (
  setKindJudgmentOptions
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/catalogs/judgement_types`,
    "get",
    null,
    {}
  );

  const requestOptions = await request.executeRequest();

  if (requestOptions.code === 200) {
    const judgementTypes = await optionsToFormat(
      requestOptions.response.judgementTypes.length > 0 ?
        requestOptions.response.judgementTypes : []
    );
    setKindJudgmentOptions(judgementTypes);
  }
  else {
    setKindJudgmentOptions([{
      value: null,
      label: 'Ha ocurrido un error...'
    }]);
  }
};

export const getCatalogOptionsLegalWays = async (
  setLegalWaysOptions
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/catalogs/legal_ways`,
    "get",
    null,
    {}
  );

  const requestOptions = await request.executeRequest();
  if (requestOptions.code === 200) {
    const legalWays = await optionsToFormat(
      requestOptions.response.legalWays.length > 0 ?
        requestOptions.response.legalWays : []
    );
    setLegalWaysOptions(legalWays);
  }
  else {
    setLegalWaysOptions([{
      value: null,
      label: 'Ha ocurrido un error...'
    }]);
  }
};

export const getOptionsKindJudgment = async () => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/catalogs/judgement_types`,
    "get",
    null,
    {}
  );

  const requestOptions = await request.executeRequest();

  if (requestOptions.code === 200) {
    const judgementTypes = await optionsToFormat(
      requestOptions.response.judgementTypes.length > 0 ?
        requestOptions.response.judgementTypes : []
    );
    return judgementTypes;
  }
  else {
    return [{
      value: null,
      label: 'Ha ocurrido un error...'
    }];
  }
};

export const getOptionsLegalWays = async () => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/catalogs/legal_ways`,
    "get",
    null,
    {}
  );

  const requestOptions = await request.executeRequest();
  if (requestOptions.code === 200) {
    const legalWays = await optionsToFormat(
      requestOptions.response.legalWays.length > 0 ?
        requestOptions.response.legalWays : []
    );
    return legalWays;
  }
  else {
    return [{
      value: null,
      label: 'Ha ocurrido un error...'
    }];
  }
};

export const optionsToFormat = (arrayOfOptions) => {

  return arrayOfOptions.reduce((acc, element) => {
    acc.push({
      value: element['id'],
      label: element['name'] + ' - ' + element['code']
    });
    return acc;
  }, [])

};

export const selectStylesError = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #f44336'
  })
};

export const getCrimes= async (matter) =>{ 
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedient/crimes/${matter}`,
    "get",
    null,
    {}
  );

  const response = await  request.executeRequest();
  if (_.get(response, 'completeResponse.data.data.crimes', []).length > 0 && _.get(response, 'completeResponse.status', 0) === 200) {
    return _.get(response, 'completeResponse.data.data.crimes', []).map((crime) => {
      return {
        value: crime.value,
        label: crime.label
      }
    });

  } else {
    return [{value: 0 , label: 'No se encontraron delitos'}];
  }
  
}

export const selectStylesDefault = {
  placeholder: (provided, state) => ({
    ...provided,
    color: '#c1c1c1'
  }),
  control: (provided, state) => {
    if (state.isDisabled) {
      return {
        ...provided,
        color: '#495057',
        backgroundColor: '#e9ecef',
        border: '1px solid #c1c1c1'
      };
    }
    else return { ...provided }
  },
  singleValue: (provided, state) => {
    if (state.isDisabled) {
      return {
        ...provided,
        color: '#495057'
      };
    }
    else return { ...provided }
  }
};
