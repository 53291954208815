import React from 'react';
import { useFormikContext } from 'formik';
import Select from 'react-select';
import WrapperFormikField from './WrapperFormikField';
import styles from './ErrorMessagesShared.module.css';

export default function SelectField({ onChange = () => { }, ...restOfProps }) {
  const { setFieldValue, values, errors } = useFormikContext();

  const handleChange = (value) => {
    onChange(value);
    setFieldValue(restOfProps.name, value);
  }

  const classNamePrefix = 'react-select-custom-styles';

  return (
    <WrapperFormikField {...restOfProps}>
      {({ currentValue, errorField }) => (
        <>
          <Select
            {...restOfProps}
            onChange={handleChange}
            value={currentValue}
            isInvalid={errorField}
            classNamePrefix={classNamePrefix}
            className={errorField ? `${classNamePrefix}__data-error-text` : ''}
          />
          {errorField &&
            <div className={styles.feedbackErrorMessages}><li>{errorField}</li></div>
          }
        </>
      )}
    </WrapperFormikField>
  )
}
