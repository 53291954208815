import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import ModalComponent from "../helper_components/ModalComponent";
import { bodyModalAddEvidenceFile } from "../government_book/details_expedient/expedient_details_logic";
import { fieldsValidation, renderErrorsByInputName } from "../government_book/my_expedients/expedients_list_logic";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import Cookies from "js-cookie";

const TableUploadManyFiles = ({ 
	acceptFiles, getFiles, 
	expedientId, dataUploadEvidences, 
	reloadDataComponent, 
	errorsHeredated, 
	isMultiSignature = false
}) => {
	const [listOfFiles, setListOfFiles] = useState([]);
	const [fileState, setFileState] = useState({});
	const [errors, setErrors] = useState({});
	const [show, setShow] = useState(false);
	const { authentication_token_03 } = Cookies.get();
	const [filesTried, setFilesTried] = useState([]);
	let copyFilesTried = [];

	const {getRootProps, getInputProps} = useDropzone({
    accept: acceptFiles,
    onDrop: acceptedFiles => {
			acceptedFiles.forEach((file, index)=> {
				if (!file['type'].includes('image')) {
					Object.assign(file, {
						preview: process.env.PUBLIC_URL + '/img/document.svg'
					});
				}
				else {
					Object.assign(file, {
						preview: URL.createObjectURL(file)
					});
				}
				setFileState({...fileState, evidence_file:file});
				setErrors({
					...errors,
					evidence_file:[]
				});
			});
    }
	});
	
	const deleteFile = (fileToDelete) => {
		let newFiles = [...listOfFiles];
		const indexDelete = newFiles.map((file) => file['alias']).indexOf(fileToDelete['alias']);
		if (indexDelete !== -1) {
			newFiles.splice(indexDelete, 1);
			setListOfFiles(newFiles);
			getFiles(newFiles);
		}
	};

	
	const addFileToTable = (fileToAdd) => {
		const requiredFields = ['evidence_type', 'alias', 'evidence_file'];
		const responseValidation =  fieldsValidation( requiredFields, fileState );

		if ( typeof responseValidation === "object" ) {
				NotificationManager.error("Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo");
				setErrors(responseValidation);
		}
		else {
			let newArr = [...listOfFiles];
			newArr.push(fileToAdd);
			setListOfFiles(newArr);
			setFileState({});
			setShow(false);
			getFiles(newArr);

			newArr.forEach(file => {
				URL.revokeObjectURL(file['evidence_file']['preview']);
			});
		}
	};

	const executeFilesUploading = async () => {
		copyFilesTried = [...listOfFiles];

		listOfFiles.forEach( async (file, index) => {
			listOfFiles[index]['status'] = file['status'] !== 2 ? 1 : 2;
			const sendFile = await uploadDocument(
				file, index
			);
			if (sendFile['error']) {
				file['status'] = 3;
				copyFilesTried.splice(index, 1, file);
				setFilesTried(copyFilesTried);
			}
			else {
				file['status'] = 2;
				copyFilesTried.splice(index, 1, file);
				setFilesTried(copyFilesTried);
			}
		});
	};

	const uploadDocument = async (file, index) => {
			if (file['status'] !== 2) {
				let responseDocumentSave;
				let filedata = new FormData();
				filedata.append('annexed_type', file['evidence_type']);
				filedata.append('alias', file['alias']);
				filedata.append('evidence_file', file['evidence_file']);
				filedata.append('is_multi_signature', isMultiSignature);
				if (dataUploadEvidences['document_type'] === 'promotion') {
					filedata.append('promotion_id', dataUploadEvidences['promotion_id']);
					filedata.append('document_type', file['evidence_type']) 
					responseDocumentSave = await axios({
						method: "post",
						url: `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/evidences/upload/${expedientId}`,
						data: filedata,
						headers: {
						Authorization: String(authentication_token_03)
						}
					});
				} 
				else if (dataUploadEvidences['document_type'] === 'demand') {
					filedata.append('demand_id', dataUploadEvidences['demand_id']);
					filedata.append('document_type', file['evidence_type']) 
					responseDocumentSave = await axios({
						method: "post",
						url: `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/evidences/demand/upload/${expedientId}`,
						data: filedata,
						headers: {
						Authorization: String(authentication_token_03)
						}
					});
				}
				else if (dataUploadEvidences['document_type'] === 'writ') {
					filedata.append('writ_id', dataUploadEvidences['writ_id']);
					filedata.append('document_type', file['evidence_type'])
					responseDocumentSave = await axios({
						method: "post",
						url: `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/evidences/writ/upload/${expedientId}`,
						data: filedata,
						headers: {
						Authorization: String(authentication_token_03)
						}
					});
				}
				if (
					responseDocumentSave && 
					responseDocumentSave.data &&
					responseDocumentSave.data.data && 
					responseDocumentSave.data.data.evidenceDocument &&
					typeof responseDocumentSave.data.data.evidenceDocument === 'object'
				) {
					return {
						error: false,
						status: 2,
						message: 'Archivo subido correctamente',
						file: file
					};
				}
				else {
					return {
						error: true,
						status: 3,
						message: 'La subida del archivo falló',
						file: file
					};
				}
			} else {
				return {
					error: false,
					status: 2,
					message: 'Archivo subido correctamente',
					file: file
				};
			}
	};

	const uploadAgain = async (fileAgain) => {
		copyFilesTried = [...listOfFiles];
		listOfFiles.forEach( async (fileUpload, index) => {
			if (fileUpload['status'] === 3) {
				listOfFiles[index]['status'] = 1;

				const sendFileAgain = await uploadDocument(
					fileUpload
				);
				if (sendFileAgain['error']) {
					fileUpload['status'] = 3;
					copyFilesTried.splice(index, 1, fileUpload);
					setFilesTried(copyFilesTried);
				}
				else {
					fileUpload['status'] = 2;
					copyFilesTried.splice(index, 1, fileUpload);
					setFilesTried(copyFilesTried);
				}
			}
			else {
				copyFilesTried.splice(index, 1, fileUpload);
				setFilesTried(copyFilesTried);
			}
		});
	};

	useEffect( () => {
		(async function (){
			if (listOfFiles.length > 0) await executeFilesUploading();
		})();
	}, [dataUploadEvidences]);

	useEffect( () => {
		setTimeout(() => {
			setListOfFiles(filesTried);
		}, 1000);
	}, [filesTried]);

	useEffect( () => {
		(async function () {
			const existsFilesErrors = listOfFiles.filter( (file) => {
				return file['status'] === 3 || !file['status'];
			});
	
			if (
				existsFilesErrors.length === 0 && 
				typeof dataUploadEvidences === 'object' && 
				dataUploadEvidences
			) {
				await reloadDataComponent(
					"La subida del documento y sus archivos de prueba ha sido exitosa",
					"Excelente",
					5000
				);
			}
		}());
	}, [listOfFiles]);

	return (
		<>
			<section className="container">
				<div style={{float:'right'}}>
					<Button onClick={() => setShow(true)} style={{fontWeight:"bold", fontSize:"14px", backgroundColor:"#02cc98"}} size="md" variant="outline-light">
						<strong><i className="fas fa-plus"/> Agregar anexo</strong>
					</Button>
					{ renderErrorsByInputName(errorsHeredated, "evidence_file") }
				</div>
				<div className="clearFloat"/>
				<div className="mT-20" style={{overflowX:'scroll'}}>
					{
						listOfFiles.length > 0 ?
							<table 
								className="table table-collapsed" 
								style={{ 
									border: '1px solid #a2a2a2',
									marginTop: '10px'
								}}>
								<tbody>
									<tr style={{backgroundColor:'#02cc98'}}>
										<th className="text-center button-see-evidence-documents">Documento:</th>
										<th className="text-center button-see-evidence-documents">Tipo:</th>
										<th className="text-center button-see-evidence-documents">Etiqueta:</th>
										<th className="text-center button-see-evidence-documents">Acciones:</th>
									</tr>
									{	
										listOfFiles.map( ( file, index ) => (
											<tr key={index}>
												<td style={{paddingTop:"15px"}} className="text-center">
													{ 
														file['status'] === 1 ?
														<><img width="40px" src={process.env.PUBLIC_URL + "/img/loader.gif"} alt="Cargando archivo"/><br/><small>Subiendo archivo...</small></> :
														file['status'] === 2 ?
														<><i className="fas fa-check-circle" style={{color:'green'}} /><br/><small>El archivo se subió correctamente</small></> :
														file['status'] === 3 ?
														<>
															<i className="fas fa-exclamation-triangle" style={{color:'red'}}/>
															<br/>
															<small style={{fontSize:'8px'}}>La carga del archivo falló</small>
															<br/>
															<Button
																size="sm"
																variant="info"
																title="Reintentar"
																style={{fontSize:'10px'}}
																onClick={() => uploadAgain(file)}
															>
																<i className="fas fa-cloud-upload-alt" style={{color:'white', fontSize:'10px'}} /> Reintentar
															</Button>
															</> :
														index + 1
													}
												</td>
												<td style={{paddingTop:"15px", maxWidth:"150px"}} className="text-center">
													{file['evidence_type']}
												</td>
												<td style={{paddingTop:"15px", maxWidth:"150px"}} className="text-justify">
													{file['alias']}
												</td>
												<td style={{paddingTop:"10px"}} className="text-center">
													<Button 
														size="sm"
														variant="danger"
														title="Cancelar"
														onClick={() => deleteFile(file)}
													>
														<i className="ti-trash"/>
													</Button>
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
						: ""
					}
				</div>
				<div className="clearFloat mT-10"/>
				<small className="text-justify">
					* IMPORTANTE: Para agregar un anexo o prueba, haz clic en el botón <strong>Agregar anexo </strong> 
					y proporciona la información solicitada por cada documento a subirse.
				</small>
			</section>


			<div className={show ? "modal-superpositionated-fade" : "d-n"}/>			
			<ModalComponent
				header={"Carga de anexo"}
				show={show}
				onHide={setShow}
				canClose={true}
				scroll={false}
				body={bodyModalAddEvidenceFile(
					setFileState, fileState, getRootProps,
					errors, setErrors, getInputProps
				)}
				footer={(
					<>
						<Button variant="secondary" onClick={() => setShow(false)}>Cancelar</Button>
						<Button variant="primary" onClick={ () => addFileToTable(fileState) } >Agregar</Button>
					</>
				)}
			/>
		</>
	);
};

export default TableUploadManyFiles;