
import { RequestNewVersion } from '../../../../../classes/RequestNewVersion';
import { NotificationManager } from "react-notifications";
import { validateObjectEqualFields } from './partyHelpers.js';
import { typeParties } from '../assets/typeParties';
import _ from 'lodash';
import { getFormattedPartyList } from '../adapters/getFormattedPartyList.js';
import { getFormattedPartyTypeList } from '../adapters/getFormattedPartyTypeList.js';
const fieldsObligatories = [
    'names',
    'paternal_surname',
    'sex',
    'canReadAndWrite',
    'canSpeakSpanish',
    'levelStudies',
    'civilStatus',
    'nationality',
    'typeParts',
    'regime',
    'partClasification'
];

const errosByForm = {
    personal_info: [
        'names',
        'paternal_surname',
        'alias',
        'age',
        'birthDate',
        'regime',
        'type_representation',
        'relation_party_id',
        'partClasification'
    ],
    contact: [
        'email',
        'home',
    ],
    transparency: [
        'canReadAndWrite',
        'canSpeakSpanish',
        'levelStudies',
        'civilStatus',
        'nationality',
        'ocupation',
    ]
};

export const findFormErrors = (values) => {
    return Object.keys(errosByForm).filter(key =>
        errosByForm[key].some(value => values.includes(value))
    );
}

export const handleSubmitParties = async (values, setValues, electronicExpedientId, toggleShowAddForm) => {
    let errors = validateErrors(fieldsObligatories, values);
    if (Object.keys(errors).length > 0) {
        let errorsByForm = findFormErrors(Object.keys(errors));
        setValues({ ...values, errors, errorsByForm });
        NotificationManager.error('¡Existen errores, revisa los campos marcados en rojo y vuelve a intentarlo!');
        return;
    }

    const data = {
        party_type: values.formValues.typeParts,
        party_data: JSON.stringify(values.formValues),
        show_cover: values.formValues.is_show_cover ? true : false
    }

    if (values.formValues.relation_party_id) {
        data['related_party_id'] = values.formValues.relation_party_id;
    }

    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/${electronicExpedientId}/parties`,
        'post',
        null,
        data
    );
    setValues({ ...values, loading: true });
    const requestParty = await request.executeRequest();
    setValues({ ...values, loading: false });
    if (
        requestParty &&
        requestParty.code === 200 &&
        requestParty.response
    ) {

        toggleShowAddForm();
        NotificationManager.success(
            '',
            'La parte ha sido agregada correctamente',
            4500
        );
    } else {
        if (
            requestParty &&
            requestParty.code === 422 &&
            requestParty.response.error &&
            typeof requestParty.response.message === 'object'
        ) {
            NotificationManager.error('¡Existen errores, revisa los campos marcados en rojo y vuelve a intentarlo!');
        }
        else {
            NotificationManager.error('¡Ha ocurrido un error inesperado y la parte no fue agregada, inténtalo nuevamente!');
        }
    }


}

export const handleSubmitUpdateParties = async (idExpedient, values, toggleShowAddForm, originalParty, setValues) => {

    if (validateObjectEqualFields(originalParty.party_data, values.formValues)) {
        NotificationManager.warning('Para poder actualizar, es necesario realizar algún cambio en el formulario antes de hacer clic en Guardar.');
        return;
    }

    let errors = validateErrors(fieldsObligatories, values);
    if (Object.keys(errors).length > 0) {
        let errorsByForm = findFormErrors(Object.keys(errors));
        setValues({ ...values, errors, errorsByForm });
        NotificationManager.error('¡Existen errores, revisa los campos marcados en rojo y vuelve a intentarlo!');
        return;
    }

    const data = {
        party_type: values.formValues.typeParts,
        party_data: JSON.stringify(values.formValues),
        show_cover: values.formValues.is_show_cover ? true : false
    }

    if (values.formValues.relation_party_id) {
        data['related_party_id'] = values.formValues.relation_party_id;
    }

    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/${idExpedient}/parties/${originalParty.id}`,
        'put',
        null,
        data
    );
    setValues({ ...values, loading: true });
    const requestParty = await request.executeRequest();
    setValues({ ...values, loading: false });
    if (
        requestParty &&
        requestParty.code === 200 &&
        requestParty.response
    ) {

        toggleShowAddForm();
        NotificationManager.success(
            '',
            'La parte ha sido actualizada correctamente',
            4500
        );
    } else {
        if (
            requestParty &&
            requestParty.code === 422 &&
            requestParty.response.error &&
            typeof requestParty.response.message === 'object'
        ) {
            NotificationManager.error('¡Existen errores, revisa los campos marcados en rojo y vuelve a intentarlo!');
        }
        else {
            NotificationManager.error('¡Ha ocurrido un error inesperado y la parte no fue actualizada, inténtalo nuevamente!');
        }
    }
}

export const handleDeleteParty = async (idExpedient, currentPartyItem = {}, toggleDeleteParty, values, setValues) => {
    if (_.isEmpty(currentPartyItem)) {
        NotificationManager.error('!Ninguna parte ha sido seleccionada para eliminar');
    }
    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/${idExpedient}/parties/${currentPartyItem.id}`,
        'delete',
        null,
        {}
    );
    setValues({ ...values, loading: true });
    const requestDeletedPary = await request.executeRequest();
    setValues({ ...values, loading: false });
    if (
        requestDeletedPary &&
        requestDeletedPary.code === 200 &&
        requestDeletedPary.response
    ) {

        toggleDeleteParty();
        NotificationManager.success(
            '',
            'La parte ha sido eliminada correctamente',
            4500
        );
    } else {
        if (
            requestDeletedPary &&
            requestDeletedPary.code === 422 &&
            requestDeletedPary.response.error &&
            typeof requestDeletedPary.response.message === 'object'
        ) {
            NotificationManager.error('¡Existen errores, revisa los campos marcados en rojo y vuelve a intentarlo!');
        } else if (
            requestDeletedPary &&
            requestDeletedPary.code === 404
        ) {
            NotificationManager.error('¡Parte no encontrada!');
        }

        else {
            NotificationManager.error('¡Ha ocurrido un error inesperado y la parte no fue eliminada, inténtalo nuevamente!');
        }
    }

}

export const getPartiesByExpedientId = async (idExpedient) => {
    try {
        const request = new RequestNewVersion(
            `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/${idExpedient}/parties`,
            'get'
        );

        const requestParties = await request.executeRequest();
        if (
            requestParties &&
            requestParties.code === 200 &&
            requestParties.response
        ) {
            let data = requestParties.response;
            delete data.message;
            const partiesByExpedientId = Object.values(requestParties.response);
            const formattedPartiesByExpedientId = getFormattedPartyList(partiesByExpedientId);
            return formattedPartiesByExpedientId;
        }

        return [];
    }
    catch (err) {
        NotificationManager.success('', 'Error desconocido al obtener las partes asociadas al expediente', 4500);
        return [];
    }
}

export const getPartiesByExpedientIdAndType = async (idExpedient, type) => {
    try {
        const request = new RequestNewVersion(
            `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/${idExpedient}/parties/type/${JSON.stringify(type)}`,
            'get',
        );

        const requestParties = await request.executeRequest();
        if (
            requestParties &&
            requestParties.code === 200 &&
            requestParties.response
        ) {
            let data = requestParties.response;
            delete data.message;
            const partyTypeList = Object.values(requestParties.response);
            const formattedPartyTypeList = getFormattedPartyTypeList(partyTypeList);
            return formattedPartyTypeList;
        }

        return [];
    }
    catch (err) {
        return [];
    }
}

export const getMatterByUser = async () => {
    try {
        const request = new RequestNewVersion(
            `${process.env.REACT_APP_URL_API_LARAVEL}/matters/get_list`,
            'get'
        );
        const requestMatter = await request.executeRequest();
        if (
            requestMatter &&
            requestMatter.code === 200 &&
            requestMatter.response
        ) {
            let data = requestMatter.response;

            return data.matters;
        }
        return [];
    }
    catch (err) {
        return [];
    }
}

// VALIDATE FUNCTIONS
const validateEmail = (email) => {
    if (!email) {
        return false;
    }
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

const validateName = (name) => {
    const re = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s.,]*$/;
    return re.test(name);
}

const validateAge = (age) => {
    const re = /^(?:1[01][0-9]|120|[1-9]?[0-9])$/;
    return re.test(age);
};

const validatePhone = (phone) => {
    const re = /^[0-9]{10}$/;
    return re.test(phone);
}

const validateBirthDate = (birthDate, age) => {
    let date = new Date(birthDate);
    let today = new Date();
    if (date > today) {
        return false;
    }
    let ageDate = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());
    return date <= ageDate;
}
const validateErrors = (listOfKeysToValidate, values) => {
    let errors = {};

    listOfKeysToValidate.forEach(key => {
        if (!values.formValues[key]) {
            errors[key] = errors[key] ? errors[key].push('El campo es obligatorio') : ['El campo es obligatorio'];
        } else {


            // Add special validations
            switch (key) {
                case 'email':
                    if (!validateEmail(values.formValues.email)) {
                        errors.email = errors.email ? errors.email.push('El correo electrónico no es válido') : ['El correo electrónico no es válido'];
                    }
                    break;
                case 'names':
                    if (!validateName(values.formValues.names)) {
                        errors.names = errors.names ? errors.names.push('El nombre no es válido') : ['El nombre no es válido'];
                    }
                    break;
                case 'paternal_surname':
                    if (!validateName(values.formValues.paternal_surname)) {
                        errors.paternal_surname = errors.paternal_surname ? errors.paternal_surname.push('El apellido paterno no es válido') : ['El apellido paterno no es válido'];
                    }
                    break;
                case 'maternal_surname':
                    if (!validateName(values.formValues.maternal_surname)) {
                        errors.maternal_surname = errors.maternal_surname ? errors.maternal_surname.push('El apellido materno no es válido') : ['El apellido materno no es válido'];
                    }
                    break;
                case 'age':
                    if (!validateAge(values.formValues.age)) {
                        errors.age = errors.age ? errors.age.push('La edad no es válida') : ['La edad no es válida'];
                    }
                    break;
                case 'phone':
                    if (!validatePhone(values.formValues.phone)) {
                        errors.phone = errors.phone ? errors.phone.push('El teléfono no es válido') : ['El teléfono no es válido'];
                    }
                    break;
                case 'birthDate':
                    if (!validateBirthDate(values.formValues.birthDate, values.formValues.age)) {
                        errors.birthDate = errors.birthDate ? errors.birthDate.push('La fecha de nacimiento no es válida') : ['La fecha de nacimiento no es válida'];
                    }
                    break;
            }
        }
    });

    if (values.formValues.maternal_surname) {
        if (!validateName(values.formValues.maternal_surname)) {
            errors.maternal_surname = errors.maternal_surname ? errors.maternal_surname.push('El apellido materno no es válido') : ['El apellido materno no es válido'];
        }
    }

    if (values.formValues.regime && values.formValues.regime === 'moral') {
        if (!values.formValues.business_name) {
            errors.business_name = errors.business_name ? errors.business_name.push('El campo es obligatorio') : ['El campo es obligatorio'];
        }
    }

    let typePartie = typeParties.find(item => item.codigo === values.formValues.typeParts);

    if (typePartie && typePartie.type === 'secondary') {
        if (!values.formValues.type_representation) {
            errors.type_representation = errors.type_representation ? errors.type_representation.push('El campo es obligatorio') : ['El campo es obligatorio'];
        }

        if (!values.formValues.relation_party_id) {
            errors.relation_party_id = errors.relation_party_id ? errors.relation_party_id.push('El campo es obligatorio') : ['El campo es obligatorio'];
        }
    }



    return errors;
}

export const isError = (listOfErrors, val) => {
    let error = false;

    if (Object.keys(listOfErrors).length > 0 && listOfErrors[val]) {
        error = true;
    }

    return error;
}
