import React, { useState } from 'react';
import * as formik from 'formik';
import _ from 'lodash';
import { Modal, Button } from 'react-bootstrap';
import {
  addPartToExpedient, getAllPartsByExpedient, editPart
} from '../../juditial_bulletin/api/parts';
import PartFormFields from '../../juditial_bulletin/expedient_form/sections/PartFormFields';
import { validatePartsFormSchema } from '../../juditial_bulletin/expedient_form/ExpedientForm.form';
import styles from '../../juditial_bulletin/expedient_form/ExpedientForm.module.css';

const { v4: uuidv4 } = require("uuid");
const PartsFormModal = ({ show, setShow, parts, setParts, id = null, expedientId = null, isNewPart = false }) => {
  const { Formik } = formik;
  const [partRegime, setPartRegime] = useState();

  let partData = null;
  if (id && !expedientId) {
    partData = parts.find(part => part.id === id);
  } else if (expedientId && id) {
    const foundPart = parts.find(part => part.id === id);

    if (foundPart) {
      partData = foundPart.attributes;
    }
  }

  const handleSubmit = async (values) => {
    // Expediente no existe
    if (!expedientId) {
      if (!id) {
        // Aun no esta en localstorage
        setParts(
          [
            ...parts,
            {
              id: uuidv4(),
              partNames: values.partNames,
              paternalSurname: values.paternalSurname,
              maternalSurname: values.maternalSurname,
              partType: values.partType,
              partClasification: values.partClasification,
              partSex: values.partSex,
              partGender: values.partGender,
              partAlias: values.partAlias,
              partAge: values.partAge,
              partEmail: values.partEmail,
              partPhone: values.partPhone,
              partBirthday: values.partBirthday,
              partRegime: values.partRegime,
              partBusinessName: values.partBusinessName
            }
          ]
        );

        setPartRegime('');
        setShow(false);
      } else {
        // Ya esta en localstorage
        setParts(parts.map((part) => {
          if (part.id === id) {
            return {
              ...part,
              partType: values.partType,
              partClasification: values.partClasification,
              partNames: values.partNames,
              paternalSurname: values.paternalSurname,
              maternalSurname: values.maternalSurname,
              partSex: values.partSex,
              partGender: values.partGender,
              partAlias: values.partAlias,
              partAge: values.partAge,
              partEmail: values.partEmail,
              partPhone: values.partPhone,
              partBirthday: values.partBirthday,
              partRegime: values.partRegime,
              partBusinessName: values.partBusinessName
            }
          }
          return part;
        }));

        setShow(false);
      }
    } else {
      // Expediente existe
      if (id) {
        // La parte ya existe en BD
        try {
          await editPart(id, values);
        } catch (error) {
          console.log('Error al editar la parte', error);
        }

        setShow(false);
      } else {
        // La parte no existe en el expediente (en BD)
        try {
          await addPartToExpedient(values, expedientId);
        } catch (error) {
          console.log('Error al agregar la parte', error);
        }

        setShow(false);
      }

      setParts(
        await getAllPartsByExpedient(expedientId)
      );
    }
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <Formik
      validationSchema={validatePartsFormSchema}
      validateOnChange={false}
      initialValues={{
        partRegime: _.get(partData, 'partRegime', ''),
        partBusinessName: _.get(partData, 'partBusinessName', ''),
        partNames: _.get(partData, 'partNames', ''),
        paternalSurname: _.get(partData, 'paternalSurname', ''),
        maternalSurname: _.get(partData, 'maternalSurname', ''),
        partAlias: _.get(partData, 'partAlias', ''),
        partEmail: _.get(partData, 'partEmail', ''),
        partPhone: _.get(partData, 'partPhone', ''),
        partAge: _.get(partData, 'partAge', ''),
        partBirthday: partData ? new Date(_.get(partData, 'partBirthday', null)) : '',
        partGender: _.get(partData, 'partGender', ''),
        partSex: _.get(partData, 'partSex', ''),
        partType: _.get(partData, 'partType', ''),
        partClasification: _.get(partData, 'partClasification', '')
      }}
      onSubmit={async (values, { resetForm }) => {
        await handleSubmit(values);
        if (isNewPart) {
          resetForm();
        }
      }}
    >
      {
        (props) => {
          return (
            <Modal show={show} onHide={handleClose} size="lx">
              <Modal.Header>
                <Modal.Title>{id ? 'Edición de parte' : 'Alta de nueva parte'}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <form onSubmit={props.handleSubmit}>

                  <PartFormFields partData={partData} partRegime={partRegime} setPartRegime={setPartRegime} errors={props.errors} />
                  <hr />

                  <div className={styles.footerButtons}>
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                    >
                      Cerrar
                    </Button>
                    <Button type="submit">{id ? 'Actualizar' : 'Guardar'}</Button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          );
        }
      }
    </Formik >
  );
}

export default PartsFormModal;