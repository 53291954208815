import React, { forwardRef } from 'react';
import { MenuItem as MenuItemReactMenu } from '@szhsin/react-menu';
import styles from './MenuItem.module.css';
import { getMenuItemClassName } from './lib/utils/getMenuItemClassName';

const MenuItem = forwardRef((props, ref) => {
  return (
    <MenuItemReactMenu
      {...props}
      className={getMenuItemClassName(styles, props.className)}
      ref={ref}
    />
  )
});

export default MenuItem