import React from 'react';
import MenuButton from '../../../../dropdown/default/menuButton/MenuButton';
import MenuItem from '../../../../dropdown/default/menuItem/MenuItem';
import Menu from '../../../../dropdown/default/menu/Menu';

const OptionsDropdown = ({
  onDeleteClick = () => { }
}) => {
  return (
    <Menu menuButton={<MenuButton />}>
      <MenuItem onClick={onDeleteClick}>
        Eliminar
      </MenuItem>
    </Menu>
  )
}

export default OptionsDropdown