import React from "react";
import { NotificationManager } from "react-notifications";
import { RequestNewVersion } from "../classes/RequestNewVersion";
import xlsx from "xlsx";

export const generateReport = async (data) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/reports/courts_reports`,
    "get",
    {},
    {
      type_report: data.type_report,
      initial_date: data.initial_date,
      final_date: data.final_date,
    },
    { responseType: "blob" }
  );

  try {
    const response = await request.executeRequest();
    if (response.completeResponse.status === 200) {
      if (
        response.completeResponse.data.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
            if (response.completeResponse.data.size <6425) {
              NotificationManager.error("No se encontraron registros");
              return false;
            }
            const url = window.URL.createObjectURL(
              new Blob([response.completeResponse.data])
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `reporte ${data.type_report}_${data.initial_date}_${data.final_date}.xlsx`);
            document.body.appendChild(link);
            link.click();
            return true;

      } else if(response.completeResponse.data.type === "application/pdf") {
        const url = window.URL.createObjectURL(
          new Blob([response.completeResponse.data])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `reporte ${data.type_report}_${data.initial_date}_${data.final_date}.pdf`);
        document.body.appendChild(link);
        link.click();
        return true;
      }
      else {
        NotificationManager.error("Error al generar el reporte");
        return false;
      }
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const fieldsValidation = (requiredFields, objectToSend) => {
  const fieldsObjectToSend = Object.keys(objectToSend);
  let missingFields = {};

  requiredFields.forEach((field) => {
    if (!fieldsObjectToSend.includes(field) || !objectToSend[field]) {
      missingFields[field] = ["Este campo es requerido"];
    }
  });

  if (objectToSend.startDate && objectToSend.endDate) {
    if (objectToSend.startDate > objectToSend.endDate) {
      missingFields["startDate"] = [
        "La fecha de inicio no puede ser mayor a la fecha de fin",
      ];
    }
  }

  if (Object.keys(missingFields).length > 0) {
    return missingFields;
  } else return true;
};

export const renderErrorsByInputName = (errors, inputName) => {
  if (errors && errors[inputName]) {
    const errorsList = errors[inputName].map((error, index) => {
      return (
        <li key={index} className="text-danger">
          <small>{error}</small>
        </li>
      );
    });
    return errorsList;
  }
};
