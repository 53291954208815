import React, { useState, useEffect, useContext, useMemo } from "react";
import { RemotePagination } from "../../helper_components/remote_pagination/RemotePagination";
import { Grid } from "react-flexbox-grid";
import NavBar from "../../layout/shared/NavBar";
import ExpedientsSearcher from "../../helper_components/expedients_searcher/ExpedientSearcher";
import {
  initColumnsMyExpedients,
  getMyExpedientsList,
  transferExpedient,
  renderErrorsByInputName,
  getUsersToTransfer,
  fieldsValidation,
  getSearchOfExpedients,
  cleanOfInputsDeleted,
  multipleReleaseExpedients,
  disabledButtonsToSingleTransfer,
  getCatalogOptionsKindJudgment,
  getCatalogOptionsLegalWays,
  selectStylesError,
  selectStylesDefault
} from "./expedients_list_logic";
import { Button, Form } from "react-bootstrap";
import ModalComponent from "../../helper_components/ModalComponent";
import Select from "react-select";
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";
import Footer from "../../layout/shared/Footer";
import _, { get, set } from "lodash";
import Cookies from "js-cookie";
import useLegalRepresentativeText from "./hooks/useLegalRepresentativeText";
import { useGlobalContext } from "../../../context/globalContext/GlobalContext";
import { getLabelFromDictionary } from "../../../config/dictionary";
import { getDecodedJwt } from "../../../services/dataFormatting/getDecodedJwt";
import { NotificationManager } from "react-notifications";
import { RequestNewVersion } from "../../classes/RequestNewVersion";
import PartiesLocalModal from "../details_expedient/modals/partiesLocalModal/PartiesLocalModal";
import { PartiesService } from "../details_expedient/modals/partiesLocalModal/services/partiesServices";
import PartsRelatedToTheExpedientTable from "../details_expedient/components/partsRelatedToTheExpedientTable/PartsRelatedToTheExpedientTable";
import { PartiesEntity } from "../../classes/Parties/Parties.entity";
import ReceiverSelectOption from "./components/react_select/receiver_select_option/receiverSelectOption";
import { CrimesServices } from "../../../services/crimes/crimesServices";
import { CrimesMultiselect } from "../../shared/crimes/CrimesMultiselect";

const ExpedientsList = props => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalProcedures, setTotalProcedures] = useState(5);
  const [searching, setSearching] = useState(false);
  const [cleaning, setCleaning] = useState(false);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [showExpiration, setShowExpiration] = useState(false);
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [transfer, setTransfer] = useState({});
  const [toCreate, setToCreate] = useState(false);
  const [textNotData, setTextNotData] = useState("");
  const [matter, setMatter] = useState(0);
  const [disableFields, setDisableFields] = useState(false);
  const [nonSelectableArray, setNonSelectableArray] = useState([]);
  const [documentsState, setDocumentsData] = useState([]);
  const [expedientListToBeTurn, setExpedientListToBeTurn] = useState([]);
  const [openModalMultipleTransfer, setOpenModalMultipleTransfer] = useState(false);
  const [multipleTransfer, setMultipleTransfer] = useState({});
  const [crimeSelectOptions, setCrimeSelectOptions] = useState([]);
  const [showCrimeSelect, setShowCrimeSelect] = useState(false);
  const [partiesList, setPartiesList] = useState([]);
  const loader = useContext(LoaderContext);
  const [kindJudgmentOptions, setKindJudgmentOptions] = useState([]);
  const [legalWaysOptions, setLegalWaysOptions] = useState([]);
  const textForLegalActorRepresentative = useLegalRepresentativeText(transfer.actor_regime);
  const textForLegalDefendantRepresentative = useLegalRepresentativeText(transfer.defendant_regime);
  const { special_permissions_03 } = Cookies.get();
  const arrayPermissionsParsed = special_permissions_03 ? JSON.parse(special_permissions_03) : [];
  const keysPermissions = arrayPermissionsParsed.length > 0 ? Object.keys(arrayPermissionsParsed[0]) : [];
  const canCreateAnExpedient = _.get(arrayPermissionsParsed, '[0].create_expedient') === '1';
  const canTurnForeign = keysPermissions.includes('foreign') ? '1' : '0';
  const { dataRequest: { mattersList: { getMattersList, mattersList, } } } = useGlobalContext();
  
  const columns = initColumnsMyExpedients(
    Boolean(expedientListToBeTurn.length),
    canTurnForeign);

  const regimeSelectorOptions = [
    { label: 'Física', value: 'fisica' },
    { label: 'Moral', value: 'moral' },
  ];

  useEffect(() => {
    (async function () {
      await getMyExpedientsList(
        setData,
        setTotalProcedures,
        page,
        sizePerPage,
        setFunctionsToOpenModal,
        setOptions,
        setTransfer,
        transfer,
        setTextNotData,
        setNonSelectableArray,
        canTurnForeign,
        setShowExpiration,
        setDocumentsData,
        loader,
      );
      renderInputOfComments(expedientListToBeTurn);
      disabledButtonsToSingleTransfer(expedientListToBeTurn, true);
    })();
  }, []);

  useEffect(() => {
    const getPartiesFunction = async () => {
      if(!_.isEmpty(transfer)) {
        const partiesList = await PartiesEntity.getPartiesList(transfer['id']);
        const mapped = partiesList.map(item => {
          const {names = '', maternal_surname = '', paternal_surname= '', bussiness_name = ''} = item.party_data;
          return {
            party_type: item.party_type,
            bussiness_name: bussiness_name || '',
            names: `${names} ${paternal_surname} ${maternal_surname}`
          }
        });
        setPartiesList(mapped || []);
      } else {
        setPartiesList([]);
      }
    }

    if(!toCreate) {
      getPartiesFunction();
    }
  }, [show]);

  useEffect(() => {
    if (matter !== null && matter !== 0) {
      getCrimesList(setCrimeSelectOptions, matter);
    }
  }, [matter]);

  useEffect(() => {
    if (crimeSelectOptions.length > 0) {
      setShowCrimeSelect(true);
    }
  }, [crimeSelectOptions]);

  const renderInputOfComments = (elements) => {

    for (let i = 0; i < elements.length; i++) {
      let divContainer = document.getElementById(elements[i]['id'] + 'div-comments') ? document.getElementById(elements[i]['id'] + 'div-comments') : null;
      if (divContainer && divContainer.childNodes.length < 1) {
        var textarea = document.createElement('textarea');
        textarea.id = elements[i]['id'] + 'input-comments';
        textarea.rows = 4;
        textarea.cols = 20;
        textarea.value = elements[i]['comments']
        textarea.style.width = '100%';
        textarea.addEventListener(
          'input',
          (e) => commentsOnChange(e, elements[i]['id']),
          false
        );

        divContainer.appendChild(textarea);
      }
    }
  };

  const addExpedientToExpedientListToBeTurn = (expedientToAdd) => {
    if (Array.isArray(expedientToAdd)) {
      setExpedientListToBeTurn((previousState) => ([...previousState, ...expedientToAdd]));
      return
    }
    setExpedientListToBeTurn((previousState) => ([...previousState, expedientToAdd]));
  }

  const clearExpedientListToBeTurn = () => {
    setExpedientListToBeTurn([]);
  }

  const getCrimesList = async (setCrimeSelectOptions, matter) => {
    // Get Crimes
    await CrimesServices.getCrimesByMatter(matter, setCrimeSelectOptions);
  }

  const setTransferExpedient = async () => {
    const { authentication_token_03 } = Cookies.get();
    const decodedJwt = getDecodedJwt(authentication_token_03);
    const accountUser = _.get(decodedJwt, 'user.account_id');
    const requiredFields = toCreate && transfer["with_defendant"] === "si" ?
      ["expedient_number", "receiver_id", "kind_judgment", "via", "parties_array"] :
      toCreate && transfer["with_defendant"] !== "si" && accountUser !== 3 ?
        ["expedient_number", "receiver_id", "kind_judgment", "via", "parties_array"] :
        ["expedient_number", "receiver_id"];
    const responseValidation = fieldsValidation(requiredFields, transfer);
    
    if (typeof responseValidation === "object") setErrors(responseValidation)
    else {
      const transferToSend = !toCreate ? _.omit(transfer, ['via', 'kind_judgment']) : transfer;
      try {
        loader.show(true);
        transferToSend["parties_array"] = PartiesService.getParties();
        await transferExpedient(transferToSend, props, setErrors, toCreate, clearExpedientListToBeTurn);
      } catch (error) {
        throw error
      } finally {
        PartiesService.handleResetCookie();
        loader.show(false);
      }
    }
  };

  const setFunctionsToOpenModal = async (create) => {
    if (!create) {
      setToCreate(false);
      getMattersList();
      setErrors({});
      setShow(true);
    }
    else {
      setToCreate(true);
      await getUsersToTransfer(
        setShow,
        setOptions,
        null,
        setTransfer,
        transfer,
        false
      );
      setErrors({});
      setShow(true);
    }
  };

  const setCloseModal = () => {
    setTransfer({});
    setShow(false);
  };

  const setCloseModalExpiration = () => {
    setDocumentsData([]);
    setShowExpiration(false);
  }

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    await getMyExpedientsList(
      setData,
      setTotalProcedures,
      page,
      sizePerPage,
      setFunctionsToOpenModal,
      setOptions,
      setTransfer,
      transfer,
      setTextNotData,
      setNonSelectableArray,
      canTurnForeign,
      setShowExpiration,
      setDocumentsData,
      loader,
    );

    disabledButtonsToSingleTransfer(expedientListToBeTurn, true);
    renderInputOfComments(expedientListToBeTurn);
  };

  const searchExpedients = async () => {

    if (search.length > 0 && /^$|.*\S+.*/.test(search)) {

      setSearching(true);
      loader.show(true);
      await getSearchOfExpedients(
        search.replace(/^\s+|\s+$/gm, ''),
        10,
        1,
        setData,
        setTotalProcedures,
        setFunctionsToOpenModal,
        setOptions,
        setTransfer,
        transfer,
        setTextNotData,
        canTurnForeign,
        setShowExpiration,
        setDocumentsData
      );

      disabledButtonsToSingleTransfer(expedientListToBeTurn, true);
      renderInputOfComments(expedientListToBeTurn);
      setSearching(false);
      loader.show(false);
    }

  };

  const cleanSearch = async () => {

    setSearch("");
    setCleaning(true);
    loader.show(true);
    await getMyExpedientsList(
      setData,
      setTotalProcedures,
      1,
      10,
      setFunctionsToOpenModal,
      setOptions,
      setTransfer,
      transfer,
      setTextNotData,
      setNonSelectableArray,
      canTurnForeign,
      setShowExpiration,
      setDocumentsData,
      loader,
    );

    disabledButtonsToSingleTransfer(expedientListToBeTurn, true);
    renderInputOfComments(expedientListToBeTurn);
    setCleaning(false);
    loader.show(false);
  };

  const bodyModalMultipleTransfer = (
    <>

      <Form>
        {renderErrorsByInputName(
          errors["expedients"] ? { "expedients": [`Aún no has seleccionado algún ${getLabelFromDictionary('global', 'singleLowerMainName')} para transferir`] } : errors,
          "expedients"
        )
        }
        {renderErrorsByInputName(errors, "general_errors")}

        <Form.Group>
          <Form.Label>* Destinatario:</Form.Label>
          <Select
            options={options}
            components={{ Option: ReceiverSelectOption }}
            onChange={selected => setMultipleTransfer({ ...multipleTransfer, receiver_id: selected["value"] })}
            placeholder="Selecciona algún destinatario"
            styles={selectStylesDefault}
          />
          {renderErrorsByInputName(errors, "receiver_id")}
        </Form.Group>

      </Form>
    </>
  );

  const bodyModal = (
    <>
      <Form>

        <Form.Group>
          {renderErrorsByInputName(errors, "generalErrors")}
          <Form.Label>{getLabelFromDictionary('addExpediente', 'numberExpedient')}</Form.Label>
          <Form.Control
            disabled={toCreate ? false : true}
            value={transfer["expedient_number"] ? transfer["expedient_number"] : ""}
            aria-label="numero de expediente"
            placeholder={`Agrega el número del ${getLabelFromDictionary('global', 'singleLowerMainName')}`}
            className={Object.keys(errors).includes("expedient_number") ? "text-danger is-invalid" : ""}
            onChange={(e) => {
              if (/^[a-zA-Z0-9/]*$/.test(e.target.value)) {
                setErrors({});
                setTransfer({ ...transfer, expedient_number: e.target.value });
              }
              else setErrors({ ...errors, expedient_number: ['Sólo son permitidos caracteres alfanuméricos y/o diagonal'] });
            }}
            onInput={(e) => e.target.value = e.target.value.replace(/\b0+/g, '')}
          />
          <Form.Text className={errors["expedient_number"] ? "d-n" : ""}>
            Ejemplo: 25/2019 (recuerda utilizar diagonal y capturar los cuatro dígitos del año)
          </Form.Text>
          {renderErrorsByInputName(errors, "expedient_number")}
        </Form.Group>

        <Form.Group >
          <Form.Label>* Destinatario:</Form.Label>
          <Select
            options={options}
            onChange={selected => setTransfer({ ...transfer, receiver_id: selected["value"] })}
            placeholder="Selecciona algún destinatario"
            styles={Object.keys(errors).includes("receiver_id") ? selectStylesError : selectStylesDefault}
          />
          <Form.Text className={errors["receiver_id"] ? "d-n" : ""}>
            Selecciona el nombre del funcionario a quién deseas turnar el {getLabelFromDictionary('global', 'singleLowerMainName')}
          </Form.Text>
          {renderErrorsByInputName(errors, "receiver_id")}
        </Form.Group>

        <PartiesLocalModal />

        {renderErrorsByInputName(errors, "parties_array")}
        
        { !toCreate &&
          <PartsRelatedToTheExpedientTable
            expedientParts={partiesList}
          />
        }

        <Form.Group>
          <Form.Label>{getLabelFromDictionary('addExpediente', 'trialType')}</Form.Label>
          <Select
            isDisabled={toCreate ? false : true}
            onFocus={() => getCatalogOptionsKindJudgment(
              setKindJudgmentOptions
            )}
            options={kindJudgmentOptions}
            onChange={selected => setTransfer({ ...transfer, kind_judgment: selected["value"] })}
            placeholder="Selecciona el tipo de Juicio"
            defaultValue={!toCreate ? transfer["kind_judgment"] : ""}
            styles={Object.keys(errors).includes("kind_judgment") ? selectStylesError : selectStylesDefault}
          />
          {renderErrorsByInputName(errors, "kind_judgment")}
        </Form.Group>

        <Form.Group>
          <Form.Label>{getLabelFromDictionary('addExpediente', 'via')}</Form.Label>
          <Select
            isDisabled={toCreate ? false : true}
            onFocus={(e) => getCatalogOptionsLegalWays(
              setLegalWaysOptions
            )}
            options={legalWaysOptions}
            onChange={selected => setTransfer({ ...transfer, via: selected["value"] })}
            placeholder="Selecciona la vía"
            defaultValue={!toCreate ? transfer["via"] : ""}
            styles={Object.keys(errors).includes("via") ? selectStylesError : selectStylesDefault}
          />
          {renderErrorsByInputName(errors, "via")}
        </Form.Group>

        <Form.Group>
          <Form.Label>{getLabelFromDictionary('addExpediente', 'subject')}</Form.Label>
          <Select
            onFocus={getMattersList}
            isDisabled={!toCreate}
            options={mattersList}
            onChange={selected => {
              setTransfer({ ...transfer, matter: selected.value })
              setMatter(selected.value);
            }}
            placeholder={toCreate ? "Selecciona la materia" : ""}
            value={mattersList.find((option) => toCreate ? option.value === transfer.matter : option.label === transfer.matter)}
            styles={Object.keys(errors).includes("matter") ? selectStylesError : selectStylesDefault}
          />
          {renderErrorsByInputName(errors, "matter")}
        </Form.Group>

        {showCrimeSelect && matter==='penal' &&
           <>
              <CrimesMultiselect
                isDisabled={!toCreate}
                matter={transfer['matter'] || ''}
                changeMainForm={setTransfer}
                defaultCrimes={''}
                isError={Object.keys(errors).includes("crimes")}
              />
              {renderErrorsByInputName(errors, "crimes")}
            </>
        }
        {matter==='penal' &&
          <Form.Group>
            <Form.Label>Carpeta de investigación:</Form.Label>
            <Form.Control
                  value={transfer["investigation_folder"] ? transfer["investigation_folder"] : ""}
                  aria-label="investigation_folder"
                  onChange={(e) => setTransfer({ ...transfer, investigation_folder: e.target.value })}
                  className={Object.keys(errors).includes("investigation_folder") ? "text-danger is-invalid" : ""}
            />
            {renderErrorsByInputName(errors, "investigation_folder")}
          </Form.Group>
        }

        <Form.Group>
          <Form.Label>Observaciones:</Form.Label>
          <Form.Control
            onChange={(e) => setTransfer({ ...transfer, observation: e.target.value })}
            as="textarea"
            rows="5"
            aria-label="Observaciones"
            placeholder="Aquí puedes agregar algunas observaciones si lo deseas"
          />
          <Form.Text className={errors["observation"] ? "d-n" : ""}>
            En caso de tener observaciones, captúralas aquí de manera clara y breve
          </Form.Text>
        </Form.Group>

      </Form>
    </>
  );

  const bodyModalExpiration = (
    <div>
      <table className="table table-collapsed table-bordered mT-10">
        {
          documentsState.map((doc, index) => (
            <tbody key={index}>
              <tr className="table-documents-modal">
                <th colSpan="4" className="text-center" title={doc['filename']} style={{overflowWrap:'break-word', wordBreak:'break-word'}}>{doc['filename']}</th>
              </tr>
              <tr>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", backgroundColor: '#dfe6e9' }} className="text-center">Nombre</td>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", backgroundColor: '#dfe6e9' }} className="text-center">Etiqueta</td>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", backgroundColor: '#dfe6e9' }} className="text-center">Vence</td>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", backgroundColor: '#dfe6e9' }} className="text-center">Indicador</td>
              </tr>
              {
                doc['expirations'].map((expiration, i) => (
                  <tr key={i}>
                    <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={expiration['name']}>{expiration['name']}</td>
                    <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={expiration['alias']}>{expiration['alias']}</td>
                    <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={expiration['expired_at']}>{expiration['expired_at']}</td>
                    <td style={{ paddingTop: "5px" }} className="text-center"><span className={"dot-" + expiration['warning_flag']} /></td>
                  </tr>
                ))
              }
            </tbody>
          ))
        }
      </table>
    </div>
  );

  const footerModalExpiration = (
    <>
      <Button
        variant="secondary"
        onClick={() => setCloseModalExpiration()}
      >
        Cerrar
      </Button>
    </>
  );

  const footerModalMultipleTransfer = (
    <>
      <Button
        variant="secondary"
        onClick={() => setCloseModalMultipleTransfer()}
      >
        Cerrar
      </Button>
      <Button
        variant="primary"
        type="submit"
        onClick={() => sendMultipleTransfer()}
      >
        Enviar
      </Button>
    </>
  );

  const footerModal = (
    <>
      <Button
        variant="secondary"
        onClick={() => setCloseModal()}
      >
        Cerrar
      </Button>
      <Button
        variant="primary"
        type="submit"
        onClick={() => setTransferExpedient()}
      >
        {toCreate ? "Enviar" : "Continuar"}
      </Button>
    </>
  );

  const onSelectRow = (row, isSelect, rowIndex, e) => {

    if (isSelect) {
      cleanOfInputsDeleted(expedientListToBeTurn, row['id'] + 'input-comments');
      const elementToAdd = {
        id: row['id'],
        expedient_number: row['expedient_number'],
        actor_name: row['actor_name'],
        actor_firstname: row['actor_firstname'],
        actor_secondname: row['actor_secondname'],
        defendant_name: row['defendant_name'],
        defendant_firstname: row['defendant_firstname'],
        defendant_secondname: row['defendant_secondname'],
        comments: "",
        kind_judgment: row['kind_judgment'],
        via: row['via']
      };

      addExpedientToExpedientListToBeTurn(elementToAdd);

      let divContainer = document.getElementById(row['id'] + 'div-comments');
      if (divContainer.childNodes.length < 1) {
        var textarea = document.createElement('textarea');
        textarea.id = row['id'] + 'input-comments';
        textarea.rows = 4;
        textarea.cols = 20;
        textarea.style.width = '100%';
        textarea.addEventListener(
          'input',
          (e) => commentsOnChange(e, row['id']),
          false
        );

        divContainer.appendChild(textarea);
      }

      disabledButtonsToSingleTransfer([...expedientListToBeTurn, elementToAdd], true);
    }
    else {
      const arrayLoop = expedientListToBeTurn.filter(element => element.id !== row['id']);
      let divContainer = document.getElementById(row['id'] + 'div-comments');
      divContainer.removeChild(divContainer.childNodes[0]);
      disabledButtonsToSingleTransfer([row], false);

      setExpedientListToBeTurn(arrayLoop);
    }
  };

  const commentsOnChange = (e, expedientId) => {

    if (!expedientListToBeTurn.length) {
      return
    }

    const newExpedientListToBeTurn = _.cloneDeep(expedientListToBeTurn);
    const index = newExpedientListToBeTurn.map((element, i) => element.id).indexOf(expedientId);
    newExpedientListToBeTurn[index]['comments'] = e.target.value;
    setExpedientListToBeTurn(newExpedientListToBeTurn);
  };


  const onSelectAllRows = (isSelect, rows, e) => {
    if (isSelect) {
      const rowsFiltered = rows.map(({ id, expedient_number, actor, defendant, kind_judgment, via }) => {

        let divContainer = document.getElementById(id + 'div-comments');
        if (divContainer.childNodes.length < 1) {
          var textarea = document.createElement('textarea');
          textarea.id = id + 'input-comments';
          textarea.rows = 4;
          textarea.cols = 20;
          textarea.style.width = '100%';
          textarea.addEventListener(
            'input',
            (e) => commentsOnChange(e, id),
            false
          );

          divContainer.appendChild(textarea);
        }

        const object = {
          id: id,
          expedient_number: expedient_number,
          comments: "",
          actor: actor,
          defendant: defendant,
          kind_judgment: kind_judgment,
          via: via
        }

        return object;
      });

      disabledButtonsToSingleTransfer([...expedientListToBeTurn, ...rowsFiltered], true);
      addExpedientToExpedientListToBeTurn(rowsFiltered);
    }
    else {
      const elementsFiltereds = expedientListToBeTurn.filter(ar => !rows.find(rm => (rm.id === ar.id && ar.expedient_number === rm.expedient_number)));
      for (let i = 0; i < rows.length; i++) {
        let divContainer = document.getElementById(rows[i]['id'] + 'div-comments');
        if (divContainer) {
          divContainer.removeChild(divContainer.childNodes[0]);
        }
      };

      disabledButtonsToSingleTransfer(rows, false);
      setExpedientListToBeTurn(elementsFiltereds);
    }
  };

  const sendExpedientsAdded = async () => {
    setErrors({});
    await getUsersToTransfer(
      () => setOpenModalMultipleTransfer(true),
      setOptions,
      null,
      null,
      null
    );
  };

  const sendMultipleTransfer = async () => {
    const multipleTransferToCheck = {
      receiver_id: multipleTransfer['receiver_id'],
      expedients: expedientListToBeTurn.length > 0 ? expedientListToBeTurn : null
    };

    const requiredFields = ["receiver_id", "expedients"];
    const responseValidation = fieldsValidation(requiredFields, multipleTransferToCheck);

    if (typeof responseValidation === "object") setErrors(responseValidation)
    else {
      const expedientsToSend = expedientListToBeTurn.map((expedient, index) => {
        let expedientSend = {};

        expedientSend['receiver_id'] = multipleTransferToCheck['receiver_id'];
        expedientSend['force'] = false;
        expedientSend['observation'] = expedient['comments'];
        expedientSend['expedient_number'] = expedient['expedient_number'];
        expedientSend['actor_name'] = expedient['actor_name'];
        expedientSend['actor_firstname'] = expedient['actor_firstname'];
        expedientSend['actor_secondname'] = expedient['actor_secondname'];
        expedientSend['defendant_name'] = expedient['defendant_name'];
        expedientSend['defendant_firstname'] = expedient['defendant_firstname'];
        expedientSend['defendant_secondname'] = expedient['defendant_secondname'];
        expedientSend['kind_judgment'] = expedient['kind_judgment_id'];
        expedientSend['via'] = expedient['via_id'];
        expedientSend['id'] = expedient['id'];

        return expedientSend;
      });

      loader.show(true);
      await multipleReleaseExpedients(
        expedientsToSend,
        setCloseModalMultipleTransfer,
        setData,
        setTotalProcedures,
        page,
        sizePerPage,
        setFunctionsToOpenModal,
        setOptions,
        setTransfer,
        transfer,
        setTextNotData,
        setNonSelectableArray,
        canTurnForeign,
        setShowExpiration,
        setDocumentsData,
        clearExpedientListToBeTurn,
      );
      loader.show(false);
    }
  };

  const setCloseModalMultipleTransfer = () => {
    setMultipleTransfer({});
    setOpenModalMultipleTransfer(false);
  };

  const getElementsSelected = () => {
    const newExpedientListToBeTurn = _.cloneDeep(expedientListToBeTurn);
    const expedientsReduce = newExpedientListToBeTurn.reduce((array, element) => {
      array.push(element['id']);
      return array;
    }, []);

    return expedientsReduce;
  };

  return (
    <>
      <NavBar {...props} />

      <h3 className="mT-20 w-100 text-center">{getLabelFromDictionary('myExpedientsPage', 'myExpedients')}</h3>

      <div style={{ width: "85%", margin: "0px auto", display: "block" }}>
        {
          canCreateAnExpedient
          &&
          <Button
            id="newTransfer"
            onClick={() => setFunctionsToOpenModal(true)}
          >
            <i className="fas fa-plus" />
            {'  '} {getLabelFromDictionary('addExpediente', 'add')}
          </Button>
        }

        <ExpedientsSearcher

          placeholderSearch={getLabelFromDictionary('searchExpedient', 'writeNoExpedient')}
          placeholderSearchButton="Buscar"
          placeholderCleanButton="Limpiar"
          helperContextual={getLabelFromDictionary('searchExpedient', 'helperText')}
          executeSearch={searchExpedients}
          cleanSearch={cleanSearch}
          isSearching={searching}
          isCleaning={cleaning}
          setSearch={setSearch}
          search={search}
          classNameButtonsSearch={"buttonsSearchExpedientsList"}
          hasRulesToSearch={true}
          rulesToSearch={/.*/}
        />

        <Button
          onClick={sendExpedientsAdded}
          disabled={!Boolean(expedientListToBeTurn.length)}
        >
          <i className="fas fa-send" />
          {'  '} {getLabelFromDictionary('addExpediente', 'selected')}
        </Button>
      </div>


      <Grid fluid={true} className={data.length > 0 ? "no-padding" : "d-n"} style={{ minHeight: '76vh', width: "85%", margin: "0px auto" }}>
        <section className="procedures-table-container mT-30">

          <RemotePagination
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalProcedures}
            onTableChange={handleTableChange}
            columns={columns}
            selectRow={
              {
                mode: 'checkbox',
                selectionHeaderRenderer: ({ mode, checked }) => (
                  <>
                    <input id='all' type={mode} checked={checked} onChange={() => { }} />
                    {'  '}<label>Todo</label>
                  </>
                ),
                headerColumnStyle: { textAlign: 'center', lineHeight: '.5', minWidth: '70px', maxWidth: '70px' },
                onSelect: (row, isSelect, rowIndex, e) => onSelectRow(row, isSelect, rowIndex, e),
                onSelectAll: (isSelect, rows, e) => onSelectAllRows(isSelect, rows, e),
                selectColumnStyle: { textAlign: 'center' },
                nonSelectable: nonSelectableArray,
                nonSelectableClasses: 'prueba-non-selectable',
                selected: getElementsSelected()
              }
            }
          />

        </section>
      </Grid>

      <h4 className={data.length === 0 ? "text-center clearFloat padding-footer-not-data" : "d-n"}>{textNotData}</h4>

      <Footer />

      <ModalComponent
        show={show}
        header={transfer["expedient_number"] ? transfer["expedient_number"] : `Alta de nuevo ${getLabelFromDictionary('global', 'singleLowerMainName')}`}
        body={bodyModal}
        footer={footerModal}
        canClose={true}
        onHide={setCloseModal}
      />

      <ModalComponent
        show={showExpiration}
        header={"Vencimiento"}
        body={bodyModalExpiration}
        footer={footerModalExpiration}
        canClose={true}
        onHide={setCloseModalExpiration}
      />

      <ModalComponent
        show={openModalMultipleTransfer}
        header={"Turnado múltiple"}
        body={bodyModalMultipleTransfer}
        footer={footerModalMultipleTransfer}
        canClose={true}
        onHide={setCloseModalMultipleTransfer}
      />

      <Loader />
    </>
  );
};
export default ExpedientsList;
