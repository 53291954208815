import '../../styles/styles.css';
import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { usePartiesContext } from '../../context/PartiesContext';
import { findData, mappingData, mappingDataOne } from '../../helpers/parseAndFilterDataToForm';
import { yesOrNot } from '../../assets/transparency';
import { civilStatus } from '../../assets/civilStatus';
import { nationalities } from '../../assets/nationalities';
import { levelOfStudies } from '../../assets/levelOfStudies';
import { ErrorsByKeyName } from '../shared/ErrorsByKeyName';
import { isError } from '../../helpers/logicDataParties';

export const PartiesFormTransparency = () => {
    const { values, handleChageForm, randomVal } = usePartiesContext();
    const [ canReadAndWrite, setCanReadAndWrite ] = useState(null);
    const [ canSpeakSpanish, setCanSpeakSpanish ] = useState(null);
    const [ levelOfStudiesSelected, setLevelOfStudiesSelected ] = useState(null);
    const [ civilStatusSelected, setCivilStatusSelected ] = useState(null);
    const [ nationalitySelected, setNationalitySelected ] = useState(null);

    const handleChangeCanReadAndWrite = (val) => {
        if (val) {
            setCanReadAndWrite(val);
            handleChageForm(val, 'canReadAndWrite')
        } else {
            setCanReadAndWrite(null);
            handleChageForm('', 'canReadAndWrite');
        }
    }

    const handleChangeCanSpeakSpanish = (val) => {
        if (val) {
            setCanSpeakSpanish(val);
            handleChageForm(val, 'canSpeakSpanish')
        } else {
            setCanSpeakSpanish(null);
            handleChageForm('', 'canSpeakSpanish');
        }
    }

    const handleChangeLevelOfStudies = (val) => {
        if (val) {
            setLevelOfStudiesSelected(val);
            handleChageForm(val, 'levelStudies')
        } else {
            setLevelOfStudiesSelected(null);
            handleChageForm('', 'levelStudies');
        }
    }

    const handleChangeCivilStatus = (val) => {
        if (val) {
            setCivilStatusSelected(val);
            handleChageForm(val, 'civilStatus')
        } else {
            setCivilStatusSelected(null);
            handleChageForm('', 'civilStatus');
        }
    }
    
    const handleChangeNationality = (val) => {
        if (val) {
            setNationalitySelected(val);
            handleChageForm(val, 'nationality')
        } else {
            setNationalitySelected(null);
            handleChageForm('', 'nationality');
        }
    }

    useEffect( () => {
        // LOAD VALUES TO STATES
        if(values.formValues.canReadAndWrite) setCanReadAndWrite(values.formValues.canReadAndWrite);
        if(values.formValues.canSpeakSpanish) setCanSpeakSpanish(values.formValues.canSpeakSpanish);
        if(values.formValues.levelStudies) setLevelOfStudiesSelected(values.formValues.levelStudies);
        if(values.formValues.civilStatus) setCivilStatusSelected(values.formValues.civilStatus);
        if(values.formValues.nationality) setNationalitySelected(values.formValues.nationality);
    }, [randomVal] );

    return (
        <>
            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* ¿Puede Leer y Escribir?</Form.Label>
                            <Select
                                options={mappingData(yesOrNot)}
                                placeholder='Selecciona una opción'
                                onChange={val => handleChangeCanReadAndWrite(val)}
                                value={canReadAndWrite}
                                classNamePrefix="my-select"
                                className={isError(values.errors, 'canReadAndWrite') ? 'select-error' : ''}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='canReadAndWrite' />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* ¿Sabe hablar español?</Form.Label>
                            <Select
                                options={mappingData(yesOrNot)}
                                placeholder='Selecciona una opción'
                                onChange={val => handleChangeCanSpeakSpanish(val)}
                                value={canSpeakSpanish}
                                classNamePrefix="my-select"
                                className={isError(values.errors, 'canSpeakSpanish') ? 'select-error' : ''}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='canSpeakSpanish' />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Lengua o dialecto</Form.Label>
                            <Form.Control 
                                type='text'
                                placeholder='Ejemplo: Español' 
                                onChange={(e) => { handleChageForm(e.target.value, 'lengugage') }}
                                value={values.formValues.lengugage}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* Grado de estudios</Form.Label>
                            <Select
                                options={mappingData(levelOfStudies)}
                                placeholder='Selecciona una opción'
                                onChange={val => handleChangeLevelOfStudies(val)}
                                value={levelOfStudiesSelected}
                                classNamePrefix="my-select"
                                className={isError(values.errors, 'levelStudies') ? 'select-error' : ''}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='levelStudies' />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* Estado civil</Form.Label>
                            <Select
                                options={mappingData(civilStatus)}
                                placeholder='Selecciona una opción'
                                onChange={val => handleChangeCivilStatus(val)}
                                value={civilStatusSelected}
                                classNamePrefix="my-select"
                                className={isError(values.errors, 'civilStatus') ? 'select-error' : ''}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='civilStatus' />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* Nacionalidad</Form.Label>
                            <Select
                                options={mappingData(nationalities)}
                                placeholder='Selecciona una opción'
                                onChange={val => handleChangeNationality(val)}
                                value={nationalitySelected}
                                classNamePrefix="my-select"
                                className={isError(values.errors, 'nationality') ? 'select-error' : ''}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='nationality' />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Ocupación</Form.Label>
                            <Form.Control 
                                type='text'
                                placeholder=''
                                onChange={(e) => { handleChageForm(e.target.value, 'ocupation') }}
                                value={values.formValues.ocupation}
                                isInvalid={ isError(values.errors, 'ocupation')}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='ocupation' />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </>
    );
}