import React from 'react';
import styles from './ExpedientActions.module.css';
import ActionWidget from '../../../../actionWidget/ActionWidget';

const ExpedientActions = ({ actionList = [] }) => {

  return (
    <section className={styles.actions} aria-label={'Acciones del expediente'}>
      {
        actionList.map((action = {}, index) => (
          <ActionWidget
            icon={action.icon}
            key={index}
            onClick={action.onClick}
            disabled={!action.isShowing}
          >
            {action.description}
          </ActionWidget>
        ))
      }
    </section>
  )
}

export default ExpedientActions