import React, { useMemo } from 'react';
import styles from './ActionWidget.module.css';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

const ActionWidget = ({
  children,
  icon = 'fas fa-user',
  ...restOfProps
}) => {
  const descriptionId = useMemo(() => `action-widget-${uuidv4()}`, []);

  return (
    <button
      type="button"
      aria-labelledby={descriptionId}
      {...restOfProps}
      className={clsx(styles.actionWidget, restOfProps.className)}
    >
      <i className={clsx(icon, styles.actionWidget_icon)} />
      <span className={styles.actionWidget_name} id={descriptionId}>
        {children}
      </span>
    </button>
  )
}

export default ActionWidget