import React from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';

export const ErrorsByKeyName = ({listErrors, value}) => {
    
    if (!listErrors[value] || !isArray(listErrors[value])) {
        return <></>;
    }
    return (
        <>
            {
                listErrors[value].map((error, index) => {
                    return (
                        <li
                            key={index}
                            style={{ padding: '0px 0px 0px', color: 'red', fontSize: '.8rem', marginLeft: '1.0rem' }}
                        >
                            <small>
                                {error}
                            </small>
                        </li>
                    );
                })
            }
        </>
    );
}

ErrorsByKeyName.propTypes = {
    listErrors: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired
}