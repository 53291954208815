import React, { useMemo } from 'react';
import styles from './BadgeWithOptions.module.css';
import clsx from 'clsx';
import OptionsDropdown from './components/optionsDropdown/OptionsDropdown';
import { v4 as uuidv4 } from 'uuid';

const BadgeWithOptions = ({
  children,
  onClick = () => { },
  onDeleteClick = () => { },
  ...restOfProps
}) => {

  const badgeId = useMemo(() => `badge-with-options-${uuidv4()}`, []);

  return (
    <article
      {...restOfProps}
      className={clsx(styles.badgeWiOptions, restOfProps.className)}
      aria-labelledby={badgeId}
    >
      <button
        role={'button'}
        className={styles.badgeWiOptions_content}
        onClick={onClick}
        id={badgeId}
      >
        {children}
      </button>
      <OptionsDropdown onDeleteClick={onDeleteClick} />
    </article>
  )
}

export default BadgeWithOptions