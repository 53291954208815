import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import tabStyles from '../../../../../styles/components/tabs/Tabs.module.css';
import styles from './TemplateTabs.module.css';

const TemplateTabs = ({
  currentTabIndex,
  templatesTypeName = '',
  handleSelectTab = () => { },
  templateItemsByType = [],
  children,
}) => {

  return (
    <Tabs
      selectedIndex={currentTabIndex}
      onSelect={handleSelectTab}
      className={tabStyles.tabs}
    >
      <TabList >
        {
          templateItemsByType.map((templateItemList, index) => (
            <Tab
              key={index}
              tabIndex={'0'}
              onClick={() => templateItemList.actions.onTabClick(templateItemList.name)}
            >
              {templateItemList.displayName}
            </Tab>
          ))
        }
      </TabList>

      {
        templateItemsByType.map((templateItemList, index) => (
          <TabPanel key={index}>
            {
              (templateItemList.name === templatesTypeName)
              &&
              <section
                className={styles.tabPanelContent}
                aria-label={`Listado de plantillas de tipo ${templateItemList.displayName}`}
              >
                {children}
              </section>
            }
          </TabPanel>
        ))
      }
    </Tabs>
  );
}

export default TemplateTabs