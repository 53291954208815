/* eslint-disable no-const-assign */
import { NotificationManager } from "react-notifications";
import axios from "axios";
import Cookies from "js-cookie";
import { DestroyCookies } from "../login/login_logic"
import _ from "lodash";

export class RequestNewVersion {

  constructor(url, method, headers, bodyRequest, args = {}) {
    this.url = url;
    this.bodyRequest = bodyRequest;
    this.method = method;
    this.headers = headers;
    this.queryRetryCounter = 0;
    this.queryRetryLimit = 1;
    this.args = args;
  }

  async executeRequest(show = true, token = null) {
    
    let responseError = {
      code: 800,
      message:
        "Hubo un error al realizar la accion, intentelo mas tarde o contacte al administrador."
    };
    let authenticationToken = Cookies.get( "authentication_token_03" ) ?
      String(Cookies.get( "authentication_token_03" )) :
      btoa( process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION );

    if (token) {
      authenticationToken = token;
    }

    try {
      const response = await axios({
        method: this.method,
        url: this.url,
        data: this.bodyRequest,
        params: this.method === "get" ? this.bodyRequest : {},
        headers: {
          Authorization: authenticationToken
        },
        ...this.args
      });
      const { clean, message } = _.get(response, 'data.data', {});
      const responseCode = _.get(response, 'data.code');

      if (clean) {
        if (message) {
          ++this.queryRetryCounter;
          if (this.queryRetryCounter <= this.queryRetryLimit) {
            return this.executeRequest();
          }
          NotificationManager.error(message);
        }
        DestroyCookies();
        window.location.href = "/";
      }

      const isErrorMessageAnObject = _.isObject(message);
      const isErrorMessageAString = _.isString(message) && Boolean(message);
      const isResponseCode200 = responseCode === 200;

      if (!isResponseCode200 && responseCode !== 422 && isErrorMessageAnObject) {
        Object.keys(message).forEach((key) => {
          NotificationManager.error(message[key]);
         });
      }

      if (!isResponseCode200 && isErrorMessageAString) {
        NotificationManager.error(message);
      }

      if (isResponseCode200 && isErrorMessageAString) {
        //NotificationManager.warning(message);
      }

      const responseStatus = _.get(response, 'status');
      const responseStatusText = _.get(response, 'statusText');

      if (responseStatus === 200 && responseStatusText === "OK") {

        return {
          code: response.data.code,
          response: response.data.data,
          completeResponse: response
        }
      }

    }
    catch(error){

      responseError = {
        code: 500,
        message: "El servidor ha respondido con un error"
      };

      NotificationManager.error(
        responseError.message,
        "Error " + responseError.code + ":"
      );
    }

  }
}
