import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import Cookies from 'js-cookie';
import { AgreementsClasificationCatalog } from '../../../../utils/BulletinCatalogs';
import DateField from '../../../formikFields/DateField';
import SelectField from '../../../formikFields/SelectField';
import TextareaField from '../../../formikFields/TextareaField';
import TextField from '../../../formikFields/TextField';
import styles from '../ExpedientForm.module.css';

export default function AgreementFormFields() {
  const [agreementType, setAgreementType] = useState(false);
  const [headingsTypesOptions, setHeadingsTypesOptions] = useState([]);

  const handleAgreementClasificationSelect = (e) => {
    const reserveValue = e.value;

    if (reserveValue === 4) {
      setAgreementType(true);
    } else {
      setAgreementType(false);
    }
  }

  async function getHeadingsOptions() {
    let authenticationToken = Cookies.get("authentication_token_03");

    const headingsResponse = await fetch(
      `${process.env.REACT_APP_URL_API_LARAVEL}/catalogs/headings`, {
      method: 'GET',
      headers: {
        'Authorization': authenticationToken
      }
    });

    const headingsData = await headingsResponse.json();
    const headingsOptions = headingsData.data.headings;
    const options = headingsOptions.map((heading) => {
      return {
        value: heading.id,
        label: heading.name
      }
    });

    setHeadingsTypesOptions(options);
  }

  return (
    <>
      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Fecha del Acuerdo
            </div>
            <div className={styles.calendarFieldApparience}>
              <div className={styles.calendarGroup}>
                <div className={styles.datePickerElement}>
                  <DateField
                    name="agreement.agreement_date"
                    id="agreement_date"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className={styles.inputDate}
                    placeholderText={"Clic para seleccionar fecha"}
                  />
                </div>
                <div className={styles.iconPicker}>
                  <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
                </div>
              </div>
            </div>
            <small>Fecha del Acuerdo</small>
          </Form.Group>
        </div>

        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Fecha de Promoción
            </div>
            <div className={styles.calendarFieldApparience}>
              <div className={styles.calendarGroup}>
                <div className={styles.datePickerElement}>
                  <DateField
                    name="agreement.promotion_date"
                    id="promotion_date"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className={styles.inputDate}
                    placeholderText={"Clic para seleccionar fecha"}
                  />
                </div>
                <div className={styles.iconPicker}>
                  <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
                </div>
              </div>
            </div>
            <small>Fecha de la Promoción</small>
          </Form.Group>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <div className={styles.fieldLabel}>
            * Nombre del Juez
          </div>
          <TextField
            id="agreement.judge_name"
            name="agreement.judge_name"
            placeholder="Nombre del Juez"
            className={styles.input}
          />
          <small>Escribe el nombre del Juez</small>
        </div>

        <div className={styles.mediumSize}>
          <div className={styles.fieldLabel}>
            * Nombre del Secretario de Acuerdos
          </div>
          <TextField
            id="agreement.secretary_name"
            name="agreement.secretary_name"
            placeholder="Nombre del Secretario de Acuerdos"
            className={styles.input}
          />
          <small>Escribe aqui el nombre del Secretario de Acuerdos</small>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Clasificación del Acuerdo
            </div>
            <SelectField
              id="agreement.clasification"
              name='agreement.clasification'
              placeholder="Selecciona una opción"
              options={AgreementsClasificationCatalog}
              onChange={handleAgreementClasificationSelect}
            />
            <small>Selecciona el tipo de documento a publicar</small>
          </Form.Group>
        </div>
        <div className={styles.mediumSize}>
          {agreementType !== true ?
            <Form.Group>
              <div className={styles.fieldLabel}>
                * Fecha de publicación del acuerdo
              </div>
              <div className={styles.calendarFieldApparience}>
                <div className={styles.calendarGroup}>
                  <div className={styles.datePickerElement}>
                    <DateField
                      name="agreement.publication_date"
                      id="publication_date"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className={styles.inputDate}
                      placeholderText={"Clic para seleccionar fecha"}
                    />
                  </div>
                  <div className={styles.iconPicker}>
                    <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
                  </div>
                </div>
              </div>
              <small>Fecha de publicación del acuerdo</small>
            </Form.Group>
            :
            <Form.Group>
              <div className={styles.fieldLabel}>
                * Fecha de reservado
              </div>
              <div className={styles.calendarFieldApparience}>
                <div className={styles.calendarGroup}>
                  <div className={styles.datePickerElement}>
                    <DateField
                      name="agreement.reserve_date"
                      id="reserve_date"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className={styles.inputDate}
                      placeholderText={"Clic para seleccionar fecha"}
                    />
                  </div>
                  <div className={styles.iconPicker}>
                    <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
                  </div>
                </div>
              </div>
              <small>Fecha de reservado del acuerdo</small>
            </Form.Group>
          }
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Rubros del Acuerdo
            </div>
            <SelectField
              id="agreement.category"
              name='agreement.category'
              isMulti
              maxMenuHeight={150}
              placeholder="Selecciona una opción"
              onFocus={() => getHeadingsOptions()}
              options={headingsTypesOptions}
            />
            <small>Rubros aplicables para el acuerdo</small>
          </Form.Group>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize}>
          <div className={styles.fieldLabel}>
            * Extracto del Acuerdo
          </div>
          <TextareaField
            rows={8}
            name="agreement.extract"
            id="agreement.extract"
            placeholder="Extracto del acuerdo"
            className={styles.textarea}
          />
          <small>Escribe el extracto del acuerdo</small>
        </div>
      </div>
    </>
  )
}
