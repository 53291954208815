import React, { useState, useEffect } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Card } from "react-bootstrap";
import queryString from "query-string";
import { getStageDetail } from "./static_form_logic";
import OneColumn from "../layout/containers/OneColumn";
import _ from "lodash";

const StaticForm = props => {
  const [formData, setFormData] = useState([]);
  const getParams = queryString.parse(props.history.location.search);

  useEffect(() => {
    (async function() {
      await getStageDetail(getParams, setFormData);
    })();
  }, []);

  let pageConfig = {
    active: "traking",
    title: "Seguimiento"
  };

  const makeFormBasedFields = fields => {
    const formFields = fields.map((el, index) => {
      if (typeof el.value == "object") {
        const isAnElementWithElectronicSignature = typeof _.get(el, "value.Pkcs7Original") === "string";
        return (
          <tr key={index}>
            <td
              className="font-weight-bold text-right"
              style={{ width: "40%" }}
            >
              {el.label}:
            </td>
            <td className="break-word text-justify">
              {!isAnElementWithElectronicSignature ? (el.value.map((option, indexOption) => {
                let options = "";
                // add options selected
                for (let key in option) {
                  options = option[key] ? key + ", " : "";
                }
                return options;
              })):(
                <a href={el.value.FileName} className="btn btn-primary text-white" target="_blank" rel="noopener noreferrer">
                  <i className="ti-download mr-2 text-white" title="Descargar" />
                  Descargar
                </a>
              )}
            </td>
          </tr>
        );
      } else if (el.value.includes("http")) {
        return (
          <tr key={index}>
            <td
              className="font-weight-bold text-right"
              style={{ width: "40%" }}
            >
              {el.label}
            </td>
            <td className="break-word text-justify">
              <a href={el.value} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                <i className="ti-download mr-2" title="Descargar" />
                Descargar
              </a>
            </td>
          </tr>
        );
      }

      return (
        <tr key={index}>
          <td className="font-weight-bold text-right" style={{ width: "40%" }}>
            {el.label}:
          </td>
          <td className="break-word text-justify">{el.value}</td>
        </tr>
      );
    });
    return formFields;
  };

  const makeFormsBasedResponse = form => {
    if (form.length > 0) {
      const responseFormFormatted = form.map((el, index) => {
        return (
          <Col key={index} xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 0 }}>
            <Card className="mB-20">
              <Card.Body>
                <div className="table-responsive">
                  <table className="nilo w-100">
                    <thead>
                      <tr>
                        <th colSpan={2} className="text-center p-20">
                          <h4 className="mb-0">{el.name}</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{makeFormBasedFields(el.fields)}</tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        );
      });
      return responseFormFormatted;
    } else {
      return (
        <div className="alert alert-warning" role="alert">
          La etapa no se encuentra vinculada a ningún trámite, favor de intentarlo
          de nuevo más tarde.
        </div>
      );
    }
  };

  return (
    <OneColumn
      {...props}
      pageConfig={pageConfig}
      hideTopMenu={true}
      noTitle={true}
    >
      <section className="tracking">
        <Row className="pB-40">
          <Col xs={12}>
            <h3 className="mY-20 text-center">Seguimiento de Etapa</h3>
          </Col>
        </Row>
        <Row className="pB-40">{makeFormsBasedResponse(formData)}</Row>
      </section>
    </OneColumn>
  );
};

export default StaticForm;

