import React, { useEffect } from 'react';
import { usePartiesContext } from '../../context/PartiesContext';
import { PartiesItem } from './PartiesItem';

async function getData(setValues, handleGetListParties, values) {
    setValues({ ...values, loading: true });
    await handleGetListParties();
    setValues({ ...values, loading: false });
}

export const PartiesList = () => {
    const { listParties, handleGetListParties, values, setValues, showForm, isDeleteParty, isWithoutMainModal = false } = usePartiesContext();

    useEffect( () => {
        // Get parties whe close modal and after to delete party if isWithoutMainModal
        if(!isWithoutMainModal) return;
        if(!showForm && !isDeleteParty) {
            getData(setValues, handleGetListParties, values);
        }
    }, [showForm, isDeleteParty] );

    useEffect( () => {
        // Load initial data
        getData(setValues, handleGetListParties, values);
    }, []);

    if(listParties.length === 0)  {
        return (
            <div className='d-flex justify-content-center'>
                <p className='h5 user-select-none'>No hay partes registradas</p>
            </div>
        );
    }
   
    return (
        <>
            {
                listParties.map( (party, index) => (
                    <PartiesItem key={index} party={party} />
                ))
            }
        </>
    );
}
