const addPartToExpedient = (values, expedientId) => {
  return fetch(`${process.env.REACT_APP_STRAPI_BACKEND_PATH}/api/parts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`
    },
    body: JSON.stringify({
      data: {
        partClasification: values.partClasification,
        partType: values.partType,
        partNames: values.partNames,
        paternalSurname: values.paternalSurname,
        maternalSurname: values.maternalSurname,
        partSex: values.partSex,
        partAlias: values.partAlias,
        partGender: values.partGender,
        partAge: values.partAge,
        partEmail: values.partEmail,
        partPhone: values.partPhone,
        partBirthday: values.partBirthday,
        expedientId: expedientId,
        partRegime: values.partRegime,
        partBusinessName: values.partBusinessName
      }
    }),
  })
    .then(response => response.json())
    .then(partResponse => {
      return partResponse.data;
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

const getAllPartsByExpedient = async (expedientId) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
      },
    }

    const responseParts = await fetch(
      `${process.env.REACT_APP_STRAPI_BACKEND_PATH}/api/parts?filters[expedientId][id][$eq]=${expedientId}`,
      requestOptions
    );
    const parts = await responseParts.json();
    return parts.data;
  } catch (error) {
    console.error(error);
    console.log("Ocurrio un error al consultar las partes");
  }
}

// const editPart = async (partId, part) => {
const editPart = async (partId, values) => {
  try {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
      },
      body: JSON.stringify({
        data: {
          partClasification: values.partClasification,
          partType: values.partType,
          partSex: values.partSex,
          partGender: values.partGender,
          partAlias: values.partAlias,
          partAge: values.partAge,
          partEmail: values.partEmail,
          partPhone: values.partPhone,
          partNames: values.partNames,
          paternalSurname: values.paternalSurname,
          maternalSurname: values.maternalSurname,
          partBirthday: values.partBirthday,
          partRegime: values.partRegime,
          partBusinessName: values.partBusinessName
        }
      }),
    }

    const editResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_BACKEND_PATH}/api/parts/${partId}`, requestOptions
    );

    const updatedPart = await editResponse.json();
    return updatedPart.data;
  } catch (error) {
    console.error(error);
    console.log("Ocurrio un error al editar la parte");
  }
}

const deleteSpecificPart = async (partId) => {
  try {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
      },
    }

    const partResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_BACKEND_PATH}` + `/api/parts/${partId}`,
      requestOptions
    );

    return await partResponse.json();
  } catch (error) {
    console.error(error);
    console.log("Ocurrio un error al eliminar la parte");
  }
}

export {
  addPartToExpedient,
  getAllPartsByExpedient,
  editPart,
  deleteSpecificPart
};