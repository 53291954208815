import { useState, useCallback } from 'react';
import { INITIAL_TAB_INDEX } from './lib/constants';

const useTemplateTabsState = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(INITIAL_TAB_INDEX);

  const handleSelectTab = useCallback(
    (newTabIndex) => setCurrentTabIndex(newTabIndex),
    [currentTabIndex],
  );

  return {
    currentTabIndex,
    setCurrentTabIndex,
    handleSelectTab,
  }
}

export default useTemplateTabsState