export const nationalities = [
    {
        'codigo': '1',
        'etiqueta': 'MEXICANA'
    },
    {
        'codigo': '2',
        'etiqueta': 'NAMIBIANA'
    },
    {
        'codigo': '3',
        'etiqueta': 'ANGOLESA'
    },
    {
        'codigo': '4',
        'etiqueta': 'ARGELIANA'
    },
    {
        'codigo': '5',
        'etiqueta': 'DE BENNIN'
    },
    {
        'codigo': '6',
        'etiqueta': 'BOTSWANESA'
    },
    {
        'codigo': '7',
        'etiqueta': 'BURUNDESA'
    },
    {
        'codigo': '8',
        'etiqueta': 'DE CABO VERDE'
    },
    {
        'codigo': '9',
        'etiqueta': 'COMORENSE'
    },
    {
        'codigo': '10',
        'etiqueta': 'CONGOLESA'
    },
    {
        'codigo': '11',
        'etiqueta': 'MARFILEÑA'
    },
    {
        'codigo': '12',
        'etiqueta': 'CHADIANA'
    },
    {
        'codigo': '13',
        'etiqueta': 'DE DJIBOUTI'
    },
    {
        'codigo': '14',
        'etiqueta': 'EGIPCIA'
    },
    {
        'codigo': '15',
        'etiqueta': 'ETIOPE'
    },
    {
        'codigo': '16',
        'etiqueta': 'GABONESA'
    },
    {
        'codigo': '17',
        'etiqueta': 'GAMBIANA'
    },
    {
        'codigo': '18',
        'etiqueta': 'GHANATA'
    },
    {
        'codigo': '19',
        'etiqueta': 'GUINEA'
    },
    {
        'codigo': '20',
        'etiqueta': 'GUINEA'
    },
    {
        'codigo': '21',
        'etiqueta': 'GUINEA ECUATORIANA'
    },
    {
        'codigo': '22',
        'etiqueta': 'LIBIA'
    },
    {
        'codigo': '23',
        'etiqueta': 'KENIANA'
    },
    {
        'codigo': '24',
        'etiqueta': 'LESOTHENSE'
    },
    {
        'codigo': '25',
        'etiqueta': 'LIBERIANA'
    },
    {
        'codigo': '26',
        'etiqueta': 'MALAWIANA'
    },
    {
        'codigo': '27',
        'etiqueta': 'MALIENSE'
    },
    {
        'codigo': '28',
        'etiqueta': 'MARROQUI'
    },
    {
        'codigo': '29',
        'etiqueta': 'MAURICIANA'
    },
    {
        'codigo': '30',
        'etiqueta': 'MAURITANA'
    },
    {
        'codigo': '31',
        'etiqueta': 'MOZAMBIQUEÑA'
    },
    {
        'codigo': '32',
        'etiqueta': 'NIGERINA'
    },
    {
        'codigo': '33',
        'etiqueta': 'NIGERIANA'
    },
    {
        'codigo': '34',
        'etiqueta': 'CENTRO AFRICANA'
    },
    {
        'codigo': '35',
        'etiqueta': 'CAMERUNESA'
    },
    {
        'codigo': '36',
        'etiqueta': 'TANZANIANA'
    },
    {
        'codigo': '37',
        'etiqueta': 'RWANDESA'
    },
    {
        'codigo': '38',
        'etiqueta': 'DEL SAHARA'
    },
    {
        'codigo': '39',
        'etiqueta': 'DE SANTO TOME'
    },
    {
        'codigo': '40',
        'etiqueta': 'SENEGALESA'
    },
    {
        'codigo': '41',
        'etiqueta': 'DE SEYCHELLES'
    },
    {
        'codigo': '42',
        'etiqueta': 'SIERRA LEONESA'
    },
    {
        'codigo': '43',
        'etiqueta': 'SOMALI'
    },
    {
        'codigo': '44',
        'etiqueta': 'SUDAFRICANA'
    },
    {
        'codigo': '45',
        'etiqueta': 'SUDANESA'
    },
    {
        'codigo': '46',
        'etiqueta': 'SWAZI'
    },
    {
        'codigo': '47',
        'etiqueta': 'TOGOLESA'
    },
    {
        'codigo': '48',
        'etiqueta': 'TUNECINA'
    },
    {
        'codigo': '49',
        'etiqueta': 'UGANDESA'
    },
    {
        'codigo': '50',
        'etiqueta': 'ZAIRANA'
    },
    {
        'codigo': '51',
        'etiqueta': 'ZAMBIANA'
    },
    {
        'codigo': '52',
        'etiqueta': 'DE ZIMBAWI'
    },
    {
        'codigo': '53',
        'etiqueta': 'ARGENTINA'
    },
    {
        'codigo': '54',
        'etiqueta': 'BAHAMEÑA'
    },
    {
        'codigo': '55',
        'etiqueta': 'DE BARBADOS'
    },
    {
        'codigo': '56',
        'etiqueta': 'BELICEÑA'
    },
    {
        'codigo': '57',
        'etiqueta': 'BOLIVIANA'
    },
    {
        'codigo': '58',
        'etiqueta': 'BRASILEÑA'
    },
    {
        'codigo': '59',
        'etiqueta': 'CANADIENSE'
    },
    {
        'codigo': '60',
        'etiqueta': 'COLOMBIANA'
    },
    {
        'codigo': '61',
        'etiqueta': 'COSTARRICENSE'
    },
    {
        'codigo': '62',
        'etiqueta': 'CUBANA'
    },
    {
        'codigo': '63',
        'etiqueta': 'CHILENA'
    },
    {
        'codigo': '64',
        'etiqueta': 'DOMINICA'
    },
    {
        'codigo': '65',
        'etiqueta': 'SALVADOREÑA'
    },
    {
        'codigo': '66',
        'etiqueta': 'ESTADOUNIDENSE'
    },
    {
        'codigo': '67',
        'etiqueta': 'GRANADINA'
    },
    {
        'codigo': '68',
        'etiqueta': 'GUATEMALTECA'
    },
    {
        'codigo': '69',
        'etiqueta': 'BRITANICA'
    },
    {
        'codigo': '70',
        'etiqueta': 'GUYANESA'
    },
    {
        'codigo': '71',
        'etiqueta': 'HAITIANA'
    },
    {
        'codigo': '72',
        'etiqueta': 'HONDUREÑA'
    },
    {
        'codigo': '73',
        'etiqueta': 'JAMAIQUINA'
    },
    {
        'codigo': '74',
        'etiqueta': 'NICARAGUENSE'
    },
    {
        'codigo': '75',
        'etiqueta': 'PANAMEÑA'
    },
    {
        'codigo': '76',
        'etiqueta': 'PARAGUAYA'
    },
    {
        'codigo': '77',
        'etiqueta': 'PERUANA'
    },
    {
        'codigo': '78',
        'etiqueta': 'PUERTORRIQUEÑA'
    },
    {
        'codigo': '79',
        'etiqueta': 'DOMINICANA'
    },
    {
        'codigo': '80',
        'etiqueta': 'SANTA LUCIENSE'
    },
    {
        'codigo': '81',
        'etiqueta': 'SURINAMENSE'
    },
    {
        'codigo': '82',
        'etiqueta': 'TRINITARIA'
    },
    {
        'codigo': '83',
        'etiqueta': 'URUGUAYA'
    },
    {
        'codigo': '84',
        'etiqueta': 'VENEZOLANA'
    },
    {
        'codigo': '85',
        'etiqueta': 'AMERICANA'
    },
    {
        'codigo': '86',
        'etiqueta': 'AFGANA'
    },
    {
        'codigo': '87',
        'etiqueta': 'DE BAHREIN'
    },
    {
        'codigo': '88',
        'etiqueta': 'BHUTANESA'
    },
    {
        'codigo': '89',
        'etiqueta': 'BIRMANA'
    },
    {
        'codigo': '90',
        'etiqueta': 'NORCOREANA'
    },
    {
        'codigo': '91',
        'etiqueta': 'SUDCOREANA'
    },
    {
        'codigo': '92',
        'etiqueta': 'CHINA'
    },
    {
        'codigo': '93',
        'etiqueta': 'CHIPRIOTA'
    },
    {
        'codigo': '94',
        'etiqueta': 'ARABE'
    },
    {
        'codigo': '95',
        'etiqueta': 'FILIPINA'
    },
    {
        'codigo': '96',
        'etiqueta': 'HINDU'
    },
    {
        'codigo': '97',
        'etiqueta': 'INDONESA'
    },
    {
        'codigo': '98',
        'etiqueta': 'IRAQUI'
    },
    {
        'codigo': '99',
        'etiqueta': 'IRANI'
    },
    {
        'codigo': '100',
        'etiqueta': 'ISRAELI'
    },
    {
        'codigo': '101',
        'etiqueta': 'JAPONESA'
    },
    {
        'codigo': '102',
        'etiqueta': 'JORDANA'
    },
    {
        'codigo': '103',
        'etiqueta': 'CAMBOYANA'
    },
    {
        'codigo': '104',
        'etiqueta': 'KUWAITI'
    },
    {
        'codigo': '105',
        'etiqueta': 'LIBANESA'
    },
    {
        'codigo': '106',
        'etiqueta': 'MALASIA'
    },
    {
        'codigo': '107',
        'etiqueta': 'MALDIVA'
    },
    {
        'codigo': '108',
        'etiqueta': 'MONGOLESA'
    },
    {
        'codigo': '109',
        'etiqueta': 'NEPALESA'
    },
    {
        'codigo': '110',
        'etiqueta': 'OMANESA'
    },
    {
        'codigo': '111',
        'etiqueta': 'PAKISTANI'
    },
    {
        'codigo': '112',
        'etiqueta': 'DEL QATAR'
    },
    {
        'codigo': '113',
        'etiqueta': 'SIRIA'
    },
    {
        'codigo': '114',
        'etiqueta': 'LAOSIANA'
    },
    {
        'codigo': '115',
        'etiqueta': 'SINGAPORENSE'
    },
    {
        'codigo': '116',
        'etiqueta': 'TAILANDESA'
    },
    {
        'codigo': '117',
        'etiqueta': 'TAIWANESA'
    },
    {
        'codigo': '118',
        'etiqueta': 'TURCA'
    },
    {
        'codigo': '119',
        'etiqueta': 'NORVIETNAMITA'
    },
    {
        'codigo': '120',
        'etiqueta': 'YEMENI'
    },
    {
        'codigo': '121',
        'etiqueta': 'ALBANESA'
    },
    {
        'codigo': '122',
        'etiqueta': 'ALEMANA'
    },
    {
        'codigo': '123',
        'etiqueta': 'ANDORRANA'
    },
    {
        'codigo': '124',
        'etiqueta': 'AUSTRIACA'
    },
    {
        'codigo': '125',
        'etiqueta': 'BELGA'
    },
    {
        'codigo': '126',
        'etiqueta': 'BULGARA'
    },
    {
        'codigo': '127',
        'etiqueta': 'CHECOSLOVACA'
    },
    {
        'codigo': '128',
        'etiqueta': 'DANESA'
    },
    {
        'codigo': '129',
        'etiqueta': 'VATICANA'
    },
    {
        'codigo': '130',
        'etiqueta': 'ESPAÑOLA'
    },
    {
        'codigo': '131',
        'etiqueta': 'FINLANDESA'
    },
    {
        'codigo': '132',
        'etiqueta': 'FRANCESA'
    },
    {
        'codigo': '133',
        'etiqueta': 'GRIEGA'
    },
    {
        'codigo': '134',
        'etiqueta': 'HUNGARA'
    },
    {
        'codigo': '135',
        'etiqueta': 'IRLANDESA'
    },
    {
        'codigo': '136',
        'etiqueta': 'ISLANDESA'
    },
    {
        'codigo': '137',
        'etiqueta': 'ITALIANA'
    },
    {
        'codigo': '138',
        'etiqueta': 'LIECHTENSTENSE'
    },
    {
        'codigo': '139',
        'etiqueta': 'LUXEMBURGUESA'
    },
    {
        'codigo': '140',
        'etiqueta': 'MALTESA'
    },
    {
        'codigo': '141',
        'etiqueta': 'MONEGASCA'
    },
    {
        'codigo': '142',
        'etiqueta': 'NORUEGA'
    },
    {
        'codigo': '143',
        'etiqueta': 'HOLANDESA'
    },
    {
        'codigo': '144',
        'etiqueta': 'PORTUGUESA'
    },
    {
        'codigo': '145',
        'etiqueta': 'BRITANICA'
    },
    {
        'codigo': '146',
        'etiqueta': 'SOVIETICA BIELORRUSA'
    },
    {
        'codigo': '147',
        'etiqueta': 'SOVIETICA UCRANIANA'
    },
    {
        'codigo': '148',
        'etiqueta': 'RUMANA'
    },
    {
        'codigo': '149',
        'etiqueta': 'SAN MARINENSE'
    },
    {
        'codigo': '150',
        'etiqueta': 'SUECA'
    },
    {
        'codigo': '151',
        'etiqueta': 'SUIZA'
    },
    {
        'codigo': '152',
        'etiqueta': 'YUGOSLAVA'
    },
    {
        'codigo': '153',
        'etiqueta': 'AUSTRALIANA'
    },
    {
        'codigo': '154',
        'etiqueta': 'FIJIANA'
    },
    {
        'codigo': '155',
        'etiqueta': 'SALOMONESA'
    },
    {
        'codigo': '156',
        'etiqueta': 'NAURUANA'
    },
    {
        'codigo': '157',
        'etiqueta': 'PAPUENSE'
    },
    {
        'codigo': '158',
        'etiqueta': 'SAMOANA'
    },
    {
        'codigo': '159',
        'etiqueta': 'ESLOVACA'
    },
    {
        'codigo': '160',
        'etiqueta': 'BURKINA FASO'
    },
    {
        'codigo': '161',
        'etiqueta': 'ESTONIA'
    },
    {
        'codigo': '162',
        'etiqueta': 'MICRONECIA'
    },
    {
        'codigo': '163',
        'etiqueta': 'REINO UNIDO(DEPEN. TET. BRIT.)'
    },
    {
        'codigo': '164',
        'etiqueta': 'REINO UNIDO(BRIT. DEL EXT.)'
    },
    {
        'codigo': '165',
        'etiqueta': 'REINO UNIDO(C. BRIT. DEL EXT.)'
    },
    {
        'codigo': '166',
        'etiqueta': 'REINO UNIDO'
    },
    {
        'codigo': '167',
        'etiqueta': 'KIRGUISTAN'
    },
    {
        'codigo': '168',
        'etiqueta': 'LITUANIA '
    },
    {
        'codigo': '169',
        'etiqueta': 'MOLDOVIA, REPUBLICA DE'
    },
    {
        'codigo': '170',
        'etiqueta': 'MACEDONIA'
    },
    {
        'codigo': '171',
        'etiqueta': 'ESLOVENIA'
    },
    {
        'codigo': '172',
        'etiqueta': 'ESLOVAQUIA'
    },
    { 
        'codigo': '200',
        'etiqueta': 'NO ESPECIFICA'
    }
];