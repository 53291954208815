import React from 'react'
import { useElectronicSignatureProcessContext } from '../../../../../../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/context/ElectronicSignatureProcessContext';
import ElectronicSignatureTable from '../../../../../../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/componets/ElectronicSignatureTable';
import CredentialsModal from '../../../../../../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/credentialsModal/CredentialsModal';
import { Button } from 'react-bootstrap';
import { electronicSignatureSettingsFirel } from '../../../../../../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/constants/electronicSignatureSettingsFirel';

const BodyModal = ({
  isElectronicSignatureTableShowing,
  isOnlyOneSignaturePerUserActivated = true,
}) => {

  const {
    isElectronicSignatureModalSowing,
    setIsElectronicSignatureModalSowing,
    electronicSignaturesList,
    onCloseModal,
    onAddElectronicSignature,
    isMultipleSignature,
    onDelteElectronicSignatureById,
    singleElectronicSignature,
    isThereASignature,
    electronicSignaturesRecentlyAddedList,
    isThereASignatureRecentlyAdded,
  } = useElectronicSignatureProcessContext();

  const isSingleSignaturePresent = (isThereASignature && !isMultipleSignature);
  const areThereMultipleSignatureRecentlyAdded = (isThereASignatureRecentlyAdded && isMultipleSignature);
  const canASignatureBeAdded = !isSingleSignaturePresent && !areThereMultipleSignatureRecentlyAdded;

  return (
    <>
      {
        isElectronicSignatureModalSowing
        &&
        <CredentialsModal
          isModalSowing={isElectronicSignatureModalSowing}
          onCloseModal={onCloseModal}
          addElectronicSignatureHandle={onAddElectronicSignature}
          isMultipleSignature={isMultipleSignature}
          electronicSignatureSettings={electronicSignatureSettingsFirel}
          isOnlyOneSignaturePerUserActivated={isOnlyOneSignaturePerUserActivated}
        />
      }

      <Button
        variant="success"
        onClick={() => setIsElectronicSignatureModalSowing(true)}
        className={'mb-3'}
        style={{ marginTop: '20px' }}
      >
        {canASignatureBeAdded ? 'Agregar Firma' : 'Cambiar Firma'}
      </Button>

      {
        isElectronicSignatureTableShowing
        &&
        <>
          <p className='m-0'>
            {isMultipleSignature ? 'Lista de Firmantes' : 'Firmante'}
          </p>
          <ElectronicSignatureTable
            electronicSignaturesList={electronicSignaturesList}
            electronicSignaturesRecentlyAddedList={electronicSignaturesRecentlyAddedList}
            singleElectronicSignature={singleElectronicSignature}
            isMultipleSignature={isMultipleSignature}
            onDelteElectronicSignatureById={onDelteElectronicSignatureById}
          />
        </>
      }
    </>
  )
}

export default BodyModal