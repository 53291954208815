export const initValuesFormParties = {
    isActive: false,
    formActive: 'personal_info',
    formValues: {
        names: '',
        paternal_surname: '',
        maternal_surname: '',
        alias: '',
        age: '',
        home: '',
        phoneNumber: '',
        email: '',
        ocupation: '',
        bussiness_name: '',
        business_name: '',
        is_show_cover: false
    },
    errors: {},
    errorsByForm: [],
    loading: false
}
