import Cookies from "js-cookie";
import _ from "lodash";
import { UserServices } from "../../services/userServices";
export class UserEntity {

    static loadPermissions() {
        const {special_permissions_03} = Cookies.get();
        const special_permissions_parsed = special_permissions_03 ? JSON.parse(special_permissions_03) : [];
        this.permissions = special_permissions_03.length > 0 ? special_permissions_parsed.at(0) : [];
    }

    static validatePermissions(permissionName = '') {
        this.loadPermissions();
        if(_.isEmpty(this.permissions)) {
            return false;
        }

        if(Object.keys(this.permissions).includes(permissionName)) {
            return true;
        }

        return false;
    }

    static async getMyMatter() {
        try {
            const mattersRequest = await UserServices.getMyMatter();
            return mattersRequest.map(item => `${item.code}`.toLowerCase().trim());
        } catch(e) {
            return [];
        }
    }
}