import React from 'react';
import { usePartiesContext } from '../../context/PartiesContext';
import { Button } from 'react-bootstrap';

export const ModalPartiesFooter = () => {
    const { closeModal, setValues, toggleDeleteParty, toggleShowAddForm, setCurrentPatyItem } = usePartiesContext();
    
    const handleCloseModal = () => {
        closeModal();
	setCurrentPatyItem({});
    }

    return (
        <>
            <Button variant='dark' onClick={handleCloseModal}>
                Cerrar
            </Button>
        </>
    );
}
