import _ from "lodash";
import { RequestNewVersion } from "../../../../../classes/RequestNewVersion";
import { useLoaderContext } from "../../../../../layout/shared/loader_context";

const useSubmitFormValues = ({
  indicatorstFormValues,
  expedientId,
  setErrors,
  errors,
  onSuccessfulRequest = () => { },
}) => {

  const { show: setIsLoaderShowing } = useLoaderContext();

  const validateFormValues = () => {
    const error = {};
    Object.keys(indicatorstFormValues).forEach((indicatorKey) => {
      if (indicatorKey === 'initial_demands'|| indicatorKey === 'final_judgments' || indicatorKey === 'interlocutory_judgments') {
        const indicatorValues = indicatorstFormValues[indicatorKey];
        Object.keys(indicatorValues).forEach((indicatorValueKey) => {
          switch (indicatorValueKey) {
            case 'measures_reparation':
              if(isNaN(indicatorValues[indicatorValueKey])){
                error[indicatorValueKey] = 'El dato en este campo debe ser un número';
              }
              break;
            case 'measures_protection':
              if(isNaN(indicatorValues[indicatorValueKey])){
                error[indicatorValueKey] = 'El dato en este campo debe ser un número';
              }
              break;
            case 'measures_reparation_decreed':
              if(isNaN(indicatorValues[indicatorValueKey])){
                error[indicatorValueKey] = 'El dato en este campo debe ser un número';
              }
              break;
            case 'measures_reparation_denied':
              if(isNaN(indicatorValues[indicatorValueKey])){
                error[indicatorValueKey] = 'El dato en este campo debe ser un número';
              }
              break;
            case 'measures_protection_decreed':
              if(isNaN(indicatorValues[indicatorValueKey])){
                error[indicatorValueKey] = 'El dato en este campo debe ser un número';
              }
              break;
            case 'measures_protection_denied':
              if(isNaN(indicatorValues[indicatorValueKey])){
                error[indicatorValueKey] = 'El dato en este campo debe ser un número';
              }
              break;
            case 'precautionary_measures_decreed':
              if(isNaN(indicatorValues[indicatorValueKey])){
                error[indicatorValueKey] = 'El dato en este campo debe ser un número';
              }
              break;
            case 'precautionary_measures_requested':
              if(isNaN(indicatorValues[indicatorValueKey])){
                error[indicatorValueKey] = 'El dato en este campo debe ser un número';
              }
              break;
            default:
              break;
          }
        });
      }
    });
    setErrors(errors);
    return error;
  }
  const handleSubmitFormValues = async () => {
    const formErrors = validateFormValues();
    if (!_.isEmpty(formErrors)) {
      return
    }
    const submitIndicatorsUrl = `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/indicators`;
    const submitIndicatorsBody = {
      indicatorstFormValues,
      expedient_id: expedientId,
    };
    const indicatorsRequest = new RequestNewVersion(submitIndicatorsUrl, "post", null, submitIndicatorsBody);

    try {
      setIsLoaderShowing(true);
      const indicatorsResponse = await indicatorsRequest.executeRequest();
      const indicatorsResponseCode = _.get(indicatorsResponse, 'code');
      const isASuccessfulRequest = indicatorsResponseCode === 200;

      if (!isASuccessfulRequest) {
        return
      }

      onSuccessfulRequest();

    } catch (error) {
      throw error
    } finally {
      setIsLoaderShowing(false);
    }
  }

  return handleSubmitFormValues
}

export default useSubmitFormValues